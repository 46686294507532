<script>

export default {
  data () {
    return {
      hasLoginErr:false,
      form: new window.Form({
        username: '',
        password: '',
        remember_me: '',
      }),
    }
  },

  methods: {
    tryToLogIn () {
      this.form.post('/login')
          .then((response) => {
              if (response.is_admin === 1) {
                  window.location.assign('/employees')
              }else{
                window.location.assign('/home')
              }

          }).catch((error)=>{
        this.hasLoginErr=true;
      })
    },
  },
}
</script>

<template>
  <div>
    <form autocomplete="off" @submit.prevent="tryToLogIn">
      <div class="mb-4">
        <label class="form-label" for="username">Username</label>
        <input
          id="username" v-model="form.username" type="text"
          class="form-control"
          autocomplete="off"
        >

        <span
          v-if="form.hasError('username')"
          class="text-danger"
          v-text="form.getError('username')"
        />
        <span v-if="hasLoginErr" class="text-danger my-1">
          Username or Password is incorrect
        </span>
      </div>

      <div class="mb-4">
        <label class="form-label" for="password">
          Password
        </label>
        <div class="input-group input-group-flat">
          <input
            id="password" v-model="form.password" type="password" class="form-control"
            placeholder="Password"
            autocomplete="off"
          >
        </div>

        <span
          v-if="form.hasError('password')"
          class="text-danger"
          v-text="form.getError('password')"
        />
      </div>

      <div class="form-footer">
        <button
          type="submit"
          class="btn btn-primary w-100 py-3 rounded-2 mb-3"
          :disabled="form.processing"
        >
          {{ form.processing ? 'Processing...' : 'Sign in' }}
        </button>
      </div>
    </form>
    <!-- <div class="text-center mt-2 text-muted">Don't have account yet? <a href="/register"> Sign up</a></div> -->
  </div>
</template>
