<script>
import { toPrice } from '@plugins/money'
export default {
    data() {
        return {
            fields: [

                {
                    name: '__slot:pay_date',
                    title: 'Month',
                    titleClass: 'font-weight-bold text-right',
                    dataClass: 'text-right',
                    // callback: this.$options.filters.toMoneyFormat
                },


                {
                    name: 'totalNetPay',
                    title: 'Net Pay',
                    titleClass: 'font-weight-bold text-left',
                    dataClass: 'text-left',
                    callback: function (value) {
                        if (value < 0) {
                            return '(' + toPrice(Math.abs((value * 0.01))).toFormat('0,0.00') + ')'
                        }
                        return toPrice((value * 0.01)).toFormat('0,0.00')
                    },
                },
                {
                    name: 'processed_by.name',
                    title: 'Processed by',
                    titleClass: 'font-weight-bold text-right',
                    dataClass: 'text-right',
                },

                {
                    name: '__slot:actions',
                    titleClass: 'text-center',
                    dataClass: 'text-left',
                    width: '5%',
                },
            ],
            moreParams: {
                filter: {
                    search: ''
                }
            },
            selectedPayroll:null,
            deletingPayroll: false,
            approvingPayroll: false,
            form: new window.Form({
                id: null,
                month: ''
            }),
        }
    },

    computed: {
        vuetable() {
            return this.$refs.table.$refs.vuetable
        },
    },

    methods: {
        showCreateForm() {
            this.$bvModal.show('create-modal')
        },
        showLoading(rowData) {
            this.$httpClient
                .post(`/payroll/email-payslips/`,
                    {
                        year: rowData.year,
                        month: rowData.month
                    }
                )
                .then(() => {
                    this.vuetable.reload()
                    this.$toast.success("payslips sent")
                })
                .catch(() => {
                    this.$toast.error(
                        "There was a problem sending payslips"
                    );
                })
            // this.$bvModal.show('create-modal')
        },
        emailP9Form(rowData) {
            this.$httpClient
                .post(`/payroll/email-p9form/`,
                    {
                        year: rowData.year,
                        month: rowData.month
                    }
                )
                .then(() => {
                    this.vuetable.reload()
                    this.$toast.success("payslips sent")
                })
                .catch(() => {
                    this.$toast.error(
                        "There was a problem sending payslips"
                    );
                })
        },
        showPayrollDetails(data) {
            window.location.href = `/payroll/lists/${data.pay_date}`;
        },
        formatDate(dateStr) {

            const date = new Date(dateStr);

            const options = {day: '2-digit', month: 'short', year: 'numeric'};

            const formatter = new Intl.DateTimeFormat('en-GB', options);

            const formattedDate = formatter.format(date);

            const finalFormattedDate = formattedDate.replace(/\//g, '-');

            return finalFormattedDate;

        },
        processPayroll() {
            this.form.post('/payroll')
                .then(() => {
                    this.$bvModal.hide('create-modal')
                    this.$toast.success('Payroll has been processed')
                    this.vuetable.reload()
                })
                .catch((error) => {
                    let errorContent = error.response
                    if (errorContent.status === 400) {
                        this.$toast.error(errorContent.data)
                    }
                    console.log(error)
                })
        },
        showDelelePayrollModal (rowData) {
            this.selectedPayroll = rowData;
            this.$bvModal.show('delete-payroll-modal')
        },
        showApprovePayrollModal(rowData){
            this.selectedPayroll = rowData;
            this.$bvModal.show('approve-close-modal')
        },
        deletePayroll() {
            this.deletingPayroll = true;
                this.$httpClient
                    .delete(`/payroll/delete/`,
                        {
                            params: {
                                year: this.selectedPayroll.year,
                                month: this.selectedPayroll.month
                            }
                        }
                    )
                    .then(() => {
                        this.deletingPayroll = false;
                        this.$bvModal.hide('delete-payroll-modal')
                        this.vuetable.reload()
                        this.$toast.success("deleted")
                    })
                    .catch(() => {
                        this.deletingPayroll = false;
                        this.$toast.error(
                            "There was a problem deleting the payroll"
                        );
                    });
        },
        approvePayroll() {
            this.approvingPayroll = true;
                this.$httpClient
                    .post(`/payroll/close-payroll/`,
                        {
                            params: {
                                year: this.selectedPayroll.year,
                                month: this.selectedPayroll.month
                            }
                        }
                    )
                    .then(() => {
                        this.approvingPayroll = false;
                        this.$bvModal.hide('approve-close-modal')
                        this.vuetable.reload()
                        this.$toast.success("approved and Closed")
                    })
                    .catch(() => {
                        this.approvingPayroll = false;
                        this.$toast.error(
                            "There was a problem approving the payroll"
                        );
                    });
        },
        closePayroll(rowData) {
            this.$toast.question('Are you sure?', 'You want to approve this payroll, make sure is correct').then(() => {
                this.$httpClient
                    .post(`/payroll/close-payroll/`,
                        {
                            params: {
                                year: this.selectedPayroll.year,
                                month: this.selectedPayroll.month
                            }
                        }
                    )
                    .then(() => {
                        this.vuetable.reload()
                        this.$toast.success("approved and Closed")
                    })
                    .catch(() => {
                        this.$toast.error(
                            "There was a problem approving the payroll"
                        );
                    });
            })
        },

        applyFilter() {
            this.vuetable.reload()
        },
        showPayrollComparision(rowData) {
            const year = rowData.year;
            const month = rowData.month;
            window.location.href = `/payroll/compare?year=${year}&month=${month}`;
        },
        cleanUp() {
            this.form.reset()
        }
    },
}
</script>

<template>
    <div>
        <div class="card card-stacked">
            <div class="card-header">
                <div class="input-icon me-2">
                    <span class="input-icon-addon">
                        <i class="uil uil-search"></i>
                    </span>
                    <input type="text" class="form-control bg-muted-lt rounded-2" placeholder="Search"
                           @input="applyFilter" v-model="moreParams.filter.search">
                </div>

                <div class="card-actions">
                    <a class="btn btn-primary" href="/payroll/payroll-list">
                        Payroll processing
                    </a>
                </div>
            </div>
            <VueTable ref="table" api-url="/datatable/payroll" :fields="fields" :append-params="moreParams">
                <template v-slot:pay_date="props">
                    <div class="media">
                        <div class="media-body align-self-center">
                            <span class="text-sm">{{ formatDate(props.rowData.pay_date) }} </span>
                        </div>
                    </div>
                </template>
                <template v-slot:name="props">
                    <div class="media">
                        <div class="media-body align-self-center">
                            <span class="text-sm">{{ props.rowData.employee.name }} </span>
                        </div>
                    </div>
                </template>

                <template v-slot:actions="props">
                    <div class="dropdown">
                        <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
                            <i class="uil uil-ellipsis-h"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <div>
                                <a class="dropdown-item" href="#" @click="showPayrollDetails(props.rowData)">
                                    <i class="uil uil-external-link-alt me-2"></i>
                                    Open
                                </a>
                                <a v-if="props.rowData.is_closed"  class="dropdown-item" href="#" @click="showLoading(props.rowData)">
                                    <i class="uil uil-envelope me-2"></i>
                                    Email payslips
                                </a>
                                <a v-if="!props.rowData.is_closed"  class="dropdown-item" href="#" @click="emailP9Form(props.rowData)">
                                    <i class="uil uil-envelope me-2"></i>
                                    Email Form P9
                                </a>
                                <a class="dropdown-item" href="#" @click="showPayrollComparision(props.rowData)">
                                    <i class="uil uil-layers me-2"></i>
                                    Compare with last month
                                </a>

                                <a v-if="!props.rowData.is_closed" class="dropdown-item" href="#" @click="showApprovePayrollModal(props.rowData)">
                                    <i class="uil uil-check-circle me-2"></i>
                                    Approve & close
                                </a>

                                <a v-if="!props.rowData.is_closed" class="dropdown-item text-danger" href="#" @click="showDelelePayrollModal(props.rowData)">
                                    <i class="uil uil-trash-alt me-2"></i>
                                    Delete
                                </a>

                            </div>

                        </div>
                    </div>
                </template>
            </VueTable>
        </div>

        <b-modal id="create-modal" title="Payroll Processing" hide-header-close
                 footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
            <form @submit.prevent="createDepartment" id="createForm">
                <div class="mb-3">
                    <label for="name" class="form-label">Select Month</label>
                    <date-picker name="month" :value="form.month" :max-date="false" @on-change="(dateOb, dateStr) => {
                            form.month = dateStr
                        }"/>

                    <span v-if="form.hasError('month')" class="text-danger" v-text="form.getError('month')">
                    </span>
                </div>

                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                    </div>
                </div>

            </form>

            <template #modal-footer="{ cancel }">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <button type="button" class="btn btn-primary" :disabled="form.processing"
                        @click.prevent="processPayroll">
                    Process
                </button>
            </template>

        </b-modal>

        <b-modal id="delete-payroll-modal" title="Payroll Delete" hide-header-close
                 footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
            <div class="modal-body text-center py-4">
                <!-- Download SVG icon from http://tabler-icons.io/i/alert-triangle -->
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon mb-2 text-danger icon-lg"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
                <h3>Are you sure?</h3>
                <div class="text-secondary">Do you really want to delete the payroll ? What you are about to do cannot be undone.</div>
                <div class="text-secondary">If deleted, all payroll inputs and loans processed will be reversed</div>
            </div>
            <div class="progress mb-2" v-if="deletingPayroll">
                <div class="progress">
                    <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                </div>
            </div>
            <template #modal-footer="{ cancel }">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Cancel
                </button>
                <button type="button" class="btn btn-danger" :disabled="deletingPayroll"
                        @click.prevent="deletePayroll">
                    Delete
                </button>
            </template>

        </b-modal>

        <b-modal id="approve-close-modal" title="Approve And Close Payroll" hide-header-close
                 footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
            <div class="modal-body text-center py-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon mb-2 text-danger icon-lg"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
                <h3>Are you sure?</h3>
                <div class="text-secondary">You want to approve this payroll, make sure is correct</div>
            </div>
            <div class="progress mb-2" v-if="approvingPayroll">
                <div class="progress">
                    <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                </div>
            </div>
            <template #modal-footer="{ cancel }">
                <button type="button" class="btn me-auto" @click.prevent="cancel">Cancel
                </button>
                <button type="button" class="btn btn-success" :disabled="approvingPayroll"
                        @click.prevent="approvePayroll">
                    Approve
                </button>
            </template>

        </b-modal>

        <b-modal id="compare-modal" title="Comparison" hide-header-close footer-class="d-flex justify-content-start"
                 modal-class="modal-blur" no-close-on-esc>
            <form @submit.prevent="updateDepartment" id="updateForm">
                <div class="mb-3">
                    <label for="name" class="form-label">Select Month</label>
                    <input type="text" class="form-control" id="month" v-model="form.month">

                    <span v-if="form.hasError('month')" class="text-danger" v-text="form.getError('month')">
                    </span>
                </div>


                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                    </div>
                </div>

            </form>
            <template #modal-footer="{ cancel }">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <button type="button" class="btn btn-primary" :disabled="form.processing"
                        @click.prevent="updateDepartment">
                    Process
                </button>

            </template>
        </b-modal>

    </div>
</template>
