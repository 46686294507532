var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.enableForm
    ? _c("div", [
        _c("div", { staticClass: "card rounded-3" }, [
          _c("div", { staticClass: "card-header" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "card-actions" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleForm.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n          Close\n        ")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body px-4" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.uploadDocument.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "" } },
                    [_vm._v("Document Name")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.document_name,
                        expression: "form.document_name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { name: "document_name", type: "text" },
                    domProps: { value: _vm.form.document_name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "document_name", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("document_name")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(
                            _vm.form.getError("document_name")
                          ),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group mb-4" },
                  [
                    _c("media-library-attachment", {
                      ref: "mediaComponent",
                      attrs: {
                        name: "media",
                        "validation-rules": {
                          accept: [
                            "image/jpeg",
                            "image/gif",
                            "application/pdf",
                          ],
                          maxSizeInKB: 10240,
                        },
                        "validation-errors": _vm.validationErrors,
                        "initial-value": _vm.value.media,
                      },
                      on: {
                        "is-ready-to-submit-change": function ($event) {
                          _vm.isReadyToSubmit = $event
                        },
                        change: _vm.onChange,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { disabled: _vm.form.processing, type: "submit" },
                  },
                  [_vm._v("\n          Upload Document\n        ")]
                ),
              ]
            ),
          ]),
        ]),
      ])
    : _c("div", [
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("div", { staticClass: "card-header" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "card-actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.toggleForm.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "uil uil-file-upload me-2" }),
                    _vm._v(" Upload\n        "),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.documents, function (document) {
              return _c("div", { key: document.id, staticClass: "card-body" }, [
                _c("div", { staticClass: "row g-2 align-items-center" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h4", { staticClass: "card-title m-0" }, [
                      _c("a", { attrs: { href: "#" } }, [
                        _vm._v(_vm._s(document.name)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-secondary" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm._f("readableFileSize")(document.size))
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v("by "),
                        _c("a", { attrs: { href: "#" } }, [
                          _vm._v(_vm._s(document.uploaded_by.name)),
                        ]),
                        _vm._v(
                          " on " +
                            _vm._s(
                              _vm._f("dATE_MONTH_YEAR_HOUR_MINUTES")(
                                document.created_at
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-auto" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn",
                        attrs: {
                          href: "/employee/employee-document/" + document.uuid,
                          target: "_blank",
                        },
                      },
                      [_vm._v("\n            Download\n          ")]
                    ),
                  ]),
                ]),
              ])
            }),
          ],
          2
        ),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("h3", { staticClass: "card-title" }, [
        _vm._v("\n          Documents\n        "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "card-subtitle" }, [
        _vm._v("\n          Upload document\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("h3", { staticClass: "card-title" }, [
        _vm._v("\n          Documents\n        "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "card-subtitle" }, [
        _vm._v("\n          Listing uploaded documents\n        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }