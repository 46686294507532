<script>

export default {
  props: {
    employeetypes: {
      type: Array,
      default: () => [],
    },
    branches: {
      type: Array,
      default: () => [],
    },
    jobgroups: {
      type: Array,
      default: () => [],
    },
    departments: {
      type: Array,
      default: () => [],
    },
      employees: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      PAYEOptions: [
        { name: 'Not Applicable', value: 0 },
        { name: 'Graduated Tax', value: 1 },
        { name: '30% of Gross Salary', value: 2 },
        { name: '5% with-holding', value: 3 },
      ],
      marital_status: [
        { name: 'Single', value: 0 },
        { name: 'Married', value: 1 },
        { name: 'Devoced', value: 2 },
        { name: 'Others', value: 3 },
      ],
      is_disabled: [
        { name: 'No', value: 0 },
        { name: 'Yes', value: 1 },
      ],
        genderList:[
            'MALE',
            'FEMALE'
        ],
      is_in_contract: false,
      disabilities: [
        'Mobility Impairments',
        'Chronic Health Conditions',
        'Visual Impairments',
        'Hearing Impairments',
        'Intellectual Disabilities',
        'Autism Spectrum Disorders',
        'Mental Illnesses',
        'Dyslexia',
        'Dyscalculia',
        'Dysgraphia',
        'Multiple Sclerosis',
        'Cerebral Palsy',
        'Traumatic Brain Injury',
        'Chronic Fatigue Syndrome',
        'Fibromyalgia',
        'Speech Impairments',
        'Language Impairments',
        'Memory Disorders',
        'Attention Deficit Disorders'
      ],
      form: new window.Form({
        id: null,
        name: '',
        dob: '',
        gender: '',
        identification_number: '',
        physical_address: '',
        job_title: '',
        marital_status: '',
        is_disabled: '',
        disability:'',
        telephone: '',
        staff_number: '',
        employee_type_id: null,
        next_of_kin: '',
        supervisor:'',
        next_of_kin_tel: '',
        home_county: '',
        home_constituency: '',
        ethnicity:'',
        home_sub_county: '',
        conflicts_of_interest: '',
        email: '',
        kra_pin: '',
        shif_no: '',
        nssf_no: '',
        payee_type: 0,
        job_group_id: null,
        branch_id: null,
        contract_exp: null,
        is_contract: false,
        pays_tax: false,
        pays_nssf: false,
        pays_shif: false,
        department_id: null,
        nationality: '',
        createLogin: false,
        username: '',
        password: '',
        cpassword: '',
        activated: false
      }),
    }
  },

  watch: {
    'form.employee_type_id': function(value){
      let employeeType = this.employeetypes.find((type)=>type.id === value)
      if(employeeType.name !== 'Permanent'){
        this.is_in_contract = true
        this.form.is_contract = true
      }else{
        this.is_in_contract = false
        this.form.is_contract = false
      }
    },
    'form.createLogin':function(value){
      if(value){
      const fullname = this.form.name
      this.form.name  = fullname

      const [firstName, secondName] = [...fullname.split(' ')];
      this.form.username = (`${firstName.substr(0,1)}.${secondName ?? ''}`).toLocaleLowerCase();
      }
    }
  },
    computed: {
        filterEmployee(){
            if(!this.form.department_id){
                return this.employees;
            }
            return this.employees.filter((employee)=>employee.department_id === this.form.department_id)
        }
    },
  methods: {
    registerEmployee() {
      if (this.form.createLogin) {
        if (this.form.password !== this.form.cpassword || this.username === '') {
          this.$toast.error('Password do not match, try again')
          return
        }
      }
      this.form.post('/employees')
        .then(() => {
          this.$toast.success('Employee Registered successfully')

          setTimeout(() => {
            window.location.assign('/employees')
          }, 2000)
        })
        .catch()
    },

    goBack() {
      window.location.assign('/employees')
    },
  },
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-xl-8 mx-auto p-4">
        <div class="text-center mb-4">
          <h1 class="fs-1">
            Employee Registration
          </h1>
          <span class="text-muted">Complete the form below to register a new employee</span>
        </div>
        <div class="card card-stacked">
          <form class="card-body py-4" @submit.prevent="registerEmployee">
            <div class="row g-4">
              <div class="col-lg-6 form-group mb-4">
                <label for="" class="form-label">Payroll Number</label>
                <input id="name" v-model="form.staff_number" type="text" class="form-control">
                <div v-if="form.hasError('staff_number')" class="text-danger" v-text="form.getError('staff_number')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label for="" class="form-label">Full name</label>
                <input id="name" v-model="form.name" type="text" class="form-control">
                <div v-if="form.hasError('name')" class="text-danger" v-text="form.getError('name')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label for="" class="form-label">Gender</label>
                  <v-select id="scheme_id" v-model="form.gender" label="name" :options="genderList"
                            :reduce="(option) => option" :clearable="false" />
                <span v-if="form.hasError('gender')" class="text-danger" v-text="form.getError('gender')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label">Date of Birth</label>
                <div>
                  <date-picker name="date_of_birth" formclass="form-control bg-white" :value="form.dob"
                    :max-date="new Date()" @on-change="(dateOb, dateStr) => {
            form.dob = dateStr
          }" />

                  <span v-if="form.hasError('dob')" class="text-danger" v-text="form.getError('dob')" />
                </div>
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Identification number</label>
                <input id="name" v-model="form.identification_number" type="text" class="form-control">
                <span v-if="form.hasError('identification_number')" class="text-danger"
                  v-text="form.getError('identification_number')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Mobile number</label>
                <input id="name" v-model="form.telephone" type="text" class="form-control">
                <span v-if="form.hasError('identification_number')" class="text-danger"
                  v-text="form.getError('identification_number')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Email address</label>
                <input id="name" v-model="form.email" type="text" class="form-control">
                <span v-if="form.hasError('email')" class="text-danger" v-text="form.getError('email')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Physical address</label>
                <input id="name" v-model="form.physical_address" type="text" class="form-control">
                <span v-if="form.hasError('physical_address')" class="text-danger"
                  v-text="form.getError('physical_address')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Marital Status</label>
                <v-select id="scheme_id" v-model="form.marital_status" label="name" :options="marital_status"
                  :reduce="(option) => option.value" :clearable="false" />
                <span v-if="form.hasError('marital_status')" class="text-danger"
                  v-text="form.getError('marital_status')" />
              </div>
              <div>
                <h5>Next of kin</h5>
                <div class="row">
                  <div class="col-lg-6 form-group mb-4">
                    <label class="form-label" for="plan_type">Name</label>
                    <input id="name" v-model="form.next_of_kin" type="text" class="form-control">
                    <span v-if="form.hasError('next_of_kin')" class="text-danger"
                          v-text="form.getError('next_of_kin')" />
                  </div>
                  <div class="col-lg-6 form-group mb-4">
                    <label class="form-label" for="plan_type">Tel</label>
                    <input id="name" v-model="form.next_of_kin_tel" type="text" class="form-control">
                    <span v-if="form.hasError('next_of_kin_tel')" class="text-danger"
                          v-text="form.getError('next_of_kin_tel')" />
                  </div>
                </div>

              </div>


              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Nationality</label>
                <input id="name" v-model="form.nationality" type="text" class="form-control">
                <span v-if="form.hasError('nationality')" class="text-danger" v-text="form.getError('nationality')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Home County</label>
                <input id="name" v-model="form.home_county" type="text" class="form-control">
                <span v-if="form.hasError('home_county')" class="text-danger" v-text="form.getError('home_county')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Home sub county</label>
                <input id="name" v-model="form.home_sub_county" type="text" class="form-control">
                <span v-if="form.hasError('home_sub_county')" class="text-danger" v-text="form.getError('home_sub_county')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Home constituency</label>
                <input id="name" v-model="form.home_constituency" type="text" class="form-control">
                <span v-if="form.hasError('home_constituency')" class="text-danger" v-text="form.getError('home_constituency')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Ethnicity</label>
                <input id="name" v-model="form.ethnicity" type="text" class="form-control">
                <span v-if="form.hasError('ethnicity')" class="text-danger" v-text="form.getError('ethnicity')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Is disabled</label>
                <v-select id="scheme_id" v-model="form.is_disabled" label="name" :options="is_disabled"
                          :reduce="(option) => option.value" :clearable="false" />
                <span v-if="form.hasError('is_disabled')" class="text-danger"
                      v-text="form.getError('is_disabled')" />
              </div>
              <div v-if="form.is_disabled === 1" class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Type of Disability</label>
                <v-select id="scheme_id" v-model="form.disability" label="name" :options="disabilities"
                          :reduce="(option) => option" :clearable="false" />
                <span v-if="form.hasError('disability')" class="text-danger"
                      v-text="form.getError('disability')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Job Title</label>
                <input id="name" v-model="form.job_title" type="text" class="form-control">
                <span v-if="form.hasError('job_title')" class="text-danger" v-text="form.getError('job_title')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Department</label>
                <v-select id="scheme_id" v-model="form.department_id" label="name" :options="departments"
                  :reduce="(option) => option.id" :clearable="false" />
                <span v-if="form.hasError('department_id')" class="text-danger"
                  v-text="form.getError('department_id')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Report to</label>
                <v-select id="scheme_id" v-model="form.supervisor" label="name" :options="filterEmployee"
                          :reduce="(option) => option.id" :clearable="false" >
                  <template v-slot:option="option">
                    <li class="media mb-1">
                      <div class="media-body">
                        <div class="d-flex justify-content-between">
                          <span>{{ option.name }}</span> <br>
                          <small class="text-warning">{{option.department.name}}</small>
                        </div>
                      </div>
                    </li>
                  </template>
                  <template v-slot:selected-option="option">
                    <li class="media mb-1">
                      <div class="media-body">
                        <div class="d-flex justify-content-between">
                          <span>{{ option.name }}</span>
                        </div>
                      </div>
                    </li>
                  </template>
                </v-select>
                <span v-if="form.hasError('supervisor')" class="text-danger"
                  v-text="form.getError('supervisor')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Employee Type</label>
                <v-select id="scheme_id" v-model="form.employee_type_id" label="name" :options="employeetypes"
                  :reduce="(option) => option.id" :clearable="false" />
                <span v-if="form.hasError('employee_type_id')" class="text-danger"
                  v-text="form.getError('employee_type_id')" />
              </div>
              <div v-if="is_in_contract" class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Employment Expiry Date</label>
                  <date-picker name="date_of_birth" formclass="form-control bg-white" :value="form.contract_exp"
                               :min-date="new Date()" @on-change="(dateOb, dateStr) => {
            form.contract_exp = dateStr
          }" />
                <span v-if="form.hasError('contract_exp')" class="text-danger"
                  v-text="form.getError('contract_exp')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Job Group</label>
                <v-select id="scheme_id" v-model="form.job_group_id" label="name" :options="jobgroups"
                  :reduce="(option) => option.id" :clearable="false" />
                <span v-if="form.hasError('job_group_id')" class="text-danger"
                  v-text="form.getError('job_group_id')" />
              </div>
              <div class="col-lg-6 form-group mb-4">
                <label class="form-label" for="plan_type">Location</label>
                <v-select id="scheme_id" v-model="form.branch_id" label="name" :options="branches"
                  :reduce="(option) => option.id" :clearable="false" />
                <span v-if="form.hasError('branch_id')" class="text-danger"
                  v-text="form.getError('branch_id')" />
              </div>
              <div class="col-lg-12 form-group mb-4">
                <label class="form-label" for="plan_type">Conflicts of Interest</label>
                <textarea class="form-control" v-model="form.conflicts_of_interest"></textarea>

                <span v-if="form.hasError('conflicts_of_interest')" class="text-danger" v-text="form.getError('conflicts_of_interest')" />
              </div>
              <div class="col-lg-8 mx-auto mt-4">
                <div class="form-group mb-3">
                  <div class="mb-2">
                    <label class="form-check form-switch">
                      <input v-model="form.pays_tax" class="form-check-input" type="checkbox">
                      <span class="form-check-label">Pays tax</span>
                    </label>
                  </div>
                </div>

                  <div v-if="form.pays_tax" class="form-group mb-3">
                    <label class="form-label" for="paye_type">Tax Setup</label>
                    <v-select id="paye_type" v-model="form.payee_type" :options="PAYEOptions" label="name"
                              :clearable="false" :reduce="(option) => option.value">
                    </v-select>

                    <span v-if="form.errors.has('payee_type')" class="text-danger" v-text="form.errors.get('payee_type')">
                  </span>
                  </div>

                <div v-if="form.pays_tax" class=" form-group mb-3">
                  <label for="kra_pin" class="form-label">Kra Pin</label>
                  <input id="name" v-model="form.kra_pin" type="text" class="form-control">

                  <span v-if="form.hasError('kra_pin')" class="text-danger" v-text="form.getError('kra_pin')" />
                </div>
                <div class="form-group mb-3">
                  <div class="mb-2">
                    <label class="form-check form-switch">
                      <input v-model="form.pays_shif" class="form-check-input" type="checkbox">
                      <span class="form-check-label">Pays SHIF</span>
                    </label>
                  </div>
                </div>
                <div v-if="form.pays_shif" class="form-group mb-4">
                  <label for="kra_pin" class="form-label">SHIF NUMBER</label>
                  <input id="name" v-model="form.shif_no" type="text" class="form-control">

                  <span v-if="form.hasError('shif_no')" class="text-danger" v-text="form.getError('shif_no')" />
                </div>
                <div class="form-group mb-3">
                  <div class="mb-2">
                    <label class="form-check form-switch">
                      <input v-model="form.pays_nssf" class="form-check-input" type="checkbox">
                      <span class="form-check-label">Pays NSSF</span>
                    </label>
                  </div>
                </div>
                <div v-if="form.pays_nssf" class=" form-group mb-4">
                  <label for="kra_pin" class="form-label">NSSF NUMBER</label>
                  <input id="name" v-model="form.nssf_no" type="text" class="form-control">

                  <span v-if="form.hasError('nssf_no')" class="text-danger" v-text="form.getError('nssf_no')" />
                </div>
                <div class="form-group mb-2">
                  <div class="mb-2 ">
                    <label class="form-check form-switch">
                      <input v-model="form.createLogin" class="form-check-input" type="checkbox">
                      <span class="form-check-label">Create Login Details</span>
                    </label>
                  </div>
                </div>
                <fieldset v-if="form.createLogin" class="form-fieldset mt-3">
                  <div class="text-center fw-bold my-3">Employee Login Details</div>
                  <div class="mb-3">
                    <label class="form-label required">Username</label>
                    <input id="name" v-model="form.username" type="text" class="form-control">

                    <span v-if="form.hasError('username')" class="text-danger" v-text="form.getError('username')" />
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label required">Password</label>
                        <input id="name" v-model="form.password" type="password" class="form-control">
                        <span v-if="form.hasError('password')" class="text-danger" v-text="form.getError('password')" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label required">Confirm Password</label>
                        <input id="name" v-model="form.cpassword" type="password" class="form-control">

                        <span v-if="form.hasError('cpassword')" class="text-danger"
                          v-text="form.getError('cpassword')" />
                      </div>
                    </div>
                    <div class="form-group mb-2">
                      <div class="mb-2">
                        <label class="form-check form-switch">
                          <input v-model="form.activated" class="form-check-input" type="checkbox">
                          <span class="form-check-label">enabled</span>
                        </label>
                      </div>
                    </div>
                  </div>


                </fieldset>
              </div>
              <div class="mt-4">
                <button class="btn btn-primary me-3" type="submit">
                  Submit
                </button>

                <button class="btn" :disabled="form.processing || form.successful" @click.prevent="goBack">
                  Cancel
                </button>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
