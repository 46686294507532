<script>
import Papa from 'papaparse';
export default {
  props: ['locations'],
  data () {
    return {
      form: new window.Form({
        location_id: '',
          to_csv: false
      }),

    }
  },
mounted() {
    this.locations = [...this.locations, {id:0,name:'all'}]
},
  methods: {
    generateReport () {
      // this.form.processing = true
        if(!this.form.to_csv){
            this.$httpClient.get('/reports/employee-listing/generate', {
                responseType: 'blob',
                params: {
                    ...this.form.data(),
                },
            })
                .then(({ data }) => {
                    this.form.processing = false

                    const url = URL.createObjectURL(
                        new Blob([data], { type: 'application/pdf' }),
                    )
                    const link = document.createElement('a')

                    link.href = url
                    link.target = '_blank'
                    link.click()
                }).catch((error) => {
                this.form.processing = false

                this.form.errors.record(error.response.data.errors)
            })
        }else{
            this.toCsv();
        }
    },
      toCsv () {
          this.$httpClient.get('/reports/employee-listing/csv', {
              // responseType: 'blob',
              params: {
                  ...this.form.data(),
              }
          })
              .then(({data}) => {
                  const csv = Papa.unparse(data);
                    console.log(data)
                  // Create a downloadable link
                  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                  const url = URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.setAttribute('href', url);
                  link.setAttribute('download', 'employee_listing.csv');
                  link.style.display = 'none';
                  document.body.appendChild(link);

                  // Programmatically click the link to trigger the download
                  link.click();

                  // Clean up by removing the link
                  document.body.removeChild(link);
                  URL.revokeObjectURL(url); // Free up memory
                  this.form.processing = false
              })
              .catch(error => {
                  console.error('Error exporting CSV:', error);
              });
      }
  },
}
</script>

<template>
  <div>
    <div class="card card-stacked card-md mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-xl-8 mx-auto">
            <div class="mb-4">
              <div class="text-center">
                <h1 class="fw-bolder">
                  Employee Listing Report
                </h1>
              </div>
            </div>
              <div class="mb-4">
                  <label class="form-check form-switch">
                      <input v-model="form.to_csv" class="form-check-input"
                             type="checkbox">
                      <span class="form-check-label">To CSV</span>
                  </label>
              </div>
            <div class="row gx-4">
              <div class="col-xl-6">
                <div class="form-group mb-4">
                  <label class="form-label" for="">Location</label>
                  <v-select id="scheme_id" v-model="form.location_id" label="name" :options="locations"
                            :reduce="(option) => option.id" :clearable="false" />
                  <span v-if="form.hasError('location_id')" class="text-danger"
                        v-text="form.getError('location_id')" />
                </div>
              </div>
            </div>

            <button
                :disabled="form.processing"
                class="btn btn-primary mx-auto"
                @click="generateReport"
            >
              Generate
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
