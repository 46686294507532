<script>

export default {
    props: {
        kpis: {
            type: Array,
            required: true,
            default: function () {
                return []
            },
        },
        departments: {
            type: Array,
            required: true,
            default: function (){
                return []
            },
        },
        employees: {
            type: Array,
            required: true,
            default: function (){
                return []
            },
        },
    },
    data() {
        return {
            selectedEmployee: {},
            appealStatus: [
                'pending',
                'approved',
                'rejected',
            ],
            fields: [
                {
                    name: '__sequence',
                    title: '#',
                    titleClass: 'ps-3',
                    dataClass: 'ps-3 text-muted',

                },
                {
                    name: 'employee.name',
                    title: 'Employee',
                    titleClass: 'text-start',
                    dataClass: 'pe-3 text-start',

                },
                {
                    name: 'department.name',
                    title: 'Department',
                    titleClass: 'ps-3',
                    dataClass: 'ps-3 text-truncate',

                },
                {
                    name: '__slot:period',
                    title: 'Review Period',
                    titleClass: 'ps-1',
                },
                {
                    name: 'due_date',
                    title: 'Due Date',
                    titleClass: 'ps-1 font-weight-bold',
                },

                {
                    name: '__slot:is_completed',
                    title: 'Review Status',
                    titleClass: 'ps-3',
                    dataClass: 'ps-3',
                },
                {
                    name: '__slot:actions',
                    dataClass: 'text-right',
                },
            ],

            moreParams: {
                filter: {
                    search: ''
                }
            },
            previousDays: null,
            filteredKpis: [],
            form: new window.Form({
                id: null,
                employee_id: '',
                start_date: '',
                departmental: false,
                department_id: null,
                end_date: '',
                due_date: '',
                status: 1
            }),
            appearlForm: new window.Form({
                id: null,
                performance_review_id: null,
                reason: '',
                status: ''
            }),
            reviewForm: new window.Form({
                review_id: null,
                start_date: '',
                end_date: '',
                general_comment: '',
                due_date: '',
                final_rate: '',
                final_comment: '',
                completion_date: '',
                ratings: [],
            }),
        }
    },

    computed: {
        vuetable() {
            return this.$refs.table.$refs.vuetable
        },
        backgroundImageUrl() {
            if (this.selectedEmployee.employee != null) {
                if (this.selectedEmployee.employee.profile_image != null) {
                    return `/storage/${this.selectedEmployee.employee.profile_image.file_path}`;
                } else {
                    return ''
                }
            }
        },
        backgroundImageSupervisorUrl() {
            if (this.selectedEmployee.reviewer != null) {
                if (this.selectedEmployee.reviewer.profile_image != null) {
                    return `/storage/${this.selectedEmployee.reviewer.profile_image.file_path}`;
                } else {
                    return ''
                }
            }
        },
    },
    methods: {
        submitReview() {
            this.$toast.question("Are you certain? Once you submit, updates won't be possible thereafter.").then(() => {
                this.reviewForm.post('/performance-review/complete/')
                    .then((error) => {
                        this.$toast.success('review completed')
                        this.$bvModal.hide('review-modal');
                        this.cleanUp()
                        this.vuetable.reload()
                    }).catch((error) => {
                    this.$toast.error(error.response.data.errors)
                    this.reviewForm.errors.record(error.response.data.errors);
                })


            })
        },

        showCreateForm() {
            this.$bvModal.show('create-modal')
        },
        showUpdateForm(rowData) {
            this.selectedDepartment = rowData
            this.viewEditForm = true
        },

        AddReview() {
            this.form.post('/performance')
                .then(() => {
                    this.$bvModal.hide('create-modal')
                    this.$toast.success('The has been added')
                    this.vuetable.reload()
                })
                .catch()
        },
        saveReview() {
            this.reviewForm.post('/performance-review')
                .then(() => {
                    this.$bvModal.hide('review-modal')
                    this.$toast.success('The review has been saved')
                    this.vuetable.reload()
                })
                .catch()
        },
        onDelete(rowData) {
            this.$toast.question("Are you certain? you want to delete the review.").then(() => {
                this.reviewForm.delete('/performance-review/' + rowData.id)
                    .then(() => {
                        this.$toast.success('Deleted')
                        this.vuetable.reload()
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.errors)
                    })
            })

        },
        saveTargets() {
            this.reviewForm.post('/my-reviews-save-targets')
                .then(() => {
                    this.$bvModal.hide('review-modal')
                    this.$toast.success('The review has been saved')
                    this.vuetable.reload()
                })
                .catch()
        },
        approveTargets() {
            this.$toast.question("Are you certain? Once you submit, updates won't be possible on targets thereafter.").then(() => {
                this.reviewForm.post('/performance-review-targets-approval')
                    .then((error) => {
                        this.$bvModal.hide('review-modal')
                        this.$toast.success('Targets approved')
                        this.cleanUp()
                        this.vuetable.reload()
                    }).catch((error) => {
                    this.$toast.error(error.response.data.errors)
                    this.form.errors.record(error.response.data.errors);
                })
            })
        },
        showEditForm(rowData) {
            this.form.populate(rowData)
            this.form.id = rowData.id

            this.$bvModal.show('update-modal')
        },
        showReviewForm(rowData) {
            this.reviewForm.ratings = []
            this.form.populate(rowData)
            this.selectedEmployee = rowData
            this.form.id = rowData.id
            this.reviewForm.review_id = rowData.id
            this.reviewForm.general_comment = rowData.general_comment
            this.reviewForm.completion_date = rowData.completion_date
            this.reviewForm.final_comment = rowData.final_comment
            this.reviewForm.final_rate = rowData.final_rate
            this.filteredKpis = this.kpis.filter(kpi => kpi.department_id === rowData.department_id && kpi)
            if (rowData.review_ratings.length === 0) {
                this.filteredKpis.forEach(kpi => {
                    this.reviewForm.ratings.push({kpId: kpi.id, target: 0, name: kpi.name, value: 0, comment: ''})
                })
            } else {
                rowData.review_ratings.forEach(reviewRating => {
                    this.reviewForm.ratings.push({
                        kpId: reviewRating.kpi_id,
                        target: reviewRating.target,
                        name: reviewRating.kpi.name,
                        value: reviewRating.rating,
                        comment: reviewRating.comment
                    })
                })
            }
            this.$bvModal.show('review-modal')
        },

        showAppealForm(rowData) {
            this.appearlForm.id = rowData.performance_appeal.id
            this.appearlForm.performance_review_id = rowData.id
            this.appearlForm.status = rowData.performance_appeal.status
            this.appearlForm.reason = rowData.performance_appeal ? rowData.performance_appeal.reason : ''
            this.$bvModal.show('appeal-modal')
        },

        submitReviewAppeal() {
            this.$toast.question('Are you sure?', '').then(() => {
                this.appearlForm.patch(`performance-review-appeals/${this.appearlForm.id}/status`)
                    .then(() => {
                        this.$bvModal.hide('appeal-modal')
                        this.$toast.success('Appeal submitted successfully')
                        this.appearlForm.reset()
                        this.vuetable.reload();
                    })
                    .catch()
            })
        },
        applyFilter() {
            this.vuetable.reload()
        },

        cleanUp() {
            this.form.reset()
        }
    },
}
</script>

<template>
    <div>
        <div class="card card-stacked">
            <div class="card-header">
                <div class="input-icon me-2">
          <span class="input-icon-addon">
            <i class="uil uil-search"></i>
          </span>
                    <input type="text" class="form-control bg-muted-lt rounded-2" placeholder="Search"
                           @input="applyFilter"
                           v-model="moreParams.filter.search">
                </div>

                <div class="card-actions">
                    <button class="btn btn-primary" @click="showCreateForm">
                        <i class="uil uil-plus me-1"></i>Add
                    </button>
                </div>
            </div>
            <VueTable ref="table" api-url="/datatable/performance" :fields="fields" :append-params="moreParams">
                <template #period="props">
                    <small>{{ props.rowData.start_period }} - {{ props.rowData.end_period }}</small>

                </template>
                <template #is_completed="props">
                    <div>{{ props.rowData.is_completed ? 'Completed' : 'On going' }}</div>
                    <small v-if="props.rowData.performance_appeal" class="text-warning"> Appeal
                        {{ props.rowData.performance_appeal.status }}</small>


                </template>
                <template v-slot:actions="props">
                    <div class="dropdown">
                        <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
                            <i class="uil uil-ellipsis-h"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">

                            <a class="dropdown-item" href="#" @click="showReviewForm(props.rowData)">
                                <i class="uil uil-pen fs-3 me-2"></i> Open review
                            </a>
                            <a class="dropdown-item" href="#" @click="onDelete(props.rowData)">
                                <i class="uil uil-trash fs-3 me-2"></i> Del
                            </a>
                            <a v-if="props.rowData.performance_appeal" class="dropdown-item" href="#"
                               @click="showAppealForm(props.rowData)">
                                <i class="uil uil-pen fs-3 me-2"></i> view appeal
                            </a>

                        </div>
                    </div>
                </template>
            </VueTable>
        </div>
        <!-- create modal -->
        <b-modal id="create-modal" title="Add Review" hide-header-close size="lg"
                 footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
            <form @submit.prevent="AddReview" id="createForm">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <div class="d-flex gap-2">
                                <input v-model="form.departmental" class="form-check-input" id="switch-department" type="checkbox">
                                <label class="form-label" for="switch-department">Use department</label>
                            </div>
                        </div>
                    </div>
                    <div v-if="form.departmental" class="col-lg-6">
                        <div class="mb-3">
                            <label for="name" class="form-label">Department</label>
                            <v-select id="gender_id" v-model="form.department_id" name="leave_type_id"
                                      :options="departments" label="name"
                                      :reduce="option => option.id" :clearable="false"/>

                            <span v-if="form.hasError('department_id')" class="text-danger"
                                  v-text="form.getError('department_id')">
              </span>
                        </div>
                    </div>
                    <div v-else class="col-lg-6">
                        <div class="mb-3">
                            <label for="name" class="form-label">Employee</label>
                            <v-select id="gender_id" v-model="form.employee_id" name="leave_type_id"
                                      :options="employees" label="name"
                                      :reduce="option => option.id" :clearable="false"/>

                            <span v-if="form.hasError('employee_id')" class="text-danger"
                                  v-text="form.getError('employee_id')">
              </span>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="name" class="form-label">Review Period Start Date</label>
                            <date-picker name="date_of_birth" :value="form.start_date" :max-date="false" @on-change="(dateOb, dateStr) => {
                form.start_date = dateStr
              }"/>

                            <span v-if="form.hasError('start_date')" class="text-danger"
                                  v-text="form.getError('start_date')">
              </span>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="name" class="form-label">Review Period End Date</label>
                            <date-picker name="date_of_birth" :value="form.end_date" :max-date="false" @on-change="(dateOb, dateStr) => {
                form.end_date = dateStr
              }"/>

                            <span v-if="form.hasError('end_date')" class="text-danger"
                                  v-text="form.getError('end_date')">
              </span>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="name" class="form-label">Due Date</label>
                            <date-picker name="date_of_birth" :value="form.due_date" :max-date="false" @on-change="(dateOb, dateStr) => {
                form.due_date = dateStr
              }"/>

                            <span v-if="form.hasError('due_date')" class="text-danger"
                                  v-text="form.getError('due_date')">
              </span>
                        </div>
                    </div>
                </div>


                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                    </div>
                </div>

            </form>

            <template #modal-footer="{ cancel }">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="AddReview">
                    Submit
                </button>
            </template>

        </b-modal>
        <!-- review -->
        <b-modal id="review-modal" title="Review" hide-header-close size="lg"
                 footer-class="d-flex justify-content-start"
                 modal-class="modal-blur" no-close-on-esc>
            <div class="col-12 d-flex flex-column">
                <div class="card-body">
                    <h2 class="mb-4">Review Summary</h2>
                    <div class="row align-items-center">
                        <div class="col-auto">
                  <span v-if="backgroundImageUrl != ''" class="avatar avatar-xl rounded"
                        :style="{ backgroundImage: `url(${backgroundImageUrl ?? ''})` }">
                <div class="dropdown-mnu position-absolute">

                </div>
                </span>
                            <span v-else class="avatar avatar-md">{{
                                    selectedEmployee.employee ? selectedEmployee.employee.name.slice(0, 2).toUpperCase() : ''
                                }}</span>
                        </div>
                        <div class="col-auto">
                            {{ selectedEmployee.employee ? selectedEmployee.employee.name : '' }}

                        </div>
                        <div class="col-md-6">
                            <div class="d-flex align-items-center flex-column p-3">
                                <div>Final Rating</div>
                                <div class="fw-bold text-lg-center pt-2">{{ selectedEmployee.final_rate }}</div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-2">
                            <div class="d-flex flex-column align-items-start p-3">
                                <div class="text-muted">Review Status</div>
                                <div class="fw-bold"> {{ selectedEmployee.status === 1 ? 'On going' : 'completed' }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-2">
                            <div class="d-flex flex-column align-items-start p-3">
                                <div class="text-muted">Review Period</div>
                                <div class="fw-bold">{{ selectedEmployee.start_period }} -
                                    {{ selectedEmployee.end_period }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-2">
                            <div class="d-flex flex-column align-items-start p-3">
                                <div class="text-muted">Review Due Date</div>
                                <div class="fw-bold">{{ selectedEmployee.due_date }}</div>
                            </div>
                        </div>


                    </div>
                    <h3 class="card-title mt-4">Supervisor Evaluation by</h3>
                    <div class="row align-items-center">
                        <div class="col-auto">
                  <span v-if="backgroundImageSupervisorUrl != ''" class="avatar avatar-xl rounded"
                        :style="{ backgroundImage: `url(${backgroundImageSupervisorUrl ?? ''})` }">
                <div class="dropdown-mnu position-absolute">

                </div>
                </span>
                            <span v-else class="avatar avatar-md">
                                 {{
                                    selectedEmployee.reviewer != null ? selectedEmployee.reviewer.name.slice(0, 2).toUpperCase() : ''
                                }}
                            </span>
                        </div>
                        <div class="col-auto">
                            {{ selectedEmployee.reviewer != null ? selectedEmployee.reviewer.name : '' }}
                        </div>
                    </div>

                    <div class="row g-3 mt-4">
                        <div class="col-md">
                            <div class="form-label fw-bold">KPIs</div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-label fw-bold">Target</div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-label fw-bold">Rating</div>
                        </div>
                        <div class="col-md">
                            <div class="form-label fw-bold">Comment</div>
                        </div>
                    </div>
                    <div v-for="(kpi, index) in reviewForm.ratings" :key="kpi.id" class="row g-3 mt-3">
                        <div class="col-md">
                            <div class="form-label">{{ kpi.name }}</div>
                        </div>
                        <div class="col-md">
                            <input
                                :disabled="selectedEmployee.is_completed === 1 || selectedEmployee.targets_approved === 1"
                                v-model="reviewForm.ratings[index].target" class="form-control" type="text">
                            <span v-if="reviewForm.hasError('target')" class="text-danger"
                                  v-text="form.getError('target')">
              </span>
                        </div>
                        <div class="col-md-2">
                            <input :disabled="selectedEmployee.is_completed === 1" class="form-control"
                                   v-model="reviewForm.ratings[index].value" type="text">

                        </div>
                        <div class="col-md">
                            <input type="text" :disabled="selectedEmployee.is_completed === 1"
                                   v-model="reviewForm.ratings[index].comment" class="form-control">
                        </div>
                    </div>

                    <div class="col-auto mt-3">
                        <div class="d-flex g-4">
                            <div class="form-label">General comment</div>
                            <div>
                                <textarea v-model="reviewForm.general_comment"
                                          :disabled="selectedEmployee.is_completed === 1"
                                          class="form-control"></textarea>
                                <br> <span v-if="reviewForm.hasError('general_comment')" class="text-danger"
                                           v-text="reviewForm.getError('general_comment')"/>
                            </div>

                        </div>
                    </div>
                    <h3 class="card-title mt-4">Review Finalization</h3>
                    <div class="row g-3">
                        <div class="col-md-4 ">
                            <div class="form-label">Completion Date</div>
                            <date-picker :disabled="selectedEmployee.is_completed === 1" name="date_of_birth"
                                         :value="reviewForm.completion_date" :max-date="false" @on-change="(dateOb, dateStr) => {
                reviewForm.completion_date = dateStr
              }"/>
                            <span v-if="reviewForm.hasError('completion_date')" class="text-danger"
                                  v-text="reviewForm.getError('completion_date')"/>
                        </div>
                        <div class="col-md-3">
                            <div class="form-label">Final Rating</div>
                            <input type="text" :disabled="selectedEmployee.is_completed === 1"
                                   v-model="reviewForm.final_rate" class="form-control">
                            <span v-if="reviewForm.hasError('final_rate')" class="text-danger"
                                  v-text="reviewForm.getError('final_rate')"/>
                        </div>
                        <div class="col-md-4">
                            <div class="form-label">Final Comments</div>
                            <input :disabled="selectedEmployee.is_completed === 1" type="text"
                                   v-model="reviewForm.final_comment" class="form-control">
                            <span v-if="reviewForm.hasError('final_comment')" class="text-danger"
                                  v-text="reviewForm.getError('final_comment')"/>
                        </div>
                    </div>
                </div>

            </div>

            <template #modal-footer="{ cancel }">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <div>
                    <button type="button" class="btn btn-outline-primary"
                            :disabled="form.processing || selectedEmployee.is_completed === 1"
                            @click.prevent="approveTargets">
                        Approve targets
                    </button>
                    <button type="button" class="btn btn-outline-primary"
                            :disabled="form.processing || selectedEmployee.is_completed === 1"
                            @click.prevent="saveReview">
                        Save
                    </button>
                    <button type="button" class="btn btn-primary"
                            :disabled="form.processing || selectedEmployee.is_completed === 1"
                            @click.prevent="submitReview">
                        Complete
                    </button>
                </div>
            </template>

        </b-modal>
        <b-modal
            id="appeal-modal"
            title="Appeal Review"
            hide-header-close
            size="sm"
            footer-class="d-flex justify-content-start"
            modal-class="modal-blur"
            no-close-on-esc
        >
            <form @submit.prevent="submitReviewAppeal" id="createForm">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="name" class="form-label">Reason for appear</label>
                            <textarea disabled class="form-control" v-model="appearlForm.reason"></textarea>
                            <span
                                v-if="appearlForm.hasError('reason')"
                                class="text-danger"
                                v-text="appearlForm.getError('reason')">
                    </span>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="name" class="form-label">Status</label>
                            <select v-model="appearlForm.status" class="form-select">
                                <option :value="status" v-for="status in appealStatus">{{ status }}</option>
                            </select>
                            <span
                                v-if="appearlForm.hasError('reason')"
                                class="text-danger"
                                v-text="appearlForm.getError('reason')">
                    </span>
                        </div>
                    </div>
                </div>


                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                    </div>
                </div>

            </form>
            <template #modal-footer="{cancel}">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <button type="button" class="btn btn-primary" :disabled="form.processing"
                        @click.prevent="submitReviewAppeal">
                    Submit
                </button>

            </template>
        </b-modal>
    </div>
</template>
