<script>

export default {
  props: {
    leave_types: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      employees: [],
      leaveBalance: null,
      fields: [
        {
          name: 'employee_leave_type.leave_type.name',
          title: 'Leave Type',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'start_date',
          title: 'Start Date',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'end_date',
          title: 'End Date',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'days',
          title: 'Days',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },

        {
          name: '__slot:approved',
          title: 'STATUS',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
          // callback: function (value) {
          //   if (value) {
          //     return 'Approved'
          //   }
          //
          //   return 'Not approved'
          // }
        },
        {
          name: '__slot:actions',
          width: '15%',
          titleClass: 'text-center',
          dataClass: 'text-right',
        },
      ],

      moreParams: {
        filter: {
          search: '',
          date: new Date().toISOString().slice(0, 10)
        }
      },

      form: new window.Form({
        id: null,
        leave_type_id: '',
        employee_leave_type_id: '',
        reason: '',
        start_date: '',
        end_date: '',
        // days:'',
        return_date: '',
      }),
    }
  },

  computed: {
    vuetable () {
      return this.$refs.table.$refs.vuetable
    },
  },
  watch: {
    'form.leave_type_id': function (id) {
      let leaveType = this.leave_types.find(type => type.id === id)
      this.form.days = leaveType.days
      console.log(leaveType);
      this.getLeaveBalance(leaveType.employee_id)
    },
    'form.end_date': function (date) {
      console.log(date);
      this.form.return_date = date;
    }
  },
  mounted () {
    this.retrieveEmployees()
  },

  methods: {
    retrieveEmployees () {
      this.$httpClient.get('/datatable/employees/')
        .then(({ data }) => {
          this.employees = data.data;
        }).catch(() => {
          this.$toast.error('There was a problem retrieving the Employees')
        })
    },
    showCreateForm () {
      this.$bvModal.show('create-modal')
    },

    showUpdateForm (rowData) {
      this.selectedDepartment = rowData
      this.viewEditForm = true
    },

    createAssignLeave () {
      this.form.post('/user/leave-requests')
 .then(() => {
            this.$bvModal.hide('create-modal');
            this.$toast.success('The leave request has been submitted successfully.');
            this.vuetable.reload();
          })
          .catch( (error) => {
            if (error.response && error.response.data && error.response.data.errors) {
            this.form.errors.record(error.response.data.errors);
        } else {
            this.$toast.error(error.response.data)
        }
          }

          )


},

    showEditForm(rowData) {
      this.form.populate(rowData)
      this.form.id = rowData.id
      this.form.leave_type_id = rowData.employee_leave_type_id
      this.$bvModal.show('update-modal')
    },
    delRequest (rowData) {
      this.$toast.question('Are you sure?', 'You want to delete this leave request').then(() => {
        this.$httpClient.delete(`/user/leave-requests/${rowData.id}/delete`)
            .then((error) => {
              this.$toast.success('Request deleted')
              this.vuetable.reload()
            }).catch((error) => {
          this.$toast.error(error.response.data)
        })
      })

    },
    getApprovalStatusHTML(rowData) {
        if (rowData.rejected === 1) {
          return '<small class="text-danger">HR Rejected</small>';
        } else if (rowData.approved === 1) {
          return '<small class="text-success">HR Approved</small>';
        } else if (rowData.supervisor_approved === 0 && rowData.supervisor_rejected !== 1) {
          return '<small class="text-warning">Pending supervisor approval</small>';
        } else if (rowData.approved === 0 && rowData.supervisor_rejected !== 1) {
          return '<small class="text-warning">Pending HR approval</small>';
        }
        return '<small class="text-danger">Supervisor Rejected</small>';
    },
    editLeave() {
      this.form.patch('/user/leave-requests/' + this.form.id)
        .then(() => {
          this.vuetable.reload()
          this.$bvModal.hide('update-modal')
          this.$toast.success('The changes have been saved')
        })
        .catch()
    },

    applyFilter() {
      this.vuetable.reload()
    },
    getLeaveBalance(id) {
      this.$httpClient.get('/employee/leave-balance', {
        params: {
          employee_id: id,
          leave_type_id: this.form.leave_type_id
        }
      })
          .then(({data})=>{
            this.leaveBalance = data
          }).catch(()=>{

      })
    },
    cleanUp() {
      this.form.reset()
    }
  },
}
</script>

<template>
  <div>
    <div class="card card-stacked">
      <div class="card-header">
        <div class="input-icon me-2">
          <span class="input-icon-addon">
            <i class="uil uil-search"></i>
          </span>
          <input type="text" class="form-control bg-muted-lt rounded-2" placeholder="Search leave" @input="applyFilter"
            v-model="moreParams.filter.search">
        </div>

        <div class="card-actions">
          <!--          {{leave_types}}-->
          <button class="btn btn-primary" @click="showCreateForm">
            <i class="uil uil-plus me-1"></i>Request Leave
          </button>
        </div>
      </div>
      <VueTable ref="table" api-url="/datatable/myleaves" :fields="fields" :append-params="moreParams">
        <template v-slot:actions="props">
          <div class="dropdown">
            <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
              <i class="uil uil-ellipsis-h"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <div v-if="!props.rowData.rejected && !props.rowData.supervisor_rejected && !props.rowData.supervisor_approved">
                <a v-if="!props.rowData.approved" class="dropdown-item" href="#" @click="showEditForm(props.rowData)">
                  <i class="uil uil-pen fs-3 me-2"></i> Edit
                </a>
              </div>

              <a v-if="!props.rowData.approved && !props.rowData.rejected && !props.rowData.supervisor_rejected && !props.rowData.supervisor_approved" class="dropdown-item text-danger" href="#" @click="delRequest(props.rowData)">
                <i class="uil  uil-trash-alt me-2"/> Delete
              </a>
            </div>
          </div>
        </template>
        <template #approved="props">
          <span v-html="getApprovalStatusHTML(props.rowData)">
<!--            {{ getApprovalStatusHTML(props.rowData) }}-->
          </span>

<!--          <a  class="text-reset">sdsd</a>-->
<!--          <div v-if="!props.rowData.rejected">-->
<!--            <small class="text-success" v-if="props.rowData.approved === 1">HR Approved</small>-->
<!--            <small class="text-warning" v-else-if="props.rowData.supervisor_approved === 0">Pending supervisor approval</small>-->
<!--            <small class="text-warning" v-else-if="props.rowData.approved === 0">Pending HR approval</small>-->
<!--            <small class="text-warning" v-else-if="props.rowData.supervisor_approved === 0">Pending supervisor approval</small>-->
<!--          </div>-->
<!--          <small v-else class="text-danger" >Rejected</small>-->
        </template>
      </VueTable>
    </div>

    <b-modal id="create-modal" title="Request Leave" hide-header-close footer-class="d-flex justify-content-start"
      modal-class="modal-blur" no-close-on-esc>
      <form @submit.prevent="createAssignLeave" id="createForm">
        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Leave Type</label>
              <v-select id="gender_id" v-model="form.leave_type_id" name="leave_type_id" :options="leave_types"
                label="leave_type_id" :reduce="option => option.id" :clearable="false">
                <template v-slot:option="option">
                  <li class="media mb-1">
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <span>{{ option.leave_type.name }}</span>
                      </div>
                    </div>
                  </li>
                </template>
                <template v-slot:selected-option="option">
                  <li class="media mb-1">
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <span>{{ option.leave_type.name }}</span>
                      </div>
                    </div>
                  </li>
                </template>
              </v-select>
              <span v-if="form.hasError('leave_type_id')" class="text-danger" v-text="form.getError('leave_type_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Reason for leave</label>
             <textarea class="form-control" v-model="form.reason"></textarea>

              <span v-if="form.hasError('reason')" class="text-danger" v-text="form.getError('reason')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Start Date</label>
              <date-picker name="date_of_birth" :value="form.start_date" :min-date="new Date()" @on-change="(dateOb, dateStr) => {
              form.start_date = dateStr
            }" />

              <span v-if="form.hasError('start_date')" class="text-danger" v-text="form.getError('start_date')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">End Date</label>
              <date-picker name="date_of_birth" :value="form.end_date" :min-date="new Date()" @on-change="(dateOb, dateStr) => {
              form.end_date = dateStr
            }" />

              <span v-if="form.hasError('end_date')" class="text-danger" v-text="form.getError('end_date')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Returns Date</label>
              <date-picker name="date_of_birth" :value="form.return_date" :min-date="new Date()" @on-change="(dateOb, dateStr) => {
              form.return_date = dateStr
            }" />

              <span v-if="form.hasError('return_date')" class="text-danger" v-text="form.getError('return_date')">
              </span>
            </div>
          </div>
          <span v-if="leaveBalance" class="text-warning">leave balance: {{ leaveBalance.balance }} day(s)</span>
        </div>


        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>

      </form>

      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="createAssignLeave">
          Submit
        </button>
      </template>

    </b-modal>

    <b-modal id="update-modal" title="Edit Leave" hide-header-close size="lg"
      footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
        <form @submit.prevent="editLeave" id="createForm">
            <div class="row">
                <div class="col-lg-12">
                    <div class="mb-3">
                        <label for="name" class="form-label">Leave Type</label>
                        <v-select id="gender_id" v-model="form.leave_type_id" name="leave_type_id" :options="leave_types"
                                  label="leave_type_id" :reduce="option => option.id" :clearable="false">
                            <template v-slot:option="option">
                                <li class="media mb-1">
                                    <div class="media-body">
                                        <div class="d-flex justify-content-between">
                                            <span>{{ option.leave_type.name }}</span>
                                        </div>
                                    </div>
                                </li>
                            </template>
                            <template v-slot:selected-option="option">
                                <li class="media mb-1">
                                    <div class="media-body">
                                        <div class="d-flex justify-content-between">
                                            <span>{{ option.leave_type.name }}</span>
                                        </div>
                                    </div>
                                </li>
                            </template>
                        </v-select>
                        <span v-if="form.hasError('leave_type_id')" class="text-danger" v-text="form.getError('leave_type_id')">
              </span>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="mb-3">
                        <label for="name" class="form-label">Reason for leave</label>
                        <textarea class="form-control" v-model="form.reason"></textarea>

                        <span v-if="form.hasError('reason')" class="text-danger" v-text="form.getError('reason')">
              </span>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label for="name" class="form-label">Start Date</label>
                        <date-picker name="date_of_birth" :value="form.start_date" :min-date="new Date()" @on-change="(dateOb, dateStr) => {
              form.start_date = dateStr
            }" />

                        <span v-if="form.hasError('start_date')" class="text-danger" v-text="form.getError('start_date')">
              </span>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label for="name" class="form-label">End Date</label>
                        <date-picker name="date_of_birth" :value="form.end_date" :min-date="new Date()" @on-change="(dateOb, dateStr) => {
              form.end_date = dateStr
            }" />

                        <span v-if="form.hasError('end_date')" class="text-danger" v-text="form.getError('end_date')">
              </span>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label for="name" class="form-label">Returns Date</label>
                        <date-picker name="date_of_birth" :value="form.return_date" :min-date="new Date()" @on-change="(dateOb, dateStr) => {
              form.return_date = dateStr
            }" />

                        <span v-if="form.hasError('return_date')" class="text-danger" v-text="form.getError('return_date')">
              </span>
                    </div>
                </div>
                <span v-if="leaveBalance" class="text-warning">leave balance: {{ leaveBalance.balance }} day(s)</span>
            </div>


            <div class="progress mb-2" v-if="form.processing">
                <div class="progress">
                    <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                </div>
            </div>

        </form>
      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="editLeave">
          Submit
        </button>

      </template>
    </b-modal>

  </div>
</template>
