var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row gy-3 my-3" }, [
    _vm.earnings.length !== 0
      ? _c("div", { staticClass: "col-lg-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-title p-2" }, [
              _vm._v("\n                Earnings\n            "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive",
                staticStyle: { overflow: "hidden" },
              },
              [
                _c("table", { staticClass: "styled-table" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.earnings, function (record, index) {
                      return _c("tr", [
                        _c("td", { staticClass: "text-center text-muted" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(index + 1) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "ps-3" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(record.description) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "pe-3 text-end" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("toMoneyFormat")(record.amount * 0.01)
                              ) +
                              "\n                        "
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.allowances.length !== 0
      ? _c("div", { staticClass: "col-lg-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-title p-2" }, [
              _vm._v("\n                Allowances\n            "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive",
                staticStyle: { overflow: "hidden" },
              },
              [
                _c("table", { staticClass: "styled-table" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.allowances, function (record, index) {
                      return _c("tr", [
                        _c("td", { staticClass: "text-center text-muted" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(index + 1) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "ps-3" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(record.description) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "pe-3 text-end" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("toMoneyFormat")(record.amount * 0.01)
                              ) +
                              "\n                        "
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.benefits.length !== 0
      ? _c("div", { staticClass: "col-lg-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-title p-2" }, [
              _vm._v("\n                Benefits\n            "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive",
                staticStyle: { overflow: "hidden" },
              },
              [
                _c("table", { staticClass: "styled-table" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.benefits, function (record, index) {
                      return _c("tr", [
                        _c("td", { staticClass: "text-center text-muted" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(index + 1) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "ps-3" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(record.description) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "pe-3 text-end" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("toMoneyFormat")(record.amount * 0.01)
                              ) +
                              "\n                        "
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.reliefs.length !== 0
      ? _c("div", { staticClass: "col-lg-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-title p-2" }, [
              _vm._v("\n                Reliefs\n            "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive",
                staticStyle: { overflow: "hidden" },
              },
              [
                _c("table", { staticClass: "styled-table" }, [
                  _vm._m(3),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.reliefs, function (record, index) {
                      return _c("tr", [
                        _c("td", { staticClass: "text-center text-muted" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(index + 1) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "ps-3" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(record.description) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "pe-3 text-end" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("toMoneyFormat")(record.amount * 0.01)
                              ) +
                              "\n                        "
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.deductions.length !== 0
      ? _c("div", { staticClass: "col-lg-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-title p-2" }, [
              _vm._v("\n                Deductions\n            "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive",
                staticStyle: { overflow: "hidden" },
              },
              [
                _c("table", { staticClass: "styled-table" }, [
                  _vm._m(4),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.deductions, function (record, index) {
                      return _c("tr", [
                        _c("td", { staticClass: "text-center text-muted" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(index + 1) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "ps-3" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(record.description) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "pe-3 text-end" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("toMoneyFormat")(record.amount * 0.01)
                              ) +
                              "\n                        "
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.pensions.length !== 0
      ? _c("div", { staticClass: "col-lg-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-title p-2" }, [
              _vm._v("\n                Pensions\n            "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive",
                staticStyle: { overflow: "hidden" },
              },
              [
                _c("table", { staticClass: "styled-table" }, [
                  _vm._m(5),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.pensions, function (record, index) {
                      return _c("tr", [
                        _c("td", { staticClass: "text-center text-muted" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(index + 1) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "ps-3" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(record.description) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "pe-3 text-end" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("toMoneyFormat")(record.amount * 0.01)
                              ) +
                              "\n                        "
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.contributions.length !== 0
      ? _c("div", { staticClass: "col-lg-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-title p-2" }, [
              _vm._v("\n                Contributions\n            "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive",
                staticStyle: { overflow: "hidden" },
              },
              [
                _c("table", { staticClass: "styled-table" }, [
                  _vm._m(6),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.contributions, function (record, index) {
                      return _c("tr", [
                        _c("td", { staticClass: "text-center text-muted" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(index + 1) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "ps-3" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(record.description) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "pe-3 text-end" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("toMoneyFormat")(record.amount * 0.01)
                              ) +
                              "\n                        "
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.advances.length !== 0
      ? _c("div", { staticClass: "col-lg-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-title p-2" }, [
              _vm._v("\n                Advances\n            "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive",
                staticStyle: { overflow: "hidden" },
              },
              [
                _c("table", { staticClass: "styled-table" }, [
                  _vm._m(7),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.advances, function (record, index) {
                      return _c("tr", [
                        _c("td", { staticClass: "text-center text-muted" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(index + 1) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "ps-3" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(record.description) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "pe-3 text-end" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("toMoneyFormat")(record.amount * 0.01)
                              ) +
                              "\n                        "
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticStyle: { color: "#ffffff !important" } }, [
        _c("th", { staticClass: "text-center" }, [
          _vm._v("\n                            #\n                        "),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "ps-3" }, [
          _vm._v(
            "\n                            Description\n                        "
          ),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "pe-3 text-end" }, [
          _vm._v(
            "\n                            Amount\n                        "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticStyle: { color: "#ffffff !important" } }, [
        _c("th", { staticClass: "text-center" }, [
          _vm._v("\n                            #\n                        "),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "ps-3" }, [
          _vm._v(
            "\n                            Description\n                        "
          ),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "pe-3 text-end" }, [
          _vm._v(
            "\n                            Amount\n                        "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticStyle: { color: "#ffffff !important" } }, [
        _c("th", { staticClass: "text-center" }, [
          _vm._v("\n                            #\n                        "),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "ps-3" }, [
          _vm._v(
            "\n                            Description\n                        "
          ),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "pe-3 text-end" }, [
          _vm._v(
            "\n                            Amount\n                        "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticStyle: { color: "#ffffff !important" } }, [
        _c("th", { staticClass: "text-center" }, [
          _vm._v("\n                            #\n                        "),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "ps-3" }, [
          _vm._v(
            "\n                            Description\n                        "
          ),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "pe-3 text-end" }, [
          _vm._v(
            "\n                            Amount\n                        "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticStyle: { color: "#ffffff !important" } }, [
        _c("th", { staticClass: "text-center" }, [
          _vm._v("\n                            #\n                        "),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "ps-3" }, [
          _vm._v(
            "\n                            Description\n                        "
          ),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "pe-3 text-end" }, [
          _vm._v(
            "\n                            Amount\n                        "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticStyle: { color: "#ffffff !important" } }, [
        _c("th", { staticClass: "text-center" }, [
          _vm._v("\n                            #\n                        "),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "ps-3" }, [
          _vm._v(
            "\n                            Description\n                        "
          ),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "pe-3 text-end" }, [
          _vm._v(
            "\n                            Amount\n                        "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticStyle: { color: "#ffffff !important" } }, [
        _c("th", { staticClass: "text-center" }, [
          _vm._v("\n                            #\n                        "),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "ps-3" }, [
          _vm._v(
            "\n                            Description\n                        "
          ),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "pe-3 text-end" }, [
          _vm._v(
            "\n                            Amount\n                        "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticStyle: { color: "#ffffff !important" } }, [
        _c("th", { staticClass: "text-center" }, [
          _vm._v("\n                            #\n                        "),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "ps-3" }, [
          _vm._v(
            "\n                            Description\n                        "
          ),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "pe-3 text-end" }, [
          _vm._v(
            "\n                            Amount\n                        "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }