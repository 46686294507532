<script>
import Contributions from "./Contributions.vue";
import Attachments from "./Attachments.vue";
import Allowances from "./Allowances.vue";
import Income from "./Income.vue";
import Pension from "./Pension.vue";
import Relief from "./Relief.vue";
import Dedutions from "./Dedutions.vue";
import Qualification from "./Qualification.vue";
import Benefits from "./Benefits.vue";

export default {
    components: {Benefits, Attachments, Contributions, Allowances, Income, Pension, Relief, Dedutions, Qualification},
    props: {
        employee: {
            type: Object,
            required: true,
            default: () => {
            },
        },
        employeetypes: {
            type: Array,
            default: () => [],
        },
        actiontypes: {
            type: Array,
            default: () => [],
        },
        severitylevels: {
            type: Array,
            default: () => [],
        },
        jobgroups: {
            type: Array,
            default: () => [],
        },
        branches: {
            type: Array,
            default: () => [],
        },
        allowances: {
            type: Array,
            default: () => [],
        },
        reliefs: {
            type: Array,
            default: () => [],
        },
        deductions: {
            type: Array,
            default: () => [],
        },
        pensions: {
            type: Array,
            default: () => [],
        },
        contributions: {
            type: Array,
            default: () => [],
        },
        benefits: {
            type: Array,
            default: () => [],
        },
        departments: {
            type: Array,
            default: () => [],
        },
        incomes: {
            type: Array,
            default: () => [],
        },
        employees: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            PAYEOptions: [
                {name: 'Not Applicable', value: 0},
                {name: 'Graduated Tax', value: 1},
                {name: '30% of Gross Salary', value: 2},
                {name: '5% with-holding', value: 3},
            ],
            disabilities: [
                'Mobility Impairments',
                'Chronic Health Conditions',
                'Visual Impairments',
                'Hearing Impairments',
                'Intellectual Disabilities',
                'Autism Spectrum Disorders',
                'Mental Illnesses',
                'Dyslexia',
                'Dyscalculia',
                'Dysgraphia',
                'Multiple Sclerosis',
                'Cerebral Palsy',
                'Traumatic Brain Injury',
                'Chronic Fatigue Syndrome',
                'Fibromyalgia',
                'Speech Impairments',
                'Language Impairments',
                'Memory Disorders',
                'Attention Deficit Disorders'
            ],
            employmentStatus: [
                {name: 'active', value: 0},
                {name: 'suspended', value: 1},
                {name: 'terminated', value: 2},
            ],
            is_in_contract: false,
            maritalStatus: [
                {name: 'Single', value: 0},
                {name: 'Married', value: 1},
                {name: 'Divorce', value: 2},
                {name: 'Others', value: 3},
            ],
            is_disabled: [
                {name: 'No', value: 0},
                {name: 'Yes', value: 1},
            ],
            genderList: [
                'MALE',
                'FEMALE'
            ],
            employmentHistory: [],
            form: new window.Form({
                id: null,
                name: '',
                dob: '',
                gender: '',
                identification_number: '',
                physical_address: '',
                telephone: '',
                marital_status: '',
                job_title: '',
                staff_number: '',
                employee_type_id: null,
                contract_exp: null,
                job_group_id: null,
                email: '',
                kra_pin: '',
                shif_no: '',
                nssf_no: '',
                payee_type: '',
                pays_tax: false,
                pays_nssf: false,
                pays_shif: false,
                pays_housing_levy: false,
                employment_status: false,
                department_id: null,
                nationality: '',
            }),

            employeeForm: new window.Form({
                id: null,
                name: '',
                dob: '',
                gender: '',
                is_disabled: '',
                disability: '',
                identification_number: '',
                address: '',
                job_title: '',
                telephone: '',
                staff_number: '',
                marital_status: '',
                home_county: '',
                home_constituency: '',
                ethnicity: '',
                home_sub_county: '',
                conflicts_of_interest: '',
                employee_type_id: null,
                job_group_id: null,
                contract_exp: null,
                branch_id: null,
                nextOfKin: '',
                nextOfKin_tel: '',
                email: '',
                supervisor: '',
                kra_pin: '',
                shif_no: '',
                nssf_no: '',
                payee_type: '',
                pays_tax: false,
                pays_nssf: false,
                pays_shif: false,
                pays_housing_levy: false,
                department_id: null,
                employment_status: false,
                nationality: '',
            }),
            promotionForm: new window.Form({
                employee_id: null,
                new_job_group_id: null,
                new_department_id: null,
                new_job_title: '',
                promotion_date: null,
                promotion_reason: '',
            }),
            demotionForm: new window.Form({
                employee_id: null,
                new_job_group_id: null,
                new_department_id: null,
                new_job_title: '',
                demotion_date: null,
                demotion_reason: '',
            }),
            transferForm: new window.Form({
                employee_id: null,
                new_job_group_id: null,
                new_department_id: null,
                new_job_title: '',
                transfer_date: null,
                transfer_reason: '',
            }),
            p9Form: new window.Form({
                employee_id: null,
                year: ''
            }),
            employmentphoto: new window.Form({
                employee_id: this.employee.id,
                image: null
            }),
            caseForm: new window.Form({
                employee_id: null,
                incident_date: null,
                description: '',
                severity_level: null,
                resolution_date: null,
                resolution_details: '',
                status: false,
                action_type_id: null,
                action_details: '',
            }),
            showPatientEditForm: false,

        }
    },

    computed: {
        backgroundImageUrl() {
            if (this.employee.profile_image != null) {
                return `/storage/${this.employee.profile_image.file_path}`;
            } else {
                return ''
            }

        },
        filterEmployee() {
            if (!this.employeeForm.department_id) {
                return this.employees;
            }
            return this.employees.filter((employee) => employee.department_id === this.employeeForm.department_id)
        }
    },
    watch: {
        'employeeForm.employee_type_id': function (value) {
            let employeeType = this.employeetypes.find((type) => type.id === value)
            if (employeeType.name !== 'Permanent') {
                this.is_in_contract = true
                this.employeeForm.is_contract = true
            } else {
                this.is_in_contract = false
                this.employeeForm.is_contract = false
            }
        },
    },
    created() {
        this.retrieveEmploymentHistory()
        this.employeeForm.populate(this.employee)
        this.setMoreField()
    },
    methods: {
        togglePatientEditForm() {
            this.showPatientEditForm = !this.showPatientEditForm
        },
        setMoreField() {
            this.employeeForm.nextOfKin = this.employee.next_of_kin.full_name ?? ''
            this.employeeForm.nextOfKin_tel = this.employee.next_of_kin.phone_number ?? ''
        },
        showP9Form() {
            this.p9Form.employee_id = this.employee.id
            this.p9Form.year = new Date().getFullYear();
            this.$bvModal.show('P10-form-modal')

        },
        showEmployeeImage() {
            this.$bvModal.show('profile-image-modal')
        },
        formatDate(dateStr) {

            const date = new Date(dateStr);

            const options = {day: '2-digit', month: 'short', year: 'numeric'};

            const formatter = new Intl.DateTimeFormat('en-GB', options);


            const formattedDate = formatter.format(date);

            const finalFormattedDate = formattedDate.replace(/\//g, '-');

            console.log(finalFormattedDate);
            return finalFormattedDate;

        },
        // formP9(){
        //   this.$httpClient.get('/reports/download-form-9', {
        //     responseType: 'blob',
        //     params: {
        //       'employee_id' : this.p9Form.employee_id,
        //       'year' : this.p9Form.year,
        //     },
        //   })
        //       .then(({ data }) => {
        //         this.p9Form.processing = false
        //
        //         const url = URL.createObjectURL(
        //             new Blob([data], { type: 'application/pdf' }),
        //         )
        //
        //         const link = document.createElement('a')
        //
        //         link.href = url
        //         link.target = '_blank'
        //         link.click()
        //       }).catch((error) => {
        //         console.log(error.response.data);
        //     this.p9Form.processing = false
        //
        //     this.p9Form.errors.record(error.response.data.errors)
        //   })
        // },
        formP9() {
            this.$httpClient.get('/reports/download-form-9', {
                responseType: 'blob',
                params: {
                    'employee_id': this.p9Form.employee_id,
                    'year': this.p9Form.year,
                },
            })
                .then(({data}) => {
                    this.p9Form.processing = false

                    const url = URL.createObjectURL(
                        new Blob([data], {type: 'application/pdf'}),
                    )

                    const link = document.createElement('a')

                    link.href = url
                    link.target = '_blank'
                    link.click()
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.error) {

                        const errorMessage = error.response.data.error;
                        console.log(error.response.data);
                        this.p9Form.errors.record(errorMessage);
                    } else {
                        console.error('An unexpected error occurred.');
                        console.log(error.response.status);
                        if (error.response.status === 400) {
                            this.$toast.error('No payment for the period selected')
                        }
                        this.p9Form.errors.record('An unexpected error occurred. Please try again later.');
                    }
                    this.p9Form.processing = false;
                });
        },
        updateEmployee() {
            this.employeeForm.patch('/employees/' + this.employee.id)
                .then(() => {
                    this.$toast.success('Employee details have been updated')

                    setTimeout(() => {
                        window.location.reload()
                    }, 3000)
                }).catch()
        },

        showPromotionForm() {
            this.promotionForm.employee_id = this.employee.id;
            this.promotionForm.new_job_group_id = this.employee.job_group_id;
            this.promotionForm.new_department_id = this.employee.department_id;
            this.promotionForm.new_job_title = this.employee.job_title;
            this.$bvModal.show('promotion-modal')
        },
        showDemotionForm() {
            this.demotionForm.employee_id = this.employee.id;
            this.demotionForm.new_job_group_id = this.employee.job_group_id;
            this.demotionForm.new_department_id = this.employee.department_id;
            this.demotionForm.new_job_title = this.employee.job_title;
            this.$bvModal.show('demotion-modal')
        },
        showTransferForm() {
            this.transferForm.employee_id = this.employee.id
            this.transferForm.new_job_group_id = this.employee.job_group_id
            this.transferForm.new_department_id = this.employee.department_id
            this.transferForm.new_job_title = this.employee.job_title
            this.$bvModal.show('transfer-modal')
        },
        showCaseForm() {
            this.caseForm.employee_id = this.employee.id
            this.$bvModal.show('record-case-modal')
        },
        transfer() {
            this.$bvModal.hide('transfer-modal')
            this.$toast.question('Are you sure?', 'You want to transfer ' + this.employee.name).then(() => {
                this.transferForm.post('/employee/transfer')
                    .then(() => {
                        this.$toast.success('Employee Transfered')
                        setTimeout(() => {
                            window.location.reload()
                        }, 3000)
                    })
                    .catch((error) => {
                        this.form.processing = false
                        this.$toast.error('Something went wrong')
                        this.form.errors.record(error.response.data.errors)
                    })
            })
        },
        showHistory() {
            this.$bvModal.show('employment-history-modal')
        },
        demotion() {
            this.$bvModal.hide('demotion-modal')
            this.$toast.question('Are you sure?', 'You want to demote ' + this.employee.name).then(() => {
                this.demotionForm.post('/employee/demote')
                    .then(() => {
                        this.$toast.success('Employee demoted')
                        setTimeout(() => {
                            window.location.reload()
                        }, 3000)
                    })
                    .catch((error) => {
                        this.form.processing = false
                        this.$toast.error('Something went wrong')
                        this.form.errors.record(error.response.data.errors)
                    })
            })
        },
        promote() {
            this.$bvModal.hide('promotion-modal')
            this.$toast.question('Are you sure?', 'You want to promote ' + this.employee.name).then(() => {
                this.promotionForm.post('/employee/promote')
                    .then(() => {
                        this.$toast.success('Employee promoted')
                        setTimeout(() => {
                            window.location.reload()
                        }, 3000)
                    })
                    .catch((error) => {
                        this.form.processing = false
                        this.$toast.error('Something went wrong')
                        this.form.errors.record(error.response.data.errors)
                    })
            })
        },
        recordCase() {
            this.$toast.question(
                'Confirm Action',
                `Are you sure you want to submit a disciplinary case against ${this.employee.name}?`
            ).then(() => {
                this.caseForm.post('/employee/disciplinary')
                    .then(() => {
                        this.$toast.success('The disciplinary case has been successfully submitted.');
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    })
                    .catch((error) => {
                        this.form.processing = false;
                        this.$toast.error('Oops! Something went wrong while submitting the disciplinary case.');
                        this.form.errors.record(error.response.data.errors);
                    });
            });
        },
        retrieveEmploymentHistory() {
            this.$httpClient.get('/employee/employment-history/' + this.employee.id)
                .then(({data}) => {
                    this.employmentHistory = data;
                }).catch(() => {
                this.$toast.error('There was a problem retrieving the employment history')
            })
        },
        uploadPhoto() {
            this.employmentphoto.post('/employee/employee-photo')
                .then(() => {
                    this.$toast.success('Photo uploaded')
                    this.$bvModal.hide('profile-image-modal')
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000)
                }).catch()
        },
        updateImage(event) {
            this.employmentphoto.image = event.target.files[0];
        },
    },
}
</script>

<template>
    <div>
        <div class="row mb-4 gx-4">
            <div class="col-xl-5">
                <div v-if="showPatientEditForm" class="card card-stacked">
                    <div class="card-header">
                        <div>
                            <h3 class="card-title">
                                {{ employee.name }}
                            </h3>

                            <p class="card-subtitle small text-indigo">
                                Registered {{ employee.employed_at | dATE_MONTH_YEAR_HOUR_MINUTES }}
                            <div v-if='employee.employment_status === 1' class="text-warning">Suspended</div>
                            <div v-if="employee.employment_status === 2" class="text-danger">Terminated</div>
                            </p>
                        </div>
                    </div>


                    <div class="card-body px-4">
                        <div class="form-group mb-4">
                            <label class="form-label" for="name">Employee No</label>
                            <div>
                                <input id="name" v-model="employeeForm.staff_number" type="text" class="form-control">

                                <span v-if="employeeForm.hasError('staff_number')" class="text-danger"
                                      v-text="employeeForm.getError('staff_number')"/>
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <label class="form-label" for="name">Full Name</label>
                            <div>
                                <input id="name" v-model="employeeForm.name" type="text" class="form-control">

                                <span v-if="employeeForm.hasError('name')" class="text-danger"
                                      v-text="employeeForm.getError('name')"/>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label class="form-label">Date of Birth
                                        <span class="form-label-description small text-indigo"></span>
                                    </label>
                                    <div>
                                        <date-picker name="date_of_birth" :value="employeeForm.dob"
                                                     :max-date="new Date()" @on-change="(dateOb, dateStr) => {
                      employeeForm.dob = dateStr
                    }"/>

                                        <span v-if="employeeForm.hasError('dob')" class="text-danger"
                                              v-text="employeeForm.getError('dob')"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label class="form-label">Gender </label>
                                    <v-select id="scheme_id" v-model="employeeForm.gender" label="name"
                                              :options="genderList"
                                              :reduce="(option) => option" :clearable="false"/>
                                    <span v-if="employeeForm.hasError('gender')" class="text-danger"
                                          v-text="employeeForm.getError('gender')"/>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-4">
                            <label for="identification_value" class="form-label">Identification Number </label>
                            <div>
                                <input id="identification_value" v-model="employeeForm.identification_number"
                                       type="text"
                                       class="form-control">

                                <span v-if="employeeForm.hasError('identification_number')" class="text-danger"
                                      v-text="employeeForm.getError('identification_number')"/>
                            </div>
                        </div>

                        <div class="row gx-4">
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="telephone" class="form-label">Mobile Number </label>
                                    <input id="telephone" v-model="employeeForm.telephone" max="10" type="tel"
                                           class="form-control">
                                    <span v-if="employeeForm.hasError('telephone')" class="text-danger"
                                          v-text="employeeForm.getError('telephone')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Email </label>
                                    <input id="physical_address" v-model="employeeForm.email" type="text"
                                           class="form-control">

                                    <span v-if="employeeForm.hasError('email')" class="text-danger"
                                          v-text="employeeForm.getError('email')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label class="form-label" for="plan_type">Is disabled</label>
                                    <v-select id="scheme_id" v-model="employeeForm.is_disabled" label="name"
                                              :options="is_disabled"
                                              :reduce="(option) => option.value" :clearable="false"/>
                                    <span v-if="employeeForm.hasError('is_disabled')" class="text-danger"
                                          v-text="employeeForm.getError('is_disabled')"/>
                                </div>
                            </div>
                            <div v-if="employeeForm.is_disabled === 1" class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Type of Disability</label>
                                <v-select id="scheme_id" v-model="employeeForm.disability" label="name"
                                          :options="disabilities"
                                          :reduce="(option) => option" :clearable="false"/>
                                <span v-if="employeeForm.hasError('disability')" class="text-danger"
                                      v-text="employeeForm.getError('disability')"/>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Residence </label>
                                    <input id="physical_address" v-model="employeeForm.address" type="text"
                                           class="form-control">

                                    <span v-if="employeeForm.hasError('address')" class="text-danger"
                                          v-text="employeeForm.getError('address')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Marital Status</label>
                                    <v-select id="marital_status" v-model="employeeForm.marital_status" label="name"
                                              :options="maritalStatus" :reduce="(option) => option.value"
                                              :clearable="false"/>
                                    <span v-if="employeeForm.hasError('marital_status')" class="text-danger"
                                          v-text="employeeForm.getError('marital_status')"/>
                                </div>
                            </div>
                            <p>Next of kin</p>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Name</label>
                                    <input type="text" class="form-control" v-model="employeeForm.nextOfKin">
                                    <span v-if="employeeForm.hasError('nextOfKin')" class="text-danger"
                                          v-text="employeeForm.getError('nextOfKin')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Tel</label>
                                    <input type="text" class="form-control" v-model="employeeForm.nextOfKin_tel">
                                    <span v-if="employeeForm.hasError('nextOfKin_tel')" class="text-danger"
                                          v-text="employeeForm.getError('nextOfKin_tel')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Nationality </label>
                                    <input id="physical_address" v-model="employeeForm.nationality" type="text"
                                           class="form-control">

                                    <span v-if="employeeForm.hasError('nationality')" class="text-danger"
                                          v-text="employeeForm.getError('nationality')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Home county </label>
                                    <input id="physical_address" v-model="employeeForm.home_county" type="text"
                                           class="form-control">
                                    <span v-if="employeeForm.hasError('home_county')" class="text-danger"
                                          v-text="employeeForm.getError('home_county')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Home sub county </label>
                                    <input id="physical_address" v-model="employeeForm.home_sub_county" type="text"
                                           class="form-control">
                                    <span v-if="employeeForm.hasError('home_sub_county')" class="text-danger"
                                          v-text="employeeForm.getError('home_sub_county')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Home Constituency </label>
                                    <input id="physical_address" v-model="employeeForm.home_constituency" type="text"
                                           class="form-control">
                                    <span v-if="employeeForm.hasError('home_constituency')" class="text-danger"
                                          v-text="employeeForm.getError('home_constituency')"/>
                                </div>
                            </div>
                            <div v-if="employeeForm.ethnicity" class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Ethnicity</label>
                                    <input id="physical_address" v-model="employeeForm.ethnicity" type="text"
                                           class="form-control">
                                    <span v-if="employeeForm.hasError('home_constituency')" class="text-danger"
                                          v-text="employeeForm.getError('home_constituency')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Department</label>
                                    <v-select id="gender_id" v-model="employeeForm.department_id" name="department_id"
                                              :options="departments" label="name" :reduce="option => option.id"
                                              :clearable="false"/>

                                    <span v-if="employeeForm.hasError('department_id')" class="text-danger"
                                          v-text="employeeForm.getError('department_id')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Supervisor</label>
                                    <v-select id="gender_id" v-model="employeeForm.supervisor" name="supervisor"
                                              :options="filterEmployee" label="name" :reduce="option => option.id"
                                              :clearable="false"/>

                                    <span v-if="employeeForm.hasError('supervisor')" class="text-danger"
                                          v-text="employeeForm.getError('supervisor')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Job Title </label>
                                    <input id="physical_address" v-model="employeeForm.job_title" type="text"
                                           class="form-control">

                                    <span v-if="employeeForm.hasError('job_title')" class="text-danger"
                                          v-text="employeeForm.getError('job_title')"/>
                                </div>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Employee Type</label>
                                <v-select id="scheme_id" v-model="employeeForm.employee_type_id" label="name"
                                          :options="employeetypes"
                                          :reduce="(option) => option.id" :clearable="false"/>
                                <span v-if="employeeForm.hasError('employee_type_id')" class="text-danger"
                                      v-text="employeeForm.getError('employee_type_id')"/>
                            </div>
                            <div v-if="is_in_contract" class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Employment Expiry Date</label>
                                <date-picker name="date_of_birth" :value="employeeForm.contract_exp"
                                             :min-date="new Date()" @on-change="(dateOb, dateStr) => {
                      employeeForm.contract_exp = dateStr
                    }"/>
                                <span v-if="employeeForm.hasError('contract_exp')" class="text-danger"
                                      v-text="employeeForm.getError('contract_exp')"/>
                            </div>

                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Job Group</label>
                                <v-select id="scheme_id" v-model="employeeForm.job_group_id" label="name"
                                          :options="jobgroups"
                                          :reduce="(option) => option.id" :clearable="false"/>
                                <span v-if="employeeForm.hasError('job_group_id')" class="text-danger"
                                      v-text="employeeForm.getError('job_group_id')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Location</label>
                                <v-select id="scheme_id" v-model="employeeForm.branch_id" label="name"
                                          :options="branches"
                                          :reduce="(option) => option.id" :clearable="false"/>
                                <span v-if="employeeForm.hasError('branch_id')" class="text-danger"
                                      v-text="employeeForm.getError('branch_id')"/>
                            </div>

                            <div class="col-xl-6">
                                <div class="form-group mb-2">
                                    <label for="paye_type" class="form-label">Tax Setup</label>
                                    <v-select id="paye_type" v-model="employeeForm.payee_type" :options="PAYEOptions"
                                              label="name"
                                              :clearable="false" :reduce="(option) => option.value">
                                    </v-select>

                                    <span v-if="employeeForm.errors.has('payee_type')" class="text-danger"
                                          v-text="employeeForm.errors.get('payee_type')">
                  </span>
                                </div>
                            </div>
                            <div class="mb-2">
                                <label class="form-check form-switch">
                                    <input v-model="employeeForm.pays_tax" class="form-check-input" type="checkbox">
                                    <span class="form-check-label">Pays tax</span>
                                </label>
                            </div>
                            <div v-if="employeeForm.pays_tax" class="mb-4">
                                <label for="telephone" class="form-label">Kra PIN </label>
                                <input id="telephone" v-model="employeeForm.kra_pin" max="10" type="tel"
                                       class="form-control">
                                <span v-if="employeeForm.hasError('kra_pin')" class="text-danger"
                                      v-text="employeeForm.getError('kra_pin')"/>
                            </div>
                            <div class="mb-2">
                                <label class="form-check form-switch">
                                    <input v-model="employeeForm.pays_shif" class="form-check-input" type="checkbox">
                                    <span class="form-check-label">Pays SHIF</span>
                                </label>
                            </div>
                            <div v-if="employeeForm.pays_shif" class="mb-4">
                                <label for="telephone" class="form-label">SHIF Number</label>
                                <input id="telephone" v-model="employeeForm.shif_no" max="10" type="tel"
                                       class="form-control">
                                <span v-if="employeeForm.hasError('shif_no')" class="text-danger"
                                      v-text="employeeForm.getError('shif_no')"/>
                            </div>
                            <div class="mb-2">
                                <label class="form-check form-switch">
                                    <input v-model="employeeForm.pays_nssf" class="form-check-input" type="checkbox">
                                    <span class="form-check-label">Pays Nssf</span>
                                </label>
                            </div>
                            <div v-if="employeeForm.pays_nssf" class="mb-4">
                                <label for="telephone" class="form-label">Nssf Number</label>
                                <input id="telephone" v-model="employeeForm.nssf_no" max="10" type="tel"
                                       class="form-control">
                                <span v-if="employeeForm.hasError('nssf_no')" class="text-danger"
                                      v-text="employeeForm.getError('nssf_no')"/>
                            </div>
                            <div class="mb-4">
                                <label class="form-check form-switch">
                                    <input v-model="employeeForm.pays_housing_levy" class="form-check-input"
                                           type="checkbox">
                                    <span class="form-check-label">Pays Housing Levy</span>
                                </label>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-2">
                                    <label for="paye_type" class="form-label">Employment status</label>
                                    <v-select id="paye_type" v-model="employeeForm.employment_status"
                                              :options="employmentStatus"
                                              label="name" :clearable="false" :reduce="(option) => option.value">
                                    </v-select>

                                    <span v-if="employeeForm.errors.has('payee_type')" class="text-danger"
                                          v-text="employeeForm.errors.get('payee_type')">
                  </span>
                                </div>
                            </div>
                            <div v-if="employeeForm.conflicts_of_interest" class="col-xl-12">
                                <div class="form-group">
                                    <label for="physical_address" class="form-label">Conflicts of interest</label>
                                    <textarea class="form-control"
                                              v-model="employeeForm.conflicts_of_interest"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between space-x px-4 mb-4">
                        <button class="btn w-25 py-3" :disabled="employeeForm.processing"
                                @click="togglePatientEditForm">
                            Close
                        </button>

                        <button class="btn btn-primary w-25 py-3" :disabled="employeeForm.processing"
                                @click="updateEmployee">
                            Save Changes
                        </button>
                    </div>
                </div>

                <div v-else class="card card-stacked">
                    <div class="card-header">
                        <div>

                            <div class="card-body d-flex align-items-center gap-2">
                     <span v-if="this.employee.profile_image" class="avatar avatar-xl rounded"
                           :style="{ backgroundImage: `url(${backgroundImageUrl ?? ''})` }">
                <div class="dropdown-mnu position-absolute">
                  <a class="item" :href="backgroundImageUrl ?? ''" target="_blank">
                    View image
                  </a>
                  <a @click="showEmployeeImage" class="item" href="#">
                    change image
                  </a>
                </div>
                </span>
                                <span v-else class="avatar avatar-xl rounded"
                                      style="background-image: url(./static/avatars/005f.jpg)">{{
                                        employee.name.slice(0, 2)
                                    }}
                   <div class="dropdown-mnu position-absolute">
                  <a class="item" :href="backgroundImageUrl ?? ''" target="_blank">
                    View image
                  </a>
                  <a @click="showEmployeeImage" class="item" href="#">
                    change image
                  </a>
                </div>
                  </span>
                                <span>
                    <h3 class="m-0 mb-1"><a href="#">{{ employee.name }}</a></h3>
                  <div class="text-secondary">{{ employee.job_title }}</div>
                  </span>

                            </div>

                            <p class="card-subtitle small text-indigo">
                                Registered {{ employee.created_at | dATE_MONTH_YEAR_HOUR_MINUTES }}
                            <div v-if='employee.employment_status === 1' class="text-warning">Suspended</div>
                            <div v-if="employee.employment_status === 2" class="text-danger">Terminated</div>
                            </p>
                        </div>

                        <div class="card-actions">
                            <div class="btn-group">
                                <a href="/employees" class="btn rounded-3 me-3" title="Return to Patients dashboard">
                                    <i class="uil uil-arrow-left me-2"/> Employees
                                </a>

                                <div class="dropdown">
                                    <button class="btn align-text-center" data-bs-toggle="dropdown">
                                        <i class="uil uil-ellipsis-h"/>
                                    </button>

                                    <div class="dropdown-menu dropdown-menu-end">
                                        <h6 class="dropdown-header">
                                            Menu
                                        </h6>
                                        <a href="#" class="dropdown-item" @click.prevent="togglePatientEditForm">
                                            <i class="uil uil-edit me-2"/> Edit Details
                                        </a>

                                        <div class="dropdown-divider"/>
                                        <a href="#" class="dropdown-item" @click.prevent="showPromotionForm">
                                            <i class="uil uil-edit me-2"/> Promote
                                        </a>
                                        <a href="#" class="dropdown-item" @click.prevent="showDemotionForm">
                                            <i class="uil uil-edit me-2"/> Demote
                                        </a>
                                        <a href="#" class="dropdown-item" @click.prevent="showTransferForm">
                                            <i class="uil uil-edit me-2"/>Transfer
                                        </a>
                                        <a href="#" class="dropdown-item" @click.prevent="showCaseForm">
                                            <i class="uil uil-edit me-2"/>Record case
                                        </a>
                                        <!--                    <a href="#" class="dropdown-item" @click.prevent="showP9Form">-->
                                        <!--                      <i class="uil uil-edit me-2" /> P9 Form-->
                                        <!--                    </a>-->
                                        <a href="#" class="dropdown-item" @click.prevent="showHistory">
                                            <i class="uil uil-edit me-2"/> Employment History
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-4">
                        <div class="form-group mb-4">
                            <label class="form-label" for="name">Employee No</label>
                            <div>
                                <input id="name" v-model="employeeForm.staff_number" disabled type="text"
                                       class="form-control">

                                <span v-if="employeeForm.hasError('staff_number')" class="text-danger"
                                      v-text="employeeForm.getError('staff_number')"/>
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <label class="form-label" for="name">Full Name</label>
                            <div>
                                <input id="name" v-model="employeeForm.name" disabled type="text" class="form-control">

                                <span v-if="employeeForm.hasError('name')" class="text-danger"
                                      v-text="employeeForm.getError('name')"/>
                            </div>
                        </div>

                        <div class="row gx-4">
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label class="form-label">Date of Birth
                                    </label>
                                    <div>
                                        <date-picker disabled name="date_of_birth" :value="employeeForm.dob"
                                                     :max-date="new Date()"
                                                     @on-change="(dateOb, dateStr) => {
                        employeeForm.dob = dateStr
                      }"/>

                                        <span v-if="employeeForm.hasError('dob')" class="text-danger"
                                              v-text="employeeForm.getError('dob')"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label class="form-label">Gender </label>
                                    <input id="name" v-model="employeeForm.gender" disabled type="text"
                                           class="form-control">

                                    <span v-if="employeeForm.hasError('gender')" class="text-danger"
                                          v-text="employeeForm.getError('gender')"/>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-4">
                            <label for="identification_value" class="form-label">Identification Number </label>
                            <div>
                                <input id="identification_value" v-model="employeeForm.identification_number" disabled
                                       type="text"
                                       class="form-control">

                                <span v-if="employeeForm.hasError('identification_number')" class="text-danger"
                                      v-text="employeeForm.getError('identification_number')"/>
                            </div>
                        </div>

                        <div class="row gx-4">
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="telephone" class="form-label">Mobile Number </label>
                                    <input id="telephone" v-model="employeeForm.telephone" max="10" disabled type="tel"
                                           class="form-control">
                                    <span v-if="employeeForm.hasError('telephone')" class="text-danger"
                                          v-text="employeeForm.getError('telephone')"/>
                                </div>
                            </div>
                            <div v-if="employeeForm.is_disabled === 1" class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Type of Disability</label>
                                <v-select disabled id="scheme_id" v-model="employeeForm.disability" label="name"
                                          :options="disabilities"
                                          :reduce="(option) => option" :clearable="false"/>
                                <span v-if="employeeForm.hasError('disability')" class="text-danger"
                                      v-text="employeeForm.getError('disability')"/>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Email </label>
                                    <input id="physical_address" v-model="employeeForm.email" type="text" disabled
                                           class="form-control">

                                    <span v-if="employeeForm.hasError('email')" class="text-danger"
                                          v-text="employeeForm.getError('email')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Marital Status</label>

                                    <v-select id="scheme_id" v-model="employeeForm.marital_status" label="name"
                                              :options="maritalStatus"
                                              :reduce="(option) => option.value" :disabled="true" :clearable="false"/>
                                    <span v-if="employeeForm.hasError('marital_status')" class="text-danger"
                                          v-text="employeeForm.getError('marital_status')"/>
                                </div>
                            </div>
                            <p v-if="employeeForm.nextOfKin || employeeForm.nextOfKin_tel">Next of kin</p>
                            <div v-if="employeeForm.nextOfKin" class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Name</label>
                                    <input disabled type="text" class="form-control" v-model="employeeForm.nextOfKin">
                                    <span v-if="employeeForm.hasError('nextOfKin')" class="text-danger"
                                          v-text="employeeForm.getError('nextOfKin')"/>
                                </div>
                            </div>
                            <div v-if="employeeForm.nextOfKin_tel" class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Tel</label>
                                    <input disabled type="text" class="form-control"
                                           v-model="employeeForm.nextOfKin_tel">
                                    <span v-if="employeeForm.hasError('nextOfKin_tel')" class="text-danger"
                                          v-text="employeeForm.getError('nextOfKin_tel')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Residence </label>
                                    <input id="physical_address" v-model="employeeForm.address" type="text" disabled
                                           class="form-control">

                                    <span v-if="employeeForm.hasError('physical_address')" class="text-danger"
                                          v-text="employeeForm.getError('physical_address')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Department</label>
                                    <v-select id="gender_id" v-model="employeeForm.department_id" name="department_id"
                                              disabled
                                              :options="departments" label="name" :reduce="option => option.id"
                                              :clearable="false"/>

                                    <span v-if="employeeForm.hasError('department_id')" class="text-danger"
                                          v-text="employeeForm.getError('department_id')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Supervisor</label>
                                    <v-select id="gender_id" v-model="employeeForm.supervisor" name="supervisor"
                                              disabled
                                              :options="filterEmployee" label="name" :reduce="option => option.id"
                                              :clearable="false"/>

                                    <span v-if="employeeForm.hasError('supervisor')" class="text-danger"
                                          v-text="employeeForm.getError('supervisor')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Nationality </label>
                                    <input id="physical_address" v-model="employeeForm.nationality" type="text" disabled
                                           class="form-control">
                                    <span v-if="employeeForm.hasError('nationality')" class="text-danger"
                                          v-text="employeeForm.getError('nationality')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Home county </label>
                                    <input id="physical_address" v-model="employeeForm.home_county" type="text" disabled
                                           class="form-control">
                                    <span v-if="employeeForm.hasError('home_county')" class="text-danger"
                                          v-text="employeeForm.getError('home_county')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Home sub county </label>
                                    <input id="physical_address" v-model="employeeForm.home_sub_county" type="text"
                                           disabled
                                           class="form-control">
                                    <span v-if="employeeForm.hasError('home_sub_county')" class="text-danger"
                                          v-text="employeeForm.getError('home_sub_county')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Home Constituency </label>
                                    <input id="physical_address" v-model="employeeForm.home_constituency" type="text"
                                           disabled
                                           class="form-control">
                                    <span v-if="employeeForm.hasError('home_constituency')" class="text-danger"
                                          v-text="employeeForm.getError('home_constituency')"/>
                                </div>
                            </div>
                            <div v-if="employeeForm.ethnicity" class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Ethnicity</label>
                                    <input id="physical_address" v-model="employeeForm.ethnicity" type="text" disabled
                                           class="form-control">
                                    <span v-if="employeeForm.hasError('home_constituency')" class="text-danger"
                                          v-text="employeeForm.getError('home_constituency')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Job Title </label>
                                    <input id="physical_address" v-model="employeeForm.job_title" type="text" disabled
                                           class="form-control">

                                    <span v-if="employeeForm.hasError('job_title')" class="text-danger"
                                          v-text="employeeForm.getError('job_title')"/>
                                </div>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Employee Type</label>
                                <v-select id="scheme_id" v-model="employeeForm.employee_type_id" label="name" disabled
                                          :options="employeetypes" :reduce="(option) => option.id" :clearable="false"/>
                                <span v-if="employeeForm.hasError('employee_type_id')" class="text-danger"
                                      v-text="employeeForm.getError('employee_type_id')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Contract Expiry</label>
                                <v-select id="scheme_id" v-model="employeeForm.contract_exp" label="name" disabled
                                          :options="employeetypes" :reduce="(option) => option.id" :clearable="false"/>
                                <span v-if="employeeForm.hasError('contract_exp')" class="text-danger"
                                      v-text="employeeForm.getError('contract_exp')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Job Group</label>
                                <v-select id="scheme_id" v-model="employeeForm.job_group_id" label="name" disabled
                                          :options="jobgroups"
                                          :reduce="(option) => option.id" :clearable="false"/>
                                <span v-if="employeeForm.hasError('job_group_id')" class="text-danger"
                                      v-text="employeeForm.getError('job_group_id')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Location</label>
                                <v-select id="scheme_id" v-model="employeeForm.branch_id" label="name" disabled
                                          :options="branches"
                                          :reduce="(option) => option.id" :clearable="false"/>
                                <span v-if="employeeForm.hasError('branch_id')" class="text-danger"
                                      v-text="employeeForm.getError('branch_id')"/>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-2">
                                    <label class="form-label" for="paye_type">Tax Setup</label>
                                    <v-select id="paye_type" v-model="employeeForm.payee_type" :options="PAYEOptions"
                                              label="name"
                                              disabled="true" :clearable="false" :reduce="(option) => option.value">
                                    </v-select>

                                    <span v-if="employeeForm.errors.has('payee_type')" class="text-danger"
                                          v-text="employeeForm.errors.get('payee_type')">
                  </span>
                                </div>
                            </div>
                            <div v-if="employeeForm.pays_tax" class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Kra PIN</label>
                                    <input id="physical_address" v-model="employeeForm.kra_pin" type="text" disabled
                                           class="form-control">

                                    <span v-if="employeeForm.hasError('kra_pin')" class="text-danger"
                                          v-text="employeeForm.getError('kra_pin')"/>
                                </div>
                            </div>
                            <div v-if="employeeForm.pays_shif" class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">SHIF Number</label>
                                    <input id="physical_address" v-model="employeeForm.shif_no" type="text" disabled
                                           class="form-control">

                                    <span v-if="employeeForm.hasError('shif_no')" class="text-danger"
                                          v-text="employeeForm.getError('shif_no')"/>
                                </div>
                            </div>
                            <div v-if="employeeForm.pays_nssf" class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="physical_address" class="form-label">Nssf Number</label>
                                    <input id="physical_address" v-model="employeeForm.nssf_no" type="text" disabled
                                           class="form-control">

                                    <span v-if="employeeForm.hasError('nssf_no')" class="text-danger"
                                          v-text="employeeForm.getError('nssf_no')"/>
                                </div>
                            </div>
                            <div class="mb-4">
                                <label class="form-check form-switch">
                                    <input disabled v-model="employeeForm.pays_housing_levy" class="form-check-input"
                                           type="checkbox">
                                    <span class="form-check-label">Pays Housing Levy</span>
                                </label>
                            </div>
                            <div v-if="employeeForm.conflicts_of_interest" class="col-xl-12">
                                <div class="form-group">
                                    <label for="physical_address" class="form-label">Conflicts of interest</label>
                                    <textarea class="form-control"
                                              v-model="employeeForm.conflicts_of_interest"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-7">
                <b-tabs nav-class="mb-2" active-nav-item-class="bg-white text-indigo">
                    <b-tab title-link-class="p-3" title-item-class="fs-3">
                        <template #title>
                            <i class="uil uil-history me-1"/> Income
                        </template>

                        <income :employee="employee" :incomes="incomes"/>
                    </b-tab>
                    <b-tab title-link-class="p-3" title-item-class="fs-3">
                        <template #title>
                            <i class="uil uil-history me-1"/> Attachments
                        </template>

                        <attachments :allowances="allowances" :employee="employee"/>
                    </b-tab>
                    <b-tab title-link-class="p-3" title-item-class="fs-3">
                        <template #title>
                            <i class="uil uil-history me-1"/> Benefits
                        </template>

                        <benefits :benefits="benefits" :employee="employee"/>
                    </b-tab>
                    <b-tab title-link-class="p-3" title-item-class="fs-3">
                        <template #title>
                            <i class="uil uil-history me-1"/> Allowances
                        </template>

                        <allowances :allowances="allowances" :employee="employee"/>
                    </b-tab>
                    <b-tab title-link-class="p-3" title-item-class="fs-3">
                        <template #title>
                            <i class="uil uil-history me-1"/> Deductions
                        </template>

                        <dedutions :deductions="deductions" :employee="employee"/>
                    </b-tab>
                    <b-tab title-link-class="p-3" title-item-class="fs-3">
                        <template #title>
                            <i class="uil uil-history me-1"/> Pension
                        </template>

                        <pension :pensions="pensions" :employee="employee"/>
                    </b-tab>
                    <b-tab title-link-class="p-3" title-item-class="fs-3">
                        <template #title>
                            <i class="uil uil-history me-1"/> Relief
                        </template>

                        <relief :reliefs="reliefs" :employee="employee"/>
                    </b-tab>

                    <b-tab title-link-class="p-3" title-item-class="fs-3">
                        <template #title>
                            <i class="uil uil-file-upload-alt me-1"/> Contributions
                        </template>

                        <contributions :employee="employee" :contributions="contributions"/>
                    </b-tab>

                    <b-tab title-link-class="p-3" title-item-class="fs-3">
                        <template #title>
                            <i class="uil uil-graduation-cap me-1"/> Qualifications
                        </template>

                        <qualification :employee="employee" :contributions="contributions"/>
                    </b-tab>
                </b-tabs>
            </div>
        </div>

        <b-modal id="promotion-modal" body-class="px-4" content-class="bg-white rounded-3"
                 footer-class="d-flex justify-content-between bg-muted-lt border-top" hide-header-close
                 modal-class="modal-blur"
                 no-enforce-focus title="Promotion Form" @hidden="cleanUp">
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">New Job Title</label>
                <input id="physical_address" v-model="promotionForm.new_job_title" type="text" class="form-control">

                <span v-if="promotionForm.hasError('new_job_title')" class="text-danger"
                      v-text="promotionForm.getError('new_job_title')"/>
            </div>
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">New Job Group</label>
                <v-select id="item_id" v-model="promotionForm.new_job_group_id" :clearable="false"
                          :reduce="(option) => option.id" :options="jobgroups" label="name">
                </v-select>

                <span v-if="promotionForm.hasError('new_job_group_id')" class="text-danger"
                      v-text="promotionForm.getError('new_job_group_id')"/>
            </div>
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">New Department</label>
                <v-select id="item_id" v-model="promotionForm.new_department_id" :clearable="false"
                          :reduce="(option) => option.id" :options="departments" label="name">
                </v-select>

                <span v-if="promotionForm.hasError('department_id')" class="text-danger"
                      v-text="promotionForm.getError('department_id')"/>
            </div>
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">Promotion Reason</label>
                <textarea v-model="promotionForm.promotion_reason" class="form-control"/>

                <span v-if="form.hasError('promotion_reason')" class="text-danger"
                      v-text="form.getError('promotion_reason')"/>
            </div>
            <div class="form-group mb-4">
                <label class="form-label">Promotion Date</label>
                <div>
                    <date-picker name="date_of_birth" formclass="form-control bg-white"
                                 :value="promotionForm.promotion_date"
                                 :max-date="false" @on-change="(dateOb, dateStr) => {
              promotionForm.promotion_date = dateStr
            }"/>

                    <span v-if="promotionForm.hasError('promotion_date')" class="text-danger"
                          v-text="promotionForm.getError('promotion_date')"/>
                </div>
            </div>
            <div class="form-group mb-4">
                <label class="form-label">Promotion Letter</label>
                <div>
                    <input type="file" class="form-control" @input="promotionForm.letter = $event.target.files[0]">
                    <span v-if="promotionForm.hasError('letter')" class="text-danger"
                          v-text="promotionForm.getError('letter')"/>

                </div>
            </div>
            <div v-if="form.processing" class="progress mb-2">
                <div class="progress">
                    <div class="progress-bar progress-bar-indeterminate bg-green"/>
                </div>
            </div>

            <template #modal-footer="{ cancel }">
                <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
                    Close
                </button>

                <button :disabled="form.processing" class="btn btn-primary" type="submit" @click="promote">
                    Submit
                </button>
            </template>
        </b-modal>
        <b-modal id="demotion-modal" body-class="px-4" content-class="bg-white rounded-3"
                 footer-class="d-flex justify-content-between bg-muted-lt border-top" hide-header-close
                 modal-class="modal-blur"
                 no-enforce-focus title="Demotion Form" @hidden="cleanUp">
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">New Job Title</label>
                <input id="physical_address" v-model="demotionForm.new_job_title" type="text" class="form-control">

                <span v-if="demotionForm.hasError('new_job_title')" class="text-danger"
                      v-text="demotionForm.getError('new_job_title')"/>
            </div>
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">New Job Group</label>
                <v-select id="item_id" v-model="demotionForm.new_job_group_id" :clearable="false"
                          :reduce="(option) => option.id" :options="jobgroups" label="name">
                </v-select>

                <span v-if="demotionForm.hasError('new_job_group_id')" class="text-danger"
                      v-text="demotionForm.getError('new_job_group_id')"/>
            </div>
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">New Department</label>
                <v-select id="item_id" v-model="demotionForm.new_department_id" :clearable="false"
                          :reduce="(option) => option.id" :options="departments" label="name">
                </v-select>

                <span v-if="demotionForm.hasError('department_id')" class="text-danger"
                      v-text="demotionForm.getError('department_id')"/>
            </div>
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">Demotion Reason</label>
                <textarea v-model="demotionForm.demotion_reason" class="form-control"/>

                <span v-if="form.hasError('demotion_reason')" class="text-danger"
                      v-text="form.getError('demotion_reason')"/>
            </div>
            <div class="form-group mb-4">
                <label class="form-label">Demotion Date</label>
                <div>
                    <date-picker name="date_of_birth" formclass="form-control bg-white"
                                 :value="demotionForm.demotion_date"
                                 :max-date="false" @on-change="(dateOb, dateStr) => {
              demotionForm.demotion_date = dateStr
            }"/>

                    <span v-if="demotionForm.hasError('demotion_date')" class="text-danger"
                          v-text="demotionForm.getError('demotion_date')"/>
                </div>
            </div>
            <div v-if="form.processing" class="progress mb-2">
                <div class="progress">
                    <div class="progress-bar progress-bar-indeterminate bg-green"/>
                </div>
            </div>

            <template #modal-footer="{ cancel }">
                <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
                    Close
                </button>

                <button :disabled="form.processing" class="btn btn-primary" type="submit" @click="demotion">
                    Submit
                </button>
            </template>
        </b-modal>
        <b-modal id="transfer-modal" body-class="px-4" content-class="bg-white rounded-3"
                 footer-class="d-flex justify-content-between bg-muted-lt border-top" hide-header-close
                 modal-class="modal-blur"
                 no-enforce-focus title="Job Transfer Form" @hidden="cleanUp">
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">New Job Title</label>
                <input id="physical_address" v-model="transferForm.new_job_title" type="text" class="form-control">

                <span v-if="transferForm.hasError('new_job_title')" class="text-danger"
                      v-text="transferForm.getError('new_job_title')"/>
            </div>
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">New Job Group</label>
                <v-select id="item_id" v-model="transferForm.new_job_group_id" :clearable="false"
                          :reduce="(option) => option.id" :options="jobgroups" label="name">
                </v-select>

                <span v-if="transferForm.hasError('new_job_group_id')" class="text-danger"
                      v-text="transferForm.getError('new_job_group_id')"/>
            </div>
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">New Department</label>
                <v-select id="item_id" v-model="transferForm.new_department_id" :clearable="false"
                          :reduce="(option) => option.id" :options="departments" label="name">
                </v-select>

                <span v-if="transferForm.hasError('department_id')" class="text-danger"
                      v-text="transferForm.getError('department_id')"/>
            </div>
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">Reason for transfer</label>
                <textarea v-model="transferForm.transfer_reason" class="form-control"/>

                <span v-if="form.hasError('transfer_reason')" class="text-danger"
                      v-text="form.getError('transfer_reason')"/>
            </div>
            <div class="form-group mb-4">
                <label class="form-label">Date of transfer</label>
                <div>
                    <date-picker name="date_of_birth" formclass="form-control bg-white"
                                 :value="transferForm.transfer_date"
                                 :max-date="false" @on-change="(dateOb, dateStr) => {
              transferForm.transfer_date = dateStr
            }"/>

                    <span v-if="transferForm.hasError('transfer_date')" class="text-danger"
                          v-text="transferForm.getError('transfer_date')"/>
                </div>
            </div>
            <div v-if="form.processing" class="progress mb-2">
                <div class="progress">
                    <div class="progress-bar progress-bar-indeterminate bg-green"/>
                </div>
            </div>

            <template #modal-footer="{ cancel }">
                <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
                    Close
                </button>

                <button :disabled="form.processing" class="btn btn-primary" type="submit" @click="transfer">
                    Submit
                </button>
            </template>
        </b-modal>
        <b-modal id="record-case-modal" body-class="px-4" content-class="bg-white rounded-3"
                 footer-class="d-flex justify-content-between bg-muted-lt border-top" hide-header-close
                 modal-class="modal-blur"
                 no-enforce-focus title="Record Disciplinary Case" @hidden="cleanUp">
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">Case Description</label>
                <textarea class="form-control" v-model="caseForm.description"></textarea>
                <span v-if="caseForm.hasError('description')" class="text-danger"
                      v-text="caseForm.getError('description')"/>
            </div>
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">Severity Level</label>
                <v-select id="item_id" v-model="caseForm.severity_level" :clearable="false"
                          :reduce="(option) => option.id" :options="severitylevels" label="name">
                </v-select>

                <span v-if="caseForm.hasError('severity_level')" class="text-danger"
                      v-text="caseForm.getError('severity_level')"/>
            </div>
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">Incident Date</label>
                <date-picker name="date_of_birth" :value="caseForm.incident_date" :max-date="new Date()" @on-change="(dateOb, dateStr) => {
                      caseForm.incident_date = dateStr
                    }"/>

                <span v-if="caseForm.hasError('incident_date')" class="text-danger"
                      v-text="caseForm.getError('incident_date')"/>
            </div>
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">Resolution Date</label>
                <date-picker name="date_of_birth" :value="caseForm.resolution_date" :min-date="new Date()" @on-change="(dateOb, dateStr) => {
                      caseForm.resolution_date = dateStr
                    }"/>

                <span v-if="caseForm.hasError('resolution_date')" class="text-danger"
                      v-text="caseForm.getError('resolution_date')"/>
            </div>
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">Resolution Details</label>
                <textarea v-model="caseForm.resolution_details" class="form-control"/>

                <span v-if="form.hasError('resolution_details')" class="text-danger"
                      v-text="form.getError('resolution_details')"/>
            </div>
            <div class="mb-4">
                <label class="form-check form-switch">
                    <input class="form-check-input" v-model="caseForm.status" type="checkbox">
                    <span class="form-check-label">Case closed ?</span>
                </label>
            </div>
            <div v-if="caseForm.status" class="card">
                <div class="card-title p-2">Take Action</div>
                <div class="card-body">
                    <div class="form-group mb-4">
                        <label for="physical_address" class="form-label">Action</label>
                        <v-select id="item_id" v-model="caseForm.action_type_id" :clearable="false"
                                  :reduce="(option) => option.id" :options="actiontypes" label="name">
                        </v-select>

                        <span v-if="caseForm.hasError('action_type_id')" class="text-danger"
                              v-text="caseForm.getError('action_type_id')"/>
                    </div>
                    <div class="form-group mb-4">
                        <label for="physical_address" class="form-label">Action Details</label>
                        <textarea class="form-control" v-model="caseForm.action_details"></textarea>

                        <span v-if="caseForm.hasError('action_details')" class="text-danger"
                              v-text="caseForm.getError('action_details')"/>
                    </div>
                </div>

            </div>
            <div v-if="form.processing" class="progress mb-2">
                <div class="progress">
                    <div class="progress-bar progress-bar-indeterminate bg-green"/>
                </div>
            </div>

            <template #modal-footer="{ cancel }">
                <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
                    Close
                </button>

                <button :disabled="form.processing" class="btn btn-primary" type="submit" @click="recordCase">
                    Submit
                </button>
            </template>
        </b-modal>
        <b-modal id="P10-form-modal" body-class="px-4" content-class="bg-white rounded-3"
                 footer-class="d-flex justify-content-between bg-muted-lt border-top" hide-header-close
                 modal-class="modal-blur"
                 no-enforce-focus title="Generate P9 Form" @hidden="cleanUp">
            <div class="form-group mb-4">
                <label for="physical_address" class="form-label">Year</label>
                <input type="number" name="" v-model="p9Form.year" class="form-control" id="">
                <span v-if="p9Form.hasError('year')" class="text-danger"
                      v-text="p9Form.getError('year')"/>
            </div>
            <div v-if="formP9.processing" class="progress mb-2">
                <div class="progress">
                    <div class="progress-bar progress-bar-indeterminate bg-green"/>
                </div>
            </div>

            <template #modal-footer="{ cancel }">
                <button :disabled="formP9.processing" class="btn" type="button" @click.prevent="cancel">
                    Close
                </button>

                <button :disabled="formP9.processing" class="btn btn-primary" type="submit" @click="formP9">
                    Submit
                </button>
            </template>
        </b-modal>
        <b-modal id="employment-history-modal" body-class="px-4" size="lg" content-class="bg-white rounded-3"
                 footer-class="d-flex justify-content-between bg-muted-lt border-top" hide-header-close
                 modal-class="modal-blur"
                 no-enforce-focus :title="employee.name + ' Employment History'" @hidden="cleanUp">
            <div>
                <div class="containe">
                    <section class="employment-history">
                        <table class="history-table">
                            <thead>
                            <tr>
                                <th>Job Title</th>
                                <th>Department</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Comments</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr :key="employment.id" v-for="employment in employmentHistory">
                                <td>{{ employment.job_title }}</td>
                                <td>{{ employment.department.name }}</td>
                                <td>{{ formatDate(employment.start_date) }}</td>
                                <td>{{ formatDate(employment.end_date) }}</td>
                                <td>{{ employment.notes }}</td>
                            </tr>

                            </tbody>
                        </table>
                    </section>
                </div>
            </div>
            <div v-if="formP9.processing" class="progress mb-2">
                <div class="progress">
                    <div class="progress-bar progress-bar-indeterminate bg-green"/>
                </div>
            </div>

            <template #modal-footer="{ cancel }">
                <button :disabled="formP9.processing" class="btn" type="button" @click.prevent="cancel">
                    Close
                </button>

                <button :disabled="formP9.processing" class="btn btn-primary" type="submit" @click="formP9">
                    Submit
                </button>
            </template>
        </b-modal>
        <b-modal id="profile-image-modal" body-class="px-4" content-class="bg-white rounded-3"
                 footer-class="d-flex justify-content-between bg-muted-lt border-top" hide-header-close
                 modal-class="modal-blur"
                 no-enforce-focus title="Upload profile image">
            <form enctype="multipart/form-data" method="post">
                <input type="file" @input="updateImage" name="" id="">
                <div v-if="formP9.processing" class="progress mb-2">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"/>
                    </div>
                </div>
            </form>


            <template #modal-footer="{ cancel }">
                <button :disabled="employmentphoto.processing" class="btn" type="button" @click.prevent="cancel">
                    Close
                </button>

                <button :disabled="employmentphoto.processing" class="btn btn-primary" type="submit"
                        @click="uploadPhoto">
                    Submit
                </button>
            </template>
        </b-modal>
    </div>
</template>
<style scoped>
.employee-details {
    margin-bottom: 20px;
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
}

.employee-details h2 {
    margin: 0 0 10px 0;
    font-size: 1.5em;
    color: #555;
}

.employee-details p {
    margin: 5px 0;
    font-size: 1em;
    color: #666;
}

.employment-history {
    margin-top: 20px;
}

.employment-history h2 {
    margin-bottom: 15px;
    font-size: 1.4em;
    color: #333;
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
}

.history-table {
    width: 100%;
    border-collapse: collapse;
}

.history-table th, .history-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.history-table th {
    background-color: #f9fafb;
    font-weight: bold;
}

.history-table td {
    background-color: #fff;
}

.avatar {
    cursor: pointer;
    position: relative;
}

.dropdown-mnu {
    display: none;
    font-size: 10px;

    top: 0px;
    width: 100%;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background: rgba(255, 255, 255, 0.8);
    transition: .3s ease-in;
}

.avatar:hover > .dropdown-mnu {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
}

.dropdown-mnu .item {
    color: #111010;
    font-weight: bolder;
}
</style>
