<script>
import { toPrice } from '@plugins/money'

export default {
    name:'PayrollDetailsComponent',
    props: {
        rowData: {
            type: Object,
            required: true,
        },
        rowIndex: {
            type: Number,
        },
    },
    data () {
        return {
            fields: [
                {
                    name: 'description',
                    title: 'Product',
                    width: '40%',
                },
                {
                    name: 'bulk_qty',
                    title: 'QTY',
                    titleClass: 'text-center',
                    dataClass: 'text-center',
                },
                {
                    name: 'discount_amount',
                    title: 'DISC',
                    titleClass: 'text-right',
                    dataClass: 'text-right',
                    callback: (value) => toPrice(value * 0.01).toFormat('0,0.00'),
                },
                {
                    name: 'tax_amount',
                    title: 'TAX',
                    titleClass: 'text-right',
                    dataClass: 'text-right',
                    callback: (value) => toPrice(value * 0.01).toFormat('0,0.00'),
                },
                {
                    name: 'bulk_cost',
                    title: 'PRICE',
                    dataClass: 'text-right',
                    titleClass: 'text-right',
                    callback: (value) => toPrice(value * 0.01).toFormat('0,0.00'),
                },
                {
                    name: 'total',
                    title: 'TOTAL',
                    dataClass: 'text-right',
                    titleClass: 'text-right',
                    callback: (value) => toPrice(value * 0.01).toFormat('0,0.00'),
                },
            ],

            moreParams: {
                filter: { purchase_order_id: this.rowData.id },
                sort: '-created_at',
            },

            allowances: [],
            earnings: [],
            deductions: [],
            benefits: [],
            pensions: [],
            reliefs: [],
            contributions: [],
            advances: [],

        }
    },

    mounted () {
        this.getDeductions()
        this.getEarnings()
        this.getAllowances()
        this.getBenefits()
        this.getReliefs()
        this.getPensions()
        this.getContributions()
        this.getAdvances()
    },

    methods: {
        getEarnings () {
            this.$httpClient.get('/datatable/payroll-in-detail', {
                params: {
                    filter: {
                        payrollId: this.rowData.id,
                        source: 1
                    },
                },
            }).then(({ data }) => {
                this.earnings = data.data
            }).catch((error) => {
                console.log(error)
            })
        },
        getAllowances () {
            this.$httpClient.get('/datatable/payroll-in-detail', {
                params: {
                    filter: {
                        payrollId: this.rowData.id,
                        source: 2
                    },
                },
            }).then(({ data }) => {
                this.allowances = data.data
            }).catch((error) => {
                console.log(error)
            })
        },
        getBenefits () {
            this.$httpClient.get('/datatable/payroll-in-detail', {
                params: {
                    filter: {
                        payrollId: this.rowData.id,
                        source: 8
                    },
                },
            }).then(({ data }) => {
                this.benefits = data.data
            }).catch((error) => {
                console.log(error)
            })
        },
        getDeductions () {
            this.$httpClient.get('/datatable/payroll-in-detail', {
                params: {
                    filter: {
                        payrollId: this.rowData.id,
                        source: 3
                    },
                },
            }).then(({ data }) => {
                this.deductions = data.data
            }).catch((error) => {
                console.log(error)
            })
        },
        getPensions () {
            this.$httpClient.get('/datatable/payroll-in-detail', {
                params: {
                    filter: {
                        payrollId: this.rowData.id,
                        source: 4
                    },
                },
            }).then(({ data }) => {
                this.pensions = data.data
            }).catch((error) => {
                console.log(error)
            })
        },
        getContributions () {
            this.$httpClient.get('/datatable/payroll-in-detail', {
                params: {
                    filter: {
                        payrollId: this.rowData.id,
                        source: 6
                    },
                },
            }).then(({ data }) => {
                this.contributions = data.data
            }).catch((error) => {
                console.log(error)
            })
        },
        getAdvances () {
            this.$httpClient.get('/datatable/payroll-in-detail', {
                params: {
                    filter: {
                        payrollId: this.rowData.id,
                        source: 7
                    },
                },
            }).then(({ data }) => {
                this.advances = data.data
            }).catch((error) => {
                console.log(error)
            })
        },
        getReliefs () {
            this.$httpClient.get('/datatable/payroll-in-detail', {
                params: {
                    filter: {
                        payrollId: this.rowData.id,
                        source: 5
                    },
                },
            }).then(({ data }) => {
                this.reliefs = data.data
            }).catch((error) => {
                console.log(error)
            })
        },
    },
}
</script>

<template>
    <div class="row gy-3 my-3">
        <div v-if="earnings.length !== 0" class="col-lg-4">
            <div class="card">
                <div class="card-title p-2">
                    Earnings
                </div>
                <div class="table-responsive" style="overflow: hidden">
                    <table class="styled-table">
                        <thead >
                        <tr style="color: #ffffff !important;">
                            <th class="text-center">
                                #
                            </th>
                            <th class="ps-3">
                                Description
                            </th>
                            <th class="pe-3 text-end">
                                Amount
                            </th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(record, index) in earnings">
                            <td class="text-center text-muted">
                                {{ index + 1 }}
                            </td>
                            <td class="ps-3">
                                {{ record.description }}
                            </td>
                            <td class="pe-3 text-end">
                                {{ (record.amount * 0.01) | toMoneyFormat }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="allowances.length !== 0" class="col-lg-4">
            <div class="card">
                <div class="card-title p-2">
                    Allowances
                </div>
                <div class="table-responsive" style="overflow: hidden">
                    <table class="styled-table">
                        <thead >
                        <tr style="color: #ffffff !important;">
                            <th class="text-center">
                                #
                            </th>
                            <th class="ps-3">
                                Description
                            </th>
                            <th class="pe-3 text-end">
                                Amount
                            </th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(record, index) in allowances">
                            <td class="text-center text-muted">
                                {{ index + 1 }}
                            </td>
                            <td class="ps-3">
                                {{ record.description }}
                            </td>
                            <td class="pe-3 text-end">
                                {{ (record.amount * 0.01) | toMoneyFormat }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="benefits.length !== 0" class="col-lg-4">
            <div class="card">
                <div class="card-title p-2">
                    Benefits
                </div>
                <div class="table-responsive" style="overflow: hidden">
                    <table class="styled-table">
                        <thead >
                        <tr style="color: #ffffff !important;">
                            <th class="text-center">
                                #
                            </th>
                            <th class="ps-3">
                                Description
                            </th>
                            <th class="pe-3 text-end">
                                Amount
                            </th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(record, index) in benefits">
                            <td class="text-center text-muted">
                                {{ index + 1 }}
                            </td>
                            <td class="ps-3">
                                {{ record.description }}
                            </td>
                            <td class="pe-3 text-end">
                                {{ (record.amount * 0.01) | toMoneyFormat }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="reliefs.length !== 0" class="col-lg-4">
            <div class="card">
                <div class="card-title p-2">
                    Reliefs
                </div>
                <div class="table-responsive" style="overflow: hidden">
                    <table class="styled-table">
                        <thead >
                        <tr style="color: #ffffff !important;">
                            <th class="text-center">
                                #
                            </th>
                            <th class="ps-3">
                                Description
                            </th>
                            <th class="pe-3 text-end">
                                Amount
                            </th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(record, index) in reliefs">
                            <td class="text-center text-muted">
                                {{ index + 1 }}
                            </td>
                            <td class="ps-3">
                                {{ record.description }}
                            </td>
                            <td class="pe-3 text-end">
                                {{ (record.amount * 0.01) | toMoneyFormat }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="deductions.length !== 0" class="col-lg-4">
            <div class="card">
                <div class="card-title p-2">
                    Deductions
                </div>
                <div class="table-responsive" style="overflow: hidden">
                    <table class="styled-table">
                        <thead >
                        <tr style="color: #ffffff !important;">
                            <th class="text-center">
                                #
                            </th>
                            <th class="ps-3">
                                Description
                            </th>
                            <th class="pe-3 text-end">
                                Amount
                            </th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(record, index) in deductions">
                            <td class="text-center text-muted">
                                {{ index + 1 }}
                            </td>
                            <td class="ps-3">
                                {{ record.description }}
                            </td>
                            <td class="pe-3 text-end">
                                {{ (record.amount * 0.01) | toMoneyFormat }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="pensions.length !== 0" class="col-lg-4">
            <div class="card">
                <div class="card-title p-2">
                    Pensions
                </div>
                <div class="table-responsive" style="overflow: hidden">
                    <table class="styled-table">
                        <thead >
                        <tr style="color: #ffffff !important;">
                            <th class="text-center">
                                #
                            </th>
                            <th class="ps-3">
                                Description
                            </th>
                            <th class="pe-3 text-end">
                                Amount
                            </th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(record, index) in pensions">
                            <td class="text-center text-muted">
                                {{ index + 1 }}
                            </td>
                            <td class="ps-3">
                                {{ record.description }}
                            </td>
                            <td class="pe-3 text-end">
                                {{ (record.amount * 0.01) | toMoneyFormat }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="contributions.length !== 0" class="col-lg-4">
            <div class="card">
                <div class="card-title p-2">
                    Contributions
                </div>
                <div class="table-responsive" style="overflow: hidden">
                    <table class="styled-table">
                        <thead >
                        <tr style="color: #ffffff !important;">
                            <th class="text-center">
                                #
                            </th>
                            <th class="ps-3">
                                Description
                            </th>
                            <th class="pe-3 text-end">
                                Amount
                            </th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(record, index) in contributions">
                            <td class="text-center text-muted">
                                {{ index + 1 }}
                            </td>
                            <td class="ps-3">
                                {{ record.description }}
                            </td>
                            <td class="pe-3 text-end">
                                {{ (record.amount * 0.01) | toMoneyFormat }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="advances.length !==0" class="col-lg-4">
            <div class="card">
                <div class="card-title p-2">
                    Advances
                </div>
                <div class="table-responsive" style="overflow: hidden">
                    <table class="styled-table">
                        <thead >
                        <tr style="color: #ffffff !important;">
                            <th class="text-center">
                                #
                            </th>
                            <th class="ps-3">
                                Description
                            </th>
                            <th class="pe-3 text-end">
                                Amount
                            </th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(record, index) in advances">
                            <td class="text-center text-muted">
                                {{ index + 1 }}
                            </td>
                            <td class="ps-3">
                                {{ record.description }}
                            </td>
                            <td class="pe-3 text-end">
                                {{ (record.amount * 0.01) | toMoneyFormat }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.styled-table {
    border-collapse: collapse;
    margin: 0 0;
    font-size: 14px;
    min-width: 100%;
}

.styled-table thead tr {
    background-color: #f4f5f7;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 6px 10px;
}

.styled-table tbody tr {
//border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr td {
    border: 1px solid #f3f3f3
}

.styled-table tbody tr:nth-of-type(even) {
//background-color: #f3f3f3;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

</style>
