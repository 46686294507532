var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card card-stacked" },
        [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "input-icon me-2" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.moreParams.filter.search,
                    expression: "moreParams.filter.search",
                  },
                ],
                staticClass: "form-control bg-muted-lt rounded-2",
                attrs: { type: "text", placeholder: "Search employee" },
                domProps: { value: _vm.moreParams.filter.search },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.moreParams.filter,
                        "search",
                        $event.target.value
                      )
                    },
                    _vm.applyFilter,
                  ],
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-actions" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.showCreateForm },
                },
                [
                  _c("i", { staticClass: "uil uil-plus me-1" }),
                  _vm._v("Give Leave\n          "),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("VueTable", {
            ref: "table",
            attrs: {
              "api-url": "/datatable/leave-requests",
              fields: _vm.fields,
              "append-params": _vm.moreParams,
            },
            scopedSlots: _vm._u([
              {
                key: "approved",
                fn: function (props) {
                  return [
                    _c("div", { staticClass: "media" }, [
                      _c(
                        "div",
                        { staticClass: "media-body align-self-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "font-size-12 text-purple-300" },
                            [
                              props.rowData.supervisor_approved
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge badge-success rounded px-1 p-1 shadow-sm border",
                                    },
                                    [
                                      _c("small", [
                                        _vm._v("supervisor has approved"),
                                      ]),
                                    ]
                                  )
                                : props.rowData.supervisor_rejected
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge badge-soft-warning rounded px-1 p-1 shadow-sm border",
                                    },
                                    [
                                      _c("small", [
                                        _vm._v("supervisor has rejected"),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge badge-soft-secondary shadow-sm",
                                    },
                                    [_vm._v("pending supervisor approval")]
                                  ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "actions",
                fn: function (props) {
                  return [
                    _c("div", { staticClass: "dropdown" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn align-text-top py-1",
                          attrs: { "data-bs-toggle": "dropdown" },
                        },
                        [_c("i", { staticClass: "uil uil-ellipsis-h" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dropdown-menu dropdown-menu-end" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.viewLeave(props.rowData)
                                },
                              },
                            },
                            [_vm._v("\n                View\n              ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.approveLeave(props.rowData)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                 Approve\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.showRejectForm(props.rowData)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                 Reject\n              "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "create-modal",
            title: "Give a Leave",
            "hide-header-close": "",
            size: "lg",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
            "no-close-on-esc": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.giveLeave.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "form",
            {
              attrs: { id: "createForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.createAssignLeave.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Employee Name")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "department_id",
                          options: _vm.employees,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.form.employee_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "employee_id", $$v)
                          },
                          expression: "form.employee_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("employee_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("employee_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Leave Type")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "leave_type_id",
                          options: _vm.assignedLeaves,
                          label: "leave_type_id",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function (option) {
                              return [
                                _c("li", { staticClass: "media mb-1" }, [
                                  _c("div", { staticClass: "media-body" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(option.leave_type.name)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "selected-option",
                            fn: function (option) {
                              return [
                                _c("li", { staticClass: "media mb-1" }, [
                                  _c("div", { staticClass: "media-body" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(option.leave_type.name)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.form.leave_type_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "leave_type_id", $$v)
                          },
                          expression: "form.leave_type_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("leave_type_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("leave_type_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Start Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          value: _vm.form.start_date,
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.start_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("start_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("start_date")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("End Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_end",
                          value: _vm.form.end_date,
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.end_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("end_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("end_date")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Return Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "return_date",
                          value: _vm.form.return_date,
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.return_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("return_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("return_date")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c("label", { staticClass: "form-check form-switch" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.approved,
                            expression: "form.approved",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.form.approved)
                            ? _vm._i(_vm.form.approved, null) > -1
                            : _vm.form.approved,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.form.approved,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.form,
                                    "approved",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.form,
                                    "approved",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.form, "approved", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "form-check-label" }, [
                        _vm._v("Approve"),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm.leaveBalance
                  ? _c("span", { staticClass: "text-warning" }, [
                      _vm._v(
                        "leave balance: " +
                          _vm._s(_vm.leaveBalance.balance) +
                          " day(s)"
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.form.processing
                ? _c("div", { staticClass: "progress mb-2" }, [
                    _c("div", { staticClass: "progress" }, [
                      _c("div", {
                        staticClass:
                          "progress-bar progress-bar-indeterminate bg-green",
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "alter-leave-modal",
            title: "Alter Leave",
            "hide-header-close": "",
            size: "lg",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
            "no-close-on-esc": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.alteration.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "form",
            {
              attrs: { id: "createForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.createAssignLeave.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Employee Name")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "department_id",
                          options: _vm.employees,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                          disabled: true,
                        },
                        model: {
                          value: _vm.alterForm.employee_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.alterForm, "employee_id", $$v)
                          },
                          expression: "alterForm.employee_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.alterForm.hasError("employee_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.alterForm.getError("employee_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Start Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          disabled: true,
                          value: _vm.alterForm.start_date,
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.alterForm.start_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.alterForm.hasError("start_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.alterForm.getError("start_date")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("End Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_end",
                          disabled: true,
                          value: _vm.alterForm.end_date,
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.alterForm.end_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.alterForm.hasError("end_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.alterForm.getError("end_date")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Return Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          disabled: true,
                          name: "return_date",
                          value: _vm.alterForm.return_date,
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.alterForm.return_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.alterForm.hasError("return_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.alterForm.getError("return_date")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Days")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.alterForm.alter_day,
                          expression: "alterForm.alter_day",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", name: "", id: "" },
                      domProps: { value: _vm.alterForm.alter_day },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.alterForm,
                            "alter_day",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.alterForm.hasError("alter_day")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.alterForm.getError("alter_day")
                            ),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Add day to be added or removed from leave"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c("label", { staticClass: "form-check form-switch" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.alterForm.recalculate,
                            expression: "alterForm.recalculate",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.alterForm.recalculate)
                            ? _vm._i(_vm.alterForm.recalculate, null) > -1
                            : _vm.alterForm.recalculate,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.alterForm.recalculate,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.alterForm,
                                    "recalculate",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.alterForm,
                                    "recalculate",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.alterForm, "recalculate", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "form-check-label" }, [
                        _vm._v("Recalculate Leave balance"),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm.leaveBalance
                  ? _c("span", { staticClass: "text-warning" }, [
                      _vm._v(
                        "leave balance: " +
                          _vm._s(_vm.leaveBalance.balance) +
                          " day(s)"
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.form.processing
                ? _c("div", { staticClass: "progress mb-2" }, [
                    _c("div", { staticClass: "progress" }, [
                      _c("div", {
                        staticClass:
                          "progress-bar progress-bar-indeterminate bg-green",
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "view-modal",
            title: "Leave Request",
            "hide-header-close": "",
            size: "lg",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
            "no-close-on-esc": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm.selectedRequest
            ? _c("div", { staticClass: "row g-3 align-items-center" }, [
                _c("div", { staticClass: "col-auto" }, [
                  _vm.backgroundImageUrl != ""
                    ? _c(
                        "span",
                        {
                          staticClass: "avatar avatar-xl rounded",
                          style: {
                            backgroundImage: `url(${
                              _vm.backgroundImageUrl ?? ""
                            })`,
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "dropdown-mnu position-absolute",
                          }),
                        ]
                      )
                    : _c("span", { staticClass: "avatar avatar-md" }, [
                        _vm._v(
                          _vm._s(
                            _vm.selectedRequest.employee_leave_type
                              ? _vm.selectedRequest.employee_leave_type.employee.name
                                  .slice(0, 2)
                                  .toUpperCase()
                              : ""
                          )
                        ),
                      ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-auto" }, [
                  _c("div", { staticClass: "fs-3" }, [
                    _vm._v(
                      "\n             " +
                        _vm._s(
                          _vm.selectedRequest.employee_leave_type.employee
                            .name ?? "N/A"
                        ) +
                        "\n           "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-muted" }, [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.selectedRequest.employee_leave_type.employee
                              .job_title
                          ) +
                          " "
                      ),
                    ]),
                    _vm._v("  | "),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.selectedRequest.employee_leave_type.employee
                              .department.name
                          )
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-header" }, [
                    _vm._v("\n             Leave Information\n           "),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("h6", { staticClass: "card-title" }, [
                          _vm._v("Leave Type:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-9" }, [
                        _c(
                          "p",
                          {
                            staticClass: "card-text",
                            attrs: { id: "leave-type" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.selectedRequest.employee_leave_type
                                  .leave_type.name
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("h6", { staticClass: "card-title" }, [
                          _vm._v("Start Date:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-9" }, [
                        _c(
                          "p",
                          {
                            staticClass: "card-text",
                            attrs: { id: "start-date" },
                          },
                          [_vm._v(_vm._s(_vm.selectedRequest.start_date))]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("h6", { staticClass: "card-title" }, [
                          _vm._v("End Date:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-9" }, [
                        _c(
                          "p",
                          {
                            staticClass: "card-text",
                            attrs: { id: "end-date" },
                          },
                          [_vm._v(_vm._s(_vm.selectedRequest.end_date))]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("h6", { staticClass: "card-title" }, [
                          _vm._v("Days:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-9" }, [
                        _c(
                          "p",
                          { staticClass: "card-text", attrs: { id: "days" } },
                          [_vm._v(_vm._s(_vm.selectedRequest.days))]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("h6", { staticClass: "card-title" }, [
                          _vm._v("Reason:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-9" }, [
                        _c(
                          "p",
                          { staticClass: "card-text", attrs: { id: "reason" } },
                          [_vm._v(_vm._s(_vm.selectedRequest.reason))]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "update-modal",
            title: "Edit Leave",
            "hide-header-close": "",
            size: "lg",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
            "no-close-on-esc": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.editLeave.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "form",
            {
              attrs: { id: "createForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.editLeave.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Employee Name")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "department_id",
                          options: _vm.employees,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.form.employee_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "employee_id", $$v)
                          },
                          expression: "form.employee_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("employee_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("employee_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Leave Type")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "leave_type_id",
                          options: _vm.leave_types,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.form.leave_type_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "leave_type_id", $$v)
                          },
                          expression: "form.leave_type_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("leave_type_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("leave_type_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Start Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          value: _vm.form.start_date,
                          "max-date": new Date(),
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.start_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("start_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("start_date")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("End Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          value: _vm.form.end_date,
                          "max-date": new Date(),
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.end_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("end_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("end_date ")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.processing
                ? _c("div", { staticClass: "progress mb-2" }, [
                    _c("div", { staticClass: "progress" }, [
                      _c("div", {
                        staticClass:
                          "progress-bar progress-bar-indeterminate bg-green",
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "reject-reason",
            title: "Reason for Reject",
            "hide-header-close": "",
            size: "sm",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
            "no-close-on-esc": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.rejectLeave.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "form",
            {
              attrs: { id: "createForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.editLeave.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Reject Reason")]
                    ),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.reason,
                          expression: "form.reason",
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: _vm.form.reason },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "reason", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("employee_id")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.form.getError("employee_id")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.processing
                ? _c("div", { staticClass: "progress mb-2" }, [
                    _c("div", { staticClass: "progress" }, [
                      _c("div", {
                        staticClass:
                          "progress-bar progress-bar-indeterminate bg-green",
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-icon-addon" }, [
      _c("i", { staticClass: "uil uil-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }