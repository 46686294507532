var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card card-stacked" },
        [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "input-icon me-2" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.moreParams.filter.search,
                    expression: "moreParams.filter.search",
                  },
                ],
                staticClass: "form-control bg-muted-lt rounded-2",
                attrs: { type: "text", placeholder: "Search application" },
                domProps: { value: _vm.moreParams.filter.search },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.moreParams.filter,
                        "search",
                        $event.target.value
                      )
                    },
                    _vm.applyFilter,
                  ],
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-actions" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.showCreateForm },
                },
                [
                  _c("i", { staticClass: "uil uil-plus me-1" }),
                  _vm._v("Add\n          "),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("VueTable", {
            ref: "table",
            attrs: {
              "api-url": "/datatable/applications",
              fields: _vm.fields,
              "append-params": _vm.moreParams,
            },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function (props) {
                  return [
                    _c("div", { staticClass: "dropdown" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn align-text-top py-1",
                          attrs: { "data-bs-toggle": "dropdown" },
                        },
                        [_c("i", { staticClass: "uil uil-ellipsis-h" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dropdown-menu dropdown-menu-end" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.viewApplication(props.rowData)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    view\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          props.rowData.status === "Applied"
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.shortList(props.rowData)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                 Short List\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          props.rowData.status === "Short listed"
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showInterviewScheduleForm(
                                        props.rowData
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                 Schedule Interview\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          props.rowData.status === "Interview passed"
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showJobOfferForm(props.rowData)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                 Offer a job\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          props.rowData.status === "Interview passed"
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.rejectedOffer(props.rowData)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    Rejected Offer\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          props.rowData.status === "Interview Scheduled"
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showInterviewForm(
                                        props.rowData
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                interview\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          props.rowData.status === "Applied"
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showEditForm(props.rowData)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n               Decline\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          props.rowData.status ===
                          "Offered                                                                                         "
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showEditForm(props.rowData)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n               Hire\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          props.rowData.status === "Interview failed" ||
                          props.rowData.status === "Rejected Offer"
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item text-danger",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteFailedInterview(
                                        props.rowData
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Del application\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          props.rowData.status === "Applied"
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item text-danger",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteApplicationn(
                                        props.rowData
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                 Del application\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "create-modal",
            title: "Job Application",
            size: "lg",
            "hide-header-close": "",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.createAssignLeave.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "form",
            {
              attrs: { id: "createForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.createAssignLeave.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Applicant name")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.name,
                          expression: "form.name",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "name" },
                      domProps: { value: _vm.form.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "name", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("name")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("name")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Applicant email")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.email,
                          expression: "form.email",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "email", id: "email" },
                      domProps: { value: _vm.form.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "email", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("email")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("email")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Death Of Birth")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          value: _vm.form.dob,
                          "max-date": false,
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.dob = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("dob")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.form.getError("dob")),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Gender")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "gender",
                          options: ["male", "female"],
                          label: "name",
                          reduce: (option) => option,
                          clearable: false,
                        },
                        model: {
                          value: _vm.form.gender,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "gender", $$v)
                          },
                          expression: "form.gender",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("gender")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.form.getError("gender")),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Nationality")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.nationality,
                          expression: "form.nationality",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "nationality" },
                      domProps: { value: _vm.form.nationality },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "nationality", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("nationality")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.form.getError("nationality")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("ID no")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.id_no,
                          expression: "form.id_no",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "email" },
                      domProps: { value: _vm.form.id_no },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "id_no", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("id_no")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("id_no")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Residence")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.residence,
                          expression: "form.residence",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "email" },
                      domProps: { value: _vm.form.residence },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "residence", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("residence")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("residence")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Applicant phone")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.phone,
                          expression: "form.phone",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "phone" },
                      domProps: { value: _vm.form.phone },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "phone", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("phone")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("phone")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Vacancy")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "vacancy_id",
                          options: _vm.vacancies,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.form.vacancy_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "vacancy_id", $$v)
                          },
                          expression: "form.vacancy_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("vacancy_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("vacancy_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.processing
                ? _c("div", { staticClass: "progress mb-2" }, [
                    _c("div", { staticClass: "progress" }, [
                      _c("div", {
                        staticClass:
                          "progress-bar progress-bar-indeterminate bg-green",
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "update-modal",
            title: "Edit Leave",
            "hide-header-close": "",
            size: "lg",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
            "no-close-on-esc": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.editLeave.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "form",
            {
              attrs: { id: "createForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.editLeave.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Leave Type")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "leave_type_id",
                          options: _vm.leave_types,
                          label: "leave_type_id",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function (option) {
                              return [
                                _c("li", { staticClass: "media mb-1" }, [
                                  _c("div", { staticClass: "media-body" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(option.leave_type.name)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "selected-option",
                            fn: function (option) {
                              return [
                                _c("li", { staticClass: "media mb-1" }, [
                                  _c("div", { staticClass: "media-body" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(option.leave_type.name)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.form.employee_leave_type_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "employee_leave_type_id", $$v)
                          },
                          expression: "form.employee_leave_type_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("leave_type_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("leave_type_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Start Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          value: _vm.form.start_date,
                          "max-date": false,
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.start_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("start_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("start_date")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("End Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          value: _vm.form.end_date,
                          "max-date": false,
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.end_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("end_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("end_date ")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Return Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          value: _vm.form.return_date,
                          "max-date": _vm.futureDate,
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.return_date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("return_date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("return_date ")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.processing
                ? _c("div", { staticClass: "progress mb-2" }, [
                    _c("div", { staticClass: "progress" }, [
                      _c("div", {
                        staticClass:
                          "progress-bar progress-bar-indeterminate bg-green",
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "interview-schedule-modal",
            title: "Schedule an Interview",
            "hide-header-close": "",
            size: "lg",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.scheduleInterview.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "form",
            {
              attrs: { id: "interview" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.scheduleInterview.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Applicant name")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.applicant.name,
                          expression: "applicant.name",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", disabled: "", id: "name" },
                      domProps: { value: _vm.applicant.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.applicant, "name", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("name")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("name")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Applicant email")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.applicant.email,
                          expression: "applicant.email",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "email", disabled: "", id: "email" },
                      domProps: { value: _vm.applicant.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.applicant, "email", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("email")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("email")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Applicant phone")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.applicant.phone,
                          expression: "applicant.phone",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", disabled: "", id: "phone" },
                      domProps: { value: _vm.applicant.phone },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.applicant, "phone", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("phone")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("phone")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Vacancy")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "vacancy_id",
                          options: _vm.vacancies,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                          disabled: "",
                        },
                        model: {
                          value: _vm.interviewForm.vacancy_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.interviewForm, "vacancy_id", $$v)
                          },
                          expression: "interviewForm.vacancy_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("vacancy_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("vacancy_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Hiring Manager")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "interviewer_id",
                          options: _vm.employees,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                          disabled: "",
                        },
                        model: {
                          value: _vm.interviewForm.interviewer_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.interviewForm, "interviewer_id", $$v)
                          },
                          expression: "interviewForm.interviewer_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.interviewForm.hasError("interviewer_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.interviewForm.getError("interviewer_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Interview Title")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.interviewForm.interview_title,
                          expression: "interviewForm.interview_title",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.interviewForm.interview_title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.interviewForm,
                            "interview_title",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.interviewForm.hasError("interview_title")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.interviewForm.getError("interview_title")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Date and Time")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          "enable-time": true,
                          "date-format": "Y-m-d H:i",
                          value: _vm.interviewForm.date_and_time,
                          "max-date": false,
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.interviewForm.date_and_time = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.interviewForm.hasError("date_and_time")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.interviewForm.getError("date_and_time")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Notes")]
                    ),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.interviewForm.notes,
                          expression: "interviewForm.notes",
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: _vm.interviewForm.notes },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.interviewForm,
                            "notes",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.interviewForm.hasError("notes")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.interviewForm.getError("notes")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.processing
                ? _c("div", { staticClass: "progress mb-2" }, [
                    _c("div", { staticClass: "progress" }, [
                      _c("div", {
                        staticClass:
                          "progress-bar progress-bar-indeterminate bg-green",
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "interview-modal",
            title: "Interview",
            "hide-header-close": "",
            size: "lg",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submitInterview.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "form",
            {
              attrs: { id: "interview" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitInterview.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Applicant name")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.applicant.name,
                          expression: "applicant.name",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", disabled: "", id: "name" },
                      domProps: { value: _vm.applicant.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.applicant, "name", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("name")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("name")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Applicant email")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.applicant.email,
                          expression: "applicant.email",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "email", disabled: "", id: "email" },
                      domProps: { value: _vm.applicant.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.applicant, "email", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("email")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("email")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Applicant phone")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.applicant.phone,
                          expression: "applicant.phone",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", disabled: "", id: "phone" },
                      domProps: { value: _vm.applicant.phone },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.applicant, "phone", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("phone")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("phone")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Vacancy")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "vacancy_id",
                          options: _vm.vacancies,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                          disabled: "",
                        },
                        model: {
                          value: _vm.interviewForm.vacancy_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.interviewForm, "vacancy_id", $$v)
                          },
                          expression: "interviewForm.vacancy_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("vacancy_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("vacancy_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Interview Title")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.interview.interview_title,
                          expression: "interview.interview_title",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", disabled: "" },
                      domProps: { value: _vm.interview.interview_title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.interview,
                            "interview_title",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.interviewForm.hasError("interview_title")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.interviewForm.getError("interview_title")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Interviewer")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "vacancy_id",
                          options: _vm.employees,
                          label: "name",
                          disabled: "",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.interview.interviewer_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.interview, "interviewer_id", $$v)
                          },
                          expression: "interview.interviewer_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.interviewForm.hasError("vacancy_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.interviewForm.getError("vacancy_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Date and Time")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          disabled: "",
                          name: "date_of_birth",
                          "enable-time": true,
                          "date-format": "Y-m-d H:i",
                          value: _vm.interview.date_and_time,
                          "max-date": false,
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.interview.date_and_time = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.interviewForm.hasError("date_and_time")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.interviewForm.getError("date_and_time")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Notes")]
                    ),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.interview.notes,
                          expression: "interview.notes",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { disabled: "" },
                      domProps: { value: _vm.interview.notes },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.interview, "notes", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.interviewForm.hasError("notes")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.interviewForm.getError("notes")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Feedback")]
                    ),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.interviewResult.feedback,
                          expression: "interviewResult.feedback",
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: _vm.interviewResult.feedback },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.interviewResult,
                            "feedback",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.interviewResult.hasError("feedback")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.interviewResult.getError("feedback")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("label", { staticClass: "form-check form-switch" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.interviewResult.interPassed,
                          expression: "interviewResult.interPassed",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.interviewResult.interPassed)
                          ? _vm._i(_vm.interviewResult.interPassed, null) > -1
                          : _vm.interviewResult.interPassed,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.interviewResult.interPassed,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.interviewResult,
                                  "interPassed",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.interviewResult,
                                  "interPassed",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.interviewResult, "interPassed", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "form-check-label" }, [
                      _vm._v("Passed interview"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.processing
                ? _c("div", { staticClass: "progress mb-2" }, [
                    _c("div", { staticClass: "progress" }, [
                      _c("div", {
                        staticClass:
                          "progress-bar progress-bar-indeterminate bg-green",
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "job-offer-modal",
            title: "Job Offer",
            "hide-header-close": "",
            size: "lg",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.hire.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Hire\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "form",
            {
              attrs: { id: "interview" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.hire.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Payroll number")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.offerJob.payroll_number,
                          expression: "offerJob.payroll_number",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "payroll_number" },
                      domProps: { value: _vm.offerJob.payroll_number },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.offerJob,
                            "payroll_number",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.offerJob.hasError("payroll_number")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.offerJob.getError("payroll_number")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Full name")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.offerJob.name,
                          expression: "offerJob.name",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "name" },
                      domProps: { value: _vm.offerJob.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.offerJob, "name", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.offerJob.hasError("name")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.offerJob.getError("name")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "dob" } },
                        [_vm._v("Date of Birth")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          value: _vm.offerJob.dob,
                          "max-date": false,
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.offerJob.dob = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.offerJob.hasError("dob")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.offerJob.getError("dob")),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "gender" } },
                      [_vm._v("Gender")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.offerJob.gender,
                          expression: "offerJob.gender",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "gender" },
                      domProps: { value: _vm.offerJob.gender },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.offerJob, "gender", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.offerJob.hasError("gender")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.offerJob.getError("gender")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "email" } },
                      [_vm._v("Email")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.offerJob.email,
                          expression: "offerJob.email",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "email", id: "email" },
                      domProps: { value: _vm.offerJob.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.offerJob, "email", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.offerJob.hasError("email")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.offerJob.getError("email")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "phone" } },
                      [_vm._v("Phone")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.offerJob.phone,
                          expression: "offerJob.phone",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "phone" },
                      domProps: { value: _vm.offerJob.phone },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.offerJob, "phone", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("phone")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("phone")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "phone" } },
                      [_vm._v("Nationality")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.offerJob.nationality,
                          expression: "offerJob.nationality",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "phone" },
                      domProps: { value: _vm.offerJob.nationality },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.offerJob,
                            "nationality",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("nationality")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.form.getError("nationality")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "phone" } },
                      [_vm._v("ID no")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.offerJob.id_no,
                          expression: "offerJob.id_no",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "id_no" },
                      domProps: { value: _vm.offerJob.id_no },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.offerJob, "id_no", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("id_no")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("id_no")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Residence")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.offerJob.residence,
                          expression: "offerJob.residence",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "residence" },
                      domProps: { value: _vm.offerJob.residence },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.offerJob,
                            "residence",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.offerJob.hasError("residence")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.offerJob.getError("residence")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Department")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "vacancy_id",
                          options: _vm.departments,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.offerJob.department_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.offerJob, "department_id", $$v)
                          },
                          expression: "offerJob.department_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.offerJob.hasError("department_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.offerJob.getError("department_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Employee Type")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "job_group_id",
                          options: _vm.employeetypes,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.offerJob.employee_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.offerJob, "employee_type", $$v)
                          },
                          expression: "offerJob.employee_type",
                        },
                      }),
                      _vm._v(" "),
                      _vm.offerJob.hasError("employee_type")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.offerJob.getError("employee_type")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Job Group")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "job_group_id",
                          options: _vm.jobgroups,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.offerJob.job_group_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.offerJob, "job_group_id", $$v)
                          },
                          expression: "offerJob.job_group_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.offerJob.hasError("job_group_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.offerJob.getError("job_group_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("label", { staticClass: "form-check form-switch" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.offerJob.activated,
                          expression: "offerJob.activated",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.offerJob.activated)
                          ? _vm._i(_vm.offerJob.activated, null) > -1
                          : _vm.offerJob.activated,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.offerJob.activated,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.offerJob,
                                  "activated",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.offerJob,
                                  "activated",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.offerJob, "activated", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "form-check-label" }, [
                      _vm._v("activate"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.processing
                ? _c("div", { staticClass: "progress mb-2" }, [
                    _c("div", { staticClass: "progress" }, [
                      _c("div", {
                        staticClass:
                          "progress-bar progress-bar-indeterminate bg-green",
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "application",
            title: "Application",
            "hide-header-close": "",
            size: "lg",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Close\n          ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("div", { staticClass: "car" }, [
            _c("div", { staticClass: "card-ody" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6 profile-info" }, [
                  _c("b", [_vm._v("Name")]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(_vm._s(_vm.selectedApplicant.name)),
                  ]),
                  _vm._v(" "),
                  _c("b", [_vm._v("Email:")]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.selectedApplicant.email))]),
                  _vm._v(" "),
                  _c("b", [_vm._v("Phone:")]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.selectedApplicant.phone))]),
                  _vm._v(" "),
                  _c("b", [_vm._v("Gender:")]),
                  _vm._v(" "),
                  _c("p", [
                    _c("span", { staticClass: "badge bg-primary" }, [
                      _vm._v(_vm._s(_vm.selectedApplicant.gender)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6 profile-info" }, [
                  _c("b", [_vm._v("Applied Pos")]),
                  _vm._v(" "),
                  _c("p", { attrs: { if: "selectedApplication.vacancy" } }, [
                    _vm._v(_vm._s(_vm.selectedApplication.vacancy.name)),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-icon-addon" }, [
      _c("i", { staticClass: "uil uil-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }