<script>
import {toPrice} from '@plugins/money'

export default {
    props: {
        deductions: {
            type: Array
        }, permissions: {
            type: Array,
            default: function () {
                return []
            },
        },
        employees: {
            type: Array,
        }
    },
    data() {
        return {
            months: [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ],
            fields: [
                {
                    name: 'employee.payroll_no',
                    title: 'Payroll No',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                },
                {
                    name: 'employee.name',
                    title: 'Employee',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                },
                {
                    name: 'deduction.name',
                    title: 'Deduction',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                },
                {
                    name: 'amount',
                    title: 'amount',
                    callback: function (value) {
                        if (value < 0) {
                            return '(' + toPrice(Math.abs((value * 0.01))).toFormat('0,0.00') + ')'
                        }
                        return toPrice((value * 0.01)).toFormat('0,0.00')
                    },
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                },
                {
                    name: 'month',
                    title: 'Month',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                },

                {
                    name: '__slot:is_deducted',
                    title: 'STATUS',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                },
                {
                    name: '__slot:actions',
                    width: '15%',
                    titleClass: 'text-center',
                    dataClass: 'text-right',
                },
            ],
            form: new window.Form({
                id: null,
                employee_payroll_id: null,
                deduction_id: null,
                amount: '',
                reason: '',
                date: '',
                month: ''
            }),
            moreParams: {
                filter: {
                    search: '',
                    // date: new Date().toISOString().slice(0, 10)
                }
            },

        }
    },

    computed: {
        vuetable() {
            return this.$refs.table.$refs.vuetable
        },
    },
    watch: {},
    mounted() {
        // this.retrieveEmployees()
    },

    methods: {
        retrieveEmployees() {
            this.$httpClient.get('/datatable/payroll-employees')
                .then(({data}) => {
                    this.employees = data.data.filter((employee) => employee.employment_status === 0 && employee);
                }).catch(() => {
                this.$toast.error('There was a problem retrieving the Employees')
            })
        },

        showDeduction() {
            this.$bvModal.show('payroll-deduction-modal')
        },
        // onSearch(searchText) {
        //     if (searchText.length > 2) {
        //         this.$httpClient.get(`/datatable/payroll-employees`, {
        //             params: { search: searchText }
        //         })
        //             .then(response => {
        //                 // Update the options with data from the server
        //                 this.options = response.data;
        //             })
        //             .catch(error => {
        //                 console.error("There was an error:", error);
        //             });
        //     }
        // },
        addEmployeeDeduction() {
            this.form.post('/payroll/deduction-inputs')
                .then(() => {
                    this.$bvModal.hide('payroll-deduction-modal');
                    this.$toast.success('One time Deduction has been added.');
                    this.vuetable.reload();
                })
                .catch((error) => {
                        if (error.response && error.response.data && error.response.data.errors) {
                            this.form.errors.record(error.response.data.errors);
                        } else {
                            this.$toast.error(error.response.data)
                        }
                    }
                )
        },

        showEditDeduction(rowData) {
            this.form.id = rowData.id
            this.month = rowData.month
            this.form.populate(rowData);
            this.$bvModal.show('payroll-deduction-edit-modal')
        },

        updateDeduction() {
            this.form.patch('/payroll/deduction-inputs/' + this.form.id)
                .then(() => {
                    // this.$bvModal.hide('payroll-deduction-edit-modal');
                    this.$toast.success('One time Deduction has been added.');
                    this.vuetable.reload();
                })
                .catch((error) => {
                        if (error.response && error.response.data && error.response.data.errors) {
                            this.form.errors.record(error.response.data.errors);
                        } else {
                            this.$toast.error(error.response.data)
                        }
                    }
                )
        }
    },
}
</script>
<template>
    <div>
        <div class="card card-stacked">
            <div class="card-header">
                <div class="input-icon me-2">
          <span class="input-icon-addon">
            <i class="uil uil-search"></i>
          </span>
                    <input type="text" class="form-control bg-muted-lt rounded-2" placeholder="Search leave"
                           v-model="moreParams.filter.search">
                </div>

                <div v-if="permissions.includes('create-payroll-adjustment')" class="card-actions">
                    <button @click="showDeduction" class="btn btn-primary">
                        <i class="uil uil-plus me-1"></i>Add Deduction
                    </button>
                </div>
            </div>
            <VueTable ref="table" api-url="/datatable/payroll-deductions" :fields="fields" :append-params="moreParams">
                <template v-slot:actions="props">
                    <div class="dropdown">
                        <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
                            <i class="uil uil-ellipsis-h"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <div v-if="!props.rowData.is_deducted && permissions.includes('delete-payroll-adjustment')">
                                <a v-if="!props.rowData.is_deducted" class="dropdown-item" href="#"
                                   @click="showEditDeduction(props.rowData)">
                                    <i class="uil uil-pen fs-3 me-2"></i> Edit
                                </a>
                            </div>

                            <a v-if="!props.rowData.is_deducted" class="dropdown-item text-danger" href="#"
                               @click="delRequest(props.rowData)">
                                <i class="uil  uil-trash-alt me-2"/> Delete
                            </a>
                        </div>
                    </div>
                </template>
                <template #is_deducted="props">
                    <span v-if="props.rowData.is_deducted" class="text-success">deducted</span>
                    <span class="text-warning" v-else>not deducted</span>

                </template>
            </VueTable>
        </div>

        <b-modal id="payroll-deduction-modal" title="Payroll Deductions" hide-header-close
                 footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
            <form @submit.prevent="addEmployeeDeduction" id="createForm">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="name" class="form-label">Employee</label>
                            <v-select id="employee_id" v-model="form.employee_payroll_id" name="employee_id"
                                      :options="employees"
                                      label="name" :reduce="option => option.id" :clearable="false">
                                <template v-slot:option="option">
                                    <li class="media mb-1">
                                        <div class="media-body">
                                            <div class="d-flex justify-content-between">
                                                <span>{{ option.name }}</span>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                                <template v-slot:selected-option="option">
                                    <li class="media mb-1">
                                        <div class="media-body">
                                            <div class="d-flex justify-content-between">
                                                <span>{{ option.name }}</span>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </v-select>
                            <span v-if="form.hasError('employee_payroll_id')" class="text-danger"
                                  v-text="form.getError('employee_payroll_id')">
              </span>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="name" class="form-label">Deduction</label>
                            <v-select id="gender_id" v-model="form.deduction_id" name="leave_type_id"
                                      :options="deductions"
                                      label="name" :reduce="option => option.id" :clearable="false"/>
                            <span v-if="form.hasError('deduction_id')" class="text-danger"
                                  v-text="form.getError('deduction_id')">
              </span>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="name" class="form-label">Reason for Deduction</label>
                            <textarea class="form-control" v-model="form.reason"></textarea>

                            <span v-if="form.hasError('reason')" class="text-danger" v-text="form.getError('reason')">
              </span>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="name" class="form-label">Amount</label>
                            <medic-money
                                :value="form.amount"
                                @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

                            <span v-if="form.hasError('amount')" class="text-danger" v-text="form.getError('amount')">
              </span>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="name" class="form-label">Date</label>
                            <date-picker name="date_of_birth" :value="form.date" :min-date="new Date()" @on-change="(dateOb, dateStr) => {
              form.date = dateStr
            }"/>

                            <span v-if="form.hasError('date')" class="text-danger" v-text="form.getError('date')">
              </span>
                        </div>
                    </div>
                </div>


                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                    </div>
                </div>

            </form>
            <template #modal-footer="{ cancel }">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <button type="button" class="btn btn-primary" :disabled="form.processing"
                        @click.prevent="addEmployeeDeduction">
                    Submit
                </button>

            </template>
        </b-modal>
        <b-modal id="payroll-deduction-edit-modal" title="Update Payroll Deductions" hide-header-close
                 footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
            <form @submit.prevent="updateDeduction" id="createForm">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="name" class="form-label">Employee</label>
                            <v-select id="employee_id" v-model="form.employee_payroll_id" name="employee_payroll_id"
                                      :options="employees"
                                      label="name" :reduce="option => option.id" :clearable="false">
                                <template v-slot:option="option">
                                    <li class="media mb-1">
                                        <div class="media-body">
                                            <div class="d-flex justify-content-between">
                                                <span>{{ option.name }}</span>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                                <template v-slot:selected-option="option">
                                    <li class="media mb-1">
                                        <div class="media-body">
                                            <div class="d-flex justify-content-between">
                                                <span>{{ option.name }}</span>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </v-select>
                            <span v-if="form.hasError('employee_payroll_id')" class="text-danger"
                                  v-text="form.getError('employee_payroll_id')">
              </span>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="name" class="form-label">Deduction</label>
                            <v-select id="gender_id" v-model="form.deduction_id" name="leave_type_id"
                                      :options="deductions"
                                      label="name" :reduce="option => option.id" :clearable="false"/>
                            <span v-if="form.hasError('deduction_id')" class="text-danger"
                                  v-text="form.getError('deduction_id')">
              </span>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="name" class="form-label">Reason for Deduction</label>
                            <textarea class="form-control" v-model="form.reason"></textarea>

                            <span v-if="form.hasError('reason')" class="text-danger" v-text="form.getError('reason')">
              </span>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="name" class="form-label">Amount</label>
                            <medic-money
                                :value="form.amount"
                                @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

                            <span v-if="form.hasError('amount')" class="text-danger" v-text="form.getError('amount')">
              </span>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="name" class="form-label">Date</label>
                            <v-select id="gender_id" v-model="form.month" name="leave_type_id" :options="months"
                                      label="name" :reduce="option => option" :clearable="false"/>
                            <span v-if="form.hasError('date')" class="text-danger" v-text="form.getError('date')">
              </span>
                        </div>
                    </div>
                </div>


                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                    </div>
                </div>

            </form>
            <template #modal-footer="{ cancel }">
                <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
                </button>
                <button type="button" class="btn btn-primary" :disabled="form.processing"
                        @click.prevent="updateDeduction">
                    Submit
                </button>

            </template>
        </b-modal>
    </div>

</template>
