<script>

export default {
    props: {
        employeetypes: {
            type: Array,
            default: () => [],
        },
        jobgroups: {
            type: Array,
            default: () => [],
        },
        departments: {
            type: Array,
            default: () => [],
        },
        employees: {
            type: Array,
            default: () => [],
        },

    },

    data() {
        return {
            PAYEOptions: [
                {name: 'Not Applicable', value: 0},
                {name: 'Graduated Tax', value: 1},
                {name: '30% of Gross Salary', value: 2},
                {name: '5% with-holding', value: 3},
            ],
            genderList:[
                'MALE',
                'FEMALE'
            ],
            is_in_contract: false,
            form: new window.Form({
                id: null,
                name: '',
                dob: '',
                gender: '',
                identification_number: '',
                physical_address: '',
                job_title: '',
                is_disabled: '',
                disability: '',
                telephone: '',
                staff_number: '',
                employee_type_id: null,
                contract_exp: null,
                next_of_kin: '',
                supervisor: '',
                email: '',
                kra_pin: '',
                shif_no: '',
                nssf_no: '',
                payee_type: 0,
                job_group_id: null,
                pays_tax: false,
                pays_nssf: false,
                pays_shif: false,
                pays_housing_levy: false,
                department_id: null,
                activated: true
            }),
        }
    },
    computed: {
        filterEmployee(){
            if(!this.form.department_id){
                return this.employees;
            }
            return this.employees.filter((employee)=>employee.department_id === this.form.department_id)
        }
    },
    watch: {
        'form.employee_type_id': function (value) {
            let employeeType = this.employeetypes.find((type) => type.id === value)
            if (employeeType.name !== 'Permanent') {
                this.is_in_contract = true
                this.form.is_contract = true
            } else {
                this.is_in_contract = false
                this.form.is_contract = false
            }
        },
    },

    methods: {
        registerEmployee() {
            this.form.post('/payroll/create')
                .then(() => {
                    this.$toast.success('Employee Registered successfully')

                    setTimeout(() => {
                        window.location.assign('/payroll/master')
                    }, 2000)
                })
                .catch()
        },

        goBack() {
            window.location.assign('/payroll/master')
        },
    },
}
</script>

<template>
    <div>
        <div class="row">
            <div class="col-xl-8 mx-auto p-4">
                <div class="text-center mb-4">
                    <h1 class="fs-1">
                        Employee Payroll Registration
                    </h1>
                    <span class="text-muted">Complete the form below to register a new employee to payroll</span>
                </div>
                <div class="card card-stacked">
                    <form class="card-body py-4" @submit.prevent="registerEmployee">
                        <div class="row g-4">
                            <div class="col-lg-6 form-group mb-4">
                                <label for="" class="form-label">Payroll Number</label>
                                <input id="name" v-model="form.staff_number" type="text" class="form-control">
                                <div v-if="form.hasError('staff_number')" class="text-danger"
                                     v-text="form.getError('staff_number')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label for="" class="form-label">Full name</label>
                                <input id="name" v-model="form.name" type="text" class="form-control">
                                <div v-if="form.hasError('name')" class="text-danger" v-text="form.getError('name')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label for="" class="form-label">Gender</label>
                                <v-select id="scheme_id" v-model="form.gender" label="name"
                                          :options="genderList"
                                          :reduce="(option) => option" :clearable="false"/>
                                <span v-if="form.hasError('gender')" class="text-danger"
                                      v-text="form.getError('gender')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label">Date of Birth</label>
                                <div>
                                    <date-picker name="date_of_birth" formclass="form-control bg-white"
                                                 :value="form.dob"
                                                 :max-date="new Date()" @on-change="(dateOb, dateStr) => {
            form.dob = dateStr
          }"/>

                                    <span v-if="form.hasError('dob')" class="text-danger"
                                          v-text="form.getError('dob')"/>
                                </div>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Identification number</label>
                                <input id="name" v-model="form.identification_number" type="text" class="form-control">
                                <span v-if="form.hasError('identification_number')" class="text-danger"
                                      v-text="form.getError('identification_number')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Mobile number</label>
                                <input id="name" v-model="form.telephone" type="text" class="form-control">
                                <span v-if="form.hasError('identification_number')" class="text-danger"
                                      v-text="form.getError('identification_number')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Email address</label>
                                <input id="name" v-model="form.email" type="text" class="form-control">
                                <span v-if="form.hasError('email')" class="text-danger"
                                      v-text="form.getError('email')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Physical address</label>
                                <input id="name" v-model="form.physical_address" type="text" class="form-control">
                                <span v-if="form.hasError('physical_address')" class="text-danger"
                                      v-text="form.getError('physical_address')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Job Title</label>
                                <input id="name" v-model="form.job_title" type="text" class="form-control">
                                <span v-if="form.hasError('job_title')" class="text-danger"
                                      v-text="form.getError('job_title')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Department</label>
                                <v-select id="scheme_id" v-model="form.department_id" label="name"
                                          :options="departments"
                                          :reduce="(option) => option.id" :clearable="false"/>
                                <span v-if="form.hasError('department_id')" class="text-danger"
                                      v-text="form.getError('department_id')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Report to</label>
                                <v-select id="scheme_id" v-model="form.supervisor" label="name" :options="filterEmployee"
                                          :reduce="(option) => option.id" :clearable="false">
                                    <template v-slot:option="option">
                                        <li class="media mb-1">
                                            <div class="media-body">
                                                <div class="d-flex justify-content-between">
                                                    <span>{{ option.name }}</span> <br>
                                                    <small class="text-warning">{{ option.department.name }}</small>
                                                </div>
                                            </div>
                                        </li>
                                    </template>
                                    <template v-slot:selected-option="option">
                                        <li class="media mb-1">
                                            <div class="media-body">
                                                <div class="d-flex justify-content-between">
                                                    <span>{{ option.name }}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </template>
                                </v-select>
                                <span v-if="form.hasError('supervisor')" class="text-danger"
                                      v-text="form.getError('supervisor')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Employee Type</label>
                                <v-select id="scheme_id" v-model="form.employee_type_id" label="name"
                                          :options="employeetypes"
                                          :reduce="(option) => option.id" :clearable="false"/>
                                <span v-if="form.hasError('employee_type_id')" class="text-danger"
                                      v-text="form.getError('employee_type_id')"/>
                            </div>
                            <div v-if="is_in_contract" class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Employment Expiry Date</label>
                                <date-picker name="date_of_birth" formclass="form-control bg-white"
                                             :value="form.contract_exp"
                                             :min-date="new Date()" @on-change="(dateOb, dateStr) => {
            form.contract_exp = dateStr
          }"/>
                                <span v-if="form.hasError('contract_exp')" class="text-danger"
                                      v-text="form.getError('contract_exp')"/>
                            </div>
                            <div class="col-lg-6 form-group mb-4">
                                <label class="form-label" for="plan_type">Job Group</label>
                                <v-select id="scheme_id" v-model="form.job_group_id" label="name" :options="jobgroups"
                                          :reduce="(option) => option.id" :clearable="false"/>
                                <span v-if="form.hasError('job_group_id')" class="text-danger"
                                      v-text="form.getError('job_group_id')"/>
                            </div>
                            <div class="col-lg-8 mx-auto mt-4">
                                <div class="form-group mb-3">
                                    <div class="mb-2">
                                        <label class="form-check form-switch">
                                            <input v-model="form.pays_tax" class="form-check-input" type="checkbox">
                                            <span class="form-check-label">Pays tax</span>
                                        </label>
                                    </div>
                                </div>

                                <div v-if="form.pays_tax" class="form-group mb-3">
                                    <label class="form-label" for="paye_type">Tax Setup</label>
                                    <v-select id="paye_type" v-model="form.payee_type" :options="PAYEOptions"
                                              label="name"
                                              :clearable="false" :reduce="(option) => option.value">
                                    </v-select>

                                    <span v-if="form.errors.has('payee_type')" class="text-danger"
                                          v-text="form.errors.get('payee_type')">
                  </span>
                                </div>

                                <div v-if="form.pays_tax" class=" form-group mb-3">
                                    <label for="kra_pin" class="form-label">Kra Pin</label>
                                    <input id="name" v-model="form.kra_pin" type="text" class="form-control">

                                    <span v-if="form.hasError('kra_pin')" class="text-danger"
                                          v-text="form.getError('kra_pin')"/>
                                </div>
                                <div class="form-group mb-3">
                                    <div class="mb-2">
                                        <label class="form-check form-switch">
                                            <input v-model="form.pays_shif" class="form-check-input" type="checkbox">
                                            <span class="form-check-label">Pays SHIF</span>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="form.pays_shif" class="form-group mb-4">
                                    <label for="kra_pin" class="form-label">SHIF NUMBER</label>
                                    <input id="name" v-model="form.shif_no" type="text" class="form-control">

                                    <span v-if="form.hasError('shif_no')" class="text-danger"
                                          v-text="form.getError('shif_no')"/>
                                </div>
                                <div class="form-group mb-3">
                                    <div class="mb-2">
                                        <label class="form-check form-switch">
                                            <input v-model="form.pays_nssf" class="form-check-input" type="checkbox">
                                            <span class="form-check-label">Pays NSSF</span>
                                        </label>
                                    </div>
                                </div>

                                <div v-if="form.pays_nssf" class=" form-group mb-4">
                                    <label for="kra_pin" class="form-label">NSSF NUMBER</label>
                                    <input id="name" v-model="form.nssf_no" type="text" class="form-control">

                                    <span v-if="form.hasError('nssf_no')" class="text-danger"
                                          v-text="form.getError('nssf_no')"/>
                                </div>
                                <div class="form-group mb-3">
                                    <div class="mb-2">
                                        <label class="form-check form-switch">
                                            <input v-model="form.pays_housing_levy" class="form-check-input" type="checkbox">
                                            <span class="form-check-label">Pays House Levy</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4">
                                <button class="btn btn-primary me-3" type="submit">
                                    Submit
                                </button>

                                <button class="btn" :disabled="form.processing || form.successful"
                                        @click.prevent="goBack">
                                    Cancel
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
