var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card card-stacked" },
        [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "input-icon me-2" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.moreParams.filter.search,
                    expression: "moreParams.filter.search",
                  },
                ],
                staticClass: "form-control bg-muted-lt rounded-2",
                attrs: { type: "text", placeholder: "Search Employees" },
                domProps: { value: _vm.moreParams.filter.search },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.moreParams.filter,
                        "search",
                        $event.target.value
                      )
                    },
                    _vm.searchEmployees,
                  ],
                },
              }),
            ]),
            _vm._v(" "),
            _vm.permissions.includes("create-payroll")
              ? _c("div", { staticClass: "card-actions" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary me-3",
                      attrs: { href: "/payroll/create" },
                    },
                    [_vm._v("New Registration")]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("VueTable", {
            ref: "table",
            staticClass: "table-hover",
            attrs: {
              "api-url": "/datatable/payroll-employees",
              fields: _vm.fields,
              "append-params": _vm.moreParams,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "name",
                  fn: function (props) {
                    return [
                      _c(
                        "a",
                        {
                          staticClass: "text-reset",
                          attrs: { href: "/employees/" + props.rowData.id },
                        },
                        [_vm._v(_vm._s(props.rowData.name))]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      props.rowData.employment_status === 1
                        ? _c("small", { staticClass: "text-warning" }, [
                            _vm._v("Suspended"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      props.rowData.employment_status === 2
                        ? _c("small", { staticClass: "text-danger" }, [
                            _vm._v("Terminated"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "telephone",
                  fn: function (props) {
                    return [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("div", { staticClass: "font-weight-medium" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  props.rowData.type
                                    ? props.rowData.type.name
                                    : "is not set"
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          props.rowData.type
                            ? _c("div", { staticClass: "text-muted" }, [
                                props.rowData.type.name !== "Permanent"
                                  ? _c("div", [
                                      _vm.isOneWeekAway(props.rowData)
                                        ? _c(
                                            "small",
                                            { staticClass: "text-warning" },
                                            [
                                              _vm._v(
                                                "ends on " +
                                                  _vm._s(
                                                    " " +
                                                      _vm.formatDate(
                                                        props.rowData
                                                          .contract_exp
                                                      )
                                                  )
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "small",
                                            { staticClass: "text-info" },
                                            [
                                              _vm._v(
                                                "ends on " +
                                                  _vm._s(
                                                    " " +
                                                      _vm.formatDate(
                                                        props.rowData
                                                          .contract_exp
                                                      )
                                                  )
                                              ),
                                            ]
                                          ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  },
                },
                {
                  key: "dob",
                  fn: function (props) {
                    return [
                      _c("div", { staticClass: "row" }, [
                        props.rowData.date_of_birth
                          ? _c("div", { staticClass: "col" }, [
                              _c("div", { staticClass: "font-weight-medium" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("date_DATE_MONTH_YEAR")(
                                        props.rowData.date_of_birth
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("small", { staticClass: "text-indigo" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(props.rowData.age) +
                                    "\n            "
                                ),
                              ]),
                            ])
                          : _c("div", { staticClass: "col" }, [
                              _vm._v("\n            N/A\n          "),
                            ]),
                      ]),
                    ]
                  },
                },
                _vm.permissions.includes("access-emp-payroll-profile")
                  ? {
                      key: "actions",
                      fn: function (props) {
                        return [
                          _c(
                            "a",
                            {
                              staticClass: "btn",
                              attrs: {
                                href: "/payroll/master/" + props.rowData.hashid,
                              },
                            },
                            [_vm._v("View")]
                          ),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "registration-modal",
            title: "Employee Registration",
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            "no-enforce-focus": "",
            lazy: "",
            scrollable: "",
            "body-class": "px-4",
            "footer-class": "d-flex justify-content-start border-top",
            "modal-class": "modal-blur",
            size: "lg",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n        Close\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "submit",
                        disabled: _vm.form.processing,
                        form: "form",
                      },
                    },
                    [_vm._v("\n        Register Employee\n      ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "form",
            {
              attrs: { id: "form" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.registerEmployee.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-group mb-4" }, [
                _c(
                  "label",
                  { staticClass: "form-label", attrs: { for: "name" } },
                  [_vm._v("Full Name")]
                ),
                _vm._v(" "),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.name,
                        expression: "form.name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "name", type: "text" },
                    domProps: { value: _vm.form.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "name", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("name")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("name")),
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xl-6" }, [
                  _c("div", { staticClass: "form-group mb-4" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Date of Birth"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("date-picker", {
                          attrs: {
                            name: "date_of_birth",
                            formclass: "form-control bg-white",
                            value: _vm.form.date_of_birth,
                            "max-date": new Date(),
                          },
                          on: {
                            "on-change": (dateOb, dateStr) => {
                              _vm.form.date_of_birth = dateStr
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.form.hasError("date_of_birth")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.form.getError("date_of_birth")
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-xl-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-4" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Gender "),
                      ]),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "gender_id",
                          options: _vm.genders,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.form.gender_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "gender_id", $$v)
                          },
                          expression: "form.gender_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("gender_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("gender_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xl-6" }, [
                  _c("div", { staticClass: "form-group mb-4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "identification_value" },
                      },
                      [_vm._v("Identification Number")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.identification_value,
                            expression: "form.identification_value",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "identification_value", type: "text" },
                        domProps: { value: _vm.form.identification_value },
                        on: {
                          blur: function ($event) {
                            return _vm.checkIdentificationStatus(
                              $event.target.value
                            )
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "identification_value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("identification_value")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("identification_value")
                              ),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-xl-6" }, [
                  _c("div", { staticClass: "form-group mb-4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "telephone" },
                      },
                      [_vm._v("Mobile ")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.telephone,
                          expression: "form.telephone",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "telephone", type: "tel" },
                      domProps: { value: _vm.form.telephone },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "telephone", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("telephone")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("telephone")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group mb-4" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-label",
                    attrs: { for: "physical_address" },
                  },
                  [_vm._v("Physical Address ")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.physical_address,
                      expression: "form.physical_address",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "physical_address", type: "text" },
                  domProps: { value: _vm.form.physical_address },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.form,
                        "physical_address",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _vm.form.hasError("physical_address")
                  ? _c("span", {
                      staticClass: "text-danger",
                      domProps: {
                        textContent: _vm._s(
                          _vm.form.getError("physical_address")
                        ),
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xl-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-4" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Department "),
                      ]),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "gender_id",
                          options: _vm.departments,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.form.department_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "department_id", $$v)
                          },
                          expression: "form.department_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("department_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("department_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-xl-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-4" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("Nationality"),
                      ]),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "gender_id",
                          options: _vm.genders,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        model: {
                          value: _vm.form.nationality,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "nationality", $$v)
                          },
                          expression: "form.nationality",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("nationality")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("nationality")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3" }, [
                  _c("div", { staticClass: "d-flex gap-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.pays_tax,
                          expression: "form.pays_tax",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: { id: "pays_tax", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.form.pays_tax)
                          ? _vm._i(_vm.form.pays_tax, null) > -1
                          : _vm.form.pays_tax,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.form.pays_tax,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.form,
                                  "pays_tax",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.form,
                                  "pays_tax",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.form, "pays_tax", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "pays_tax" } },
                      [_vm._v("Tax payer")]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.form.hasError("pays_tax")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("pays_tax")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3" }, [
                  _c("div", { staticClass: "d-flex gap-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.pays_nhif,
                          expression: "form.pays_nhif",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: { id: "pays_nhif", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.form.pays_nhif)
                          ? _vm._i(_vm.form.pays_nhif, null) > -1
                          : _vm.form.pays_nhif,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.form.pays_nhif,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.form,
                                  "pays_nhif",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.form,
                                  "pays_nhif",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.form, "pays_nhif", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "pays_nhif" },
                      },
                      [_vm._v("NHIF payer")]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.form.hasError("pays_nhif")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("pays_nhif")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3" }, [
                  _c("div", { staticClass: "d-flex gap-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.pays_nssf,
                          expression: "form.pays_nssf",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: { id: "pays_nssf", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.form.pays_nssf)
                          ? _vm._i(_vm.form.pays_nssf, null) > -1
                          : _vm.form.pays_nssf,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.form.pays_nssf,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.form,
                                  "pays_nssf",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.form,
                                  "pays_nssf",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.form, "pays_nssf", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "pays_nssf" },
                      },
                      [_vm._v("NSSF payer")]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.form.hasError("pays_nssf")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("pays_nssf")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-xl-6" }, [
                  _c("div", { staticClass: "form-group mb-4" }, [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Kra Pin"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.kra_pin,
                          expression: "form.kra_pin",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "physical_address", type: "text" },
                      domProps: { value: _vm.form.kra_pin },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "kra_pin", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("kra_pin")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("kra_pin")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.processing
                ? _c("div", { staticClass: "progress mb-2" }, [
                    _c("div", { staticClass: "progress" }, [
                      _c("div", {
                        staticClass:
                          "progress-bar progress-bar-indeterminate bg-green",
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-icon-addon" }, [
      _c("i", { staticClass: "uil uil-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }