<script>
import { defineComponent } from 'vue'
import { toPrice } from '@plugins/money'

export default defineComponent({
  name: 'Incomes',
  props: {
    incomes: {
      type: Array,
      default: () => [],
    },
    permissions: {
      type: Array,
      default: () => [],
    },
    employee: {
      type: Object,
      default: () => {},
    },
  },

  data () {
    return {

      fields: [
        {
          name: 'employee.name',
          title: 'Name',
          titleClass: ' text-start pe-3',
          dataClass: ' text-start pe-3',
          width: '8%',
        },
        {
          name: 'income.name',
          title: 'Income / Earning',
          titleClass: ' text-start pe-3',
          dataClass: ' text-start pe-3',
          width: '8%',
        },
        {
          name: 'amount',
          title: 'Amount',
          callback: function (value) {
            if (value < 0) {
              return '(' + toPrice(Math.abs((value * 0.01))).toFormat('0,0.00') + ')'
            }
            return toPrice((value * 0.01)).toFormat('0,0.00')
          },
          titleClass: 'text-end pe-3',
          dataClass: 'text-end pe-3',
          width: '10%',
        },
        {
          name: '__slot:actions',
          dataClass: 'txt-center',
          width: '5%',
        },
      ],


      form: new window.Form({
        id: '',
        income_id: null,
        employee_payroll_id: this.employee.id,
        amount: null,
      }),

      reverseForm: new window.Form({
        comments: '',
        detail_id: '',
        source: '1',
      }),

      employeeIncomes: [],
    }
  },

  computed: {
    vuetable() {
      return this.$refs.tablee.$refs.vuetable
    },
  },

  watch: {
  },
mounted() {
    this.retrieveEmployeeIncomes()
},
  methods: {
    retrieveEmployeeIncomes () {
      this.$httpClient.get('/payroll/incomes/' + this.employee.hashid)
          .then(({ data }) => {
            this.employeeIncomes = data;
          }).catch(() => {
        this.$toast.error('There was a problem retrieving the employee incomes')
      })
    },
    openIncomeModal () {
      this.$bvModal.show('income-modal')

    },


    addIncome () {
      this.form.post('/payroll/incomes')
          .then(() => {
            this.$toast.success('The income has been added')
              this.$bvModal.hide('income-modal')
            this.retrieveEmployeeIncomes()
            this.vuetable.reload()
          })
          .catch((error) => {
            this.form.processing = false
            this.form.errors.record(error.response.data.errors)
          })
    },
    editIncome(data){
      this.form.populate(data)
        this.form.amount = (data.amount * 0.01)
      this.$bvModal.show('edit-income-modal')
    },
    updateIncome(){
      this.form.patch('/payroll/incomes/' + this.form.id)
          .then(() => {
            this.$toast.success('The changes have been saved')

            this.retrieveEmployeeIncomes()
            this.$bvModal.hide('edit-income-modal')
            this.vuetable.reload()
          })
          .catch()
    },
    delIncome (rowData){
      this.form.id = rowData.id;
      this.$toast.question('Are you sure?', 'You want to delete').then(() => {
        this.$httpClient.delete('/payroll/incomes/' + this.form.id )
        .then((error) => {
          this.$toast.success('deleted')
          this.retrieveEmployeeIncomes()
          this.vuetable.reload()
        }).catch((error) => {
          this.$toast.error(error.response.data)
        })


      })
    }
  },

})
</script>

<template>

  <div>

    <b-modal
        id="income-modal"
        body-class="px-4"
        content-class="bg-white rounded-3"
        footer-class="d-flex justify-content-between bg-muted-lt border-top"
        hide-header-close
        modal-class="modal-blur"
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        title="Income / Earning Form"
        @hidden="cleanUp"
    >
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group mb-4">
            <label class="form-label" for="item_id">Income / Earning</label>
            <v-select
                id="item_id"
                v-model="form.income_id"
                :clearable="false"
                :reduce="(option) => option.id"
                :options="incomes"
                label="name"
            >
            </v-select>
            <span
                v-if="form.errors.has('income_id')"
                class="text-danger"
                v-text="form.errors.first('income_id')"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group mb-4">

              <label for="" class="form-label">Amount</label>
              <medic-money
                  :value="form.amount"
                  @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

              <div class="text-danger"
                   v-if="form.hasError('amount')"
                   v-text="form.getError('amount')">
              </div>

          </div>
        </div>
      </div>



      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green"/>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button
            :disabled="form.processing" class="btn btn-primary"
            type="submit"
            @click="addIncome"
        >
          Submit
        </button>
      </template>
    </b-modal>
    <b-modal
        id="edit-income-modal"
        body-class="px-4"
        content-class="bg-white rounded-3"
        footer-class="d-flex justify-content-between bg-muted-lt border-top"
        hide-header-close
        modal-class="modal-blur"
        size="lg"
        no-enforce-focus
        title="Edit Income / Earning Form"
        @hidden="cleanUp"
    >
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group mb-4">
            <label class="form-label" for="item_id">Income / Earning</label>
            <v-select
                id="item_id"
                v-model="form.income_id"
                :clearable="false"
                :reduce="(option) => option.id"
                :options="incomes"
                label="name"
            >
            </v-select>
            <span
                v-if="form.errors.has('income_id')"
                class="text-danger"
                v-text="form.errors.first('income_id')"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group mb-4">

              <label for="" class="form-label">Amount</label>
              <medic-money
                  :value="form.amount"
                  @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

              <div class="text-danger"
                   v-if="form.hasError('amount')"
                   v-text="form.getError('amount')">
              </div>

          </div>
        </div>
      </div>



      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green"/>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button
            :disabled="form.processing" class="btn btn-primary"
            type="submit"
            @click="updateIncome"
        >
          Submit
        </button>
      </template>
    </b-modal>


    <div class="card rounded-3 mb-5 ">
      <div class="card-header">
        <div class="">
          <h2 class="card-title mb-0 fw-bold">
            Incomes
          </h2>
        </div>

        <div class="card-actions">
          <div class="btn-list">
            <button class="btn btn-primary me-4" @click.prevent="openIncomeModal">
              <i class="uil uil-plus me-1"/> Income
            </button>
          </div>
        </div>
      </div>

      <VueTable
          ref="table"
          :api-mode="false"
          :data="employeeIncomes"
          :fields="fields"
          :load-on-start="false"
          :show-pagination="false"
          :show-per-page="false"
      >
        <template #date="props">
          <div class="flex-fill">
            <div class="ps-3">
              {{ props.rowData.date | date_DATE_MONTH_YEAR }}
            </div>
            <div class="text-indigo small ps-3">
              {{ props.rowData.date | tIME_HOUR_MINUTES }}
            </div>
          </div>
        </template>



        <template #actions="props">
          <div class="dropdown">
            <button
                class="btn align-text-center py-1"
                data-bs-toggle="dropdown"
            >
              <i class="uil uil-ellipsis-h"/>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <div class="dropdown-divider"/>
              <a class="dropdown-item" href="#" @click="editIncome(props.rowData)">
                <i class="uil uil-pen fs-3 me-2"/> Edit
              </a>
              <a class="dropdown-item text-danger" href="#" @click="delIncome(props.rowData)">
                <i class="uil  uil-trash-alt me-2"/> Delete
              </a>
            </div>
          </div>
        </template>
      </VueTable>
    </div>

  </div>
</template>

<style scoped>

</style>
