import Vue from 'vue'

import './bootstrap'

//
// import store from '@state/store'
//
// import MedicPermission from '@plugins/permission'
//
// Vue.use(MedicPermission)
//
// Vue.config.productionTip = process.env.NODE_ENV === 'production'
Vue.config.productionTip = process.env.NODE_ENV === 'development'

Vue.component('LoginForm', require('@components/pages/auth/Login.vue').default)
Vue.component('RegisterForm', require('@components/pages/auth/register.vue').default)
Vue.component('EmployeeManagement', require('@components/pages/employee/EmployeeMaster.vue').default)
Vue.component('EmployeeRegistration', require('@components/pages/employee/Create.vue').default)
Vue.component('EmployeeProfile', require('@components/pages/employee/EmployeeProfile.vue').default)
Vue.component('HomeEmployeeProfile', require('@components/pages/home/Index.vue').default)
Vue.component('Deduction', require('@components/pages/configuration/deduction/Index.vue').default)
Vue.component('Departments', require('@components/pages/configuration/department/Index.vue').default)
Vue.component('Branches', require('@components/pages/configuration/branches/Index.vue').default)
Vue.component('employee-types', require('@components/pages/configuration/employeeTypes/Index.vue').default)
Vue.component('job-groups', require('@components/pages/configuration/jobGroups/Index.vue').default)
Vue.component('Allowance', require('@components/pages/configuration/allowance/Index.vue').default)
Vue.component('Kpis', require('@components/pages/configuration/kpis/Index.vue').default)
Vue.component('Contributions', require('@components/pages/configuration/contributions/Index.vue').default)
Vue.component('Benefits', require('@components/pages/configuration/benefits/Index.vue').default)
Vue.component('Pensions', require('@components/pages/configuration/pension/Index.vue').default)
Vue.component('Vendors', require('@components/pages/configuration/vendor/Index.vue').default)
Vue.component('Tax', require('@components/pages/configuration/Tax/Index.vue').default)
Vue.component('LeaveTypes', require('@components/pages/configuration/leaveTypes/Index.vue').default)
Vue.component('Incomes', require('@components/pages/configuration/income/Index.vue').default)
Vue.component('Relief', require('@components/pages/configuration/relief/Index.vue').default)
Vue.component('EmployeeContributions', require('@components/pages/employeeContributions/Index.vue').default)
Vue.component('EmployeeLeave', require('@components/pages/employee/leave/Index.vue').default)
Vue.component('EmployeeLeaveSupervisor', require('@components/pages/employee/leave/supervisor/Index.vue').default)
Vue.component('MyLeave', require('@components/pages/leave/Index.vue').default)
Vue.component('EmployeeLeaveType', require('@components/pages/employee_leave_type/Index.vue').default)
Vue.component('Dashboard', require('@components/pages/dashboard/Index').default)
Vue.component('SystemUsers', require('@components/pages/configuration/user/Index').default)
Vue.component('Payroll', require('@components/pages/payroll/Index.vue').default)
Vue.component('PayrollDetails', require('@components/pages/payroll/Payroll.vue').default)
Vue.component('HousingLevyConfig', require('@components/pages/configuration/housingLevy/Index.vue').default)

Vue.component('Recruitment', require('@components/pages/recruitment/Index.vue').default)
Vue.component('Performance', require('@components/pages/performance/Index.vue').default)
Vue.component('Resignations', require('@components/pages/employee/resignations/Index.vue').default)

Vue.component('SupervisorPerformance', require('@components/pages/performance/supervisor/Index.vue').default)
Vue.component('MyReview', require('@components/pages/performance/myReview/Index.vue').default)
Vue.component('DisciplinaryCases', require('@components/pages/disciplinary_cases/Index.vue').default)
Vue.component('Promotions', require('@components/pages/promotions/Index.vue').default)
Vue.component('Demotions', require('@components/pages/demotions/Index.vue').default)
Vue.component('Transfers', require('@components/pages/transfers/Index.vue').default)
Vue.component('Organization', require('@components/pages/organization/Index.vue').default)
Vue.component('Chart', require('@components/pages/organization/chart.vue').default)

// reports
Vue.component('EmployeeListing', require('@components/pages/reports/employee/EmployeeListing.vue').default)
Vue.component('PerformanceAppraisal', require('@components/pages/reports/employee/PerformanceAppraisal.vue').default)
Vue.component('FormP10', require('@components/pages/reports/P10Form/10PForm.vue').default)
Vue.component('EmployeeLeaveBalance', require('@components/pages/reports/leave/EmployeeLeaveBalance.vue').default)
Vue.component('Paye', require('@components/pages/reports/paye/Paye.vue').default)
Vue.component('HousingLevy', require('@components/pages/reports/payroll/HousingLevy.vue').default)
Vue.component('Deductions', require('@components/pages/reports/payroll/deductions/Deductions.vue').default)
Vue.component('Nssf', require('@components/pages/reports/nssf/Nssf.vue').default)
Vue.component('Nhif', require('@components/pages/reports/nhif/Nhif.vue').default)
Vue.component('Shif', require('@components/pages/reports/shif/Shif.vue').default)

// Payroll
Vue.component('PayrollMaster', require('@components/pages/payroll/PayrollMaster.vue').default)
Vue.component('PayrollCreate', require('@components/pages/payroll/Create.vue').default)
Vue.component('PayrollInput', require('@components/pages/payroll/inputs.vue').default)
Vue.component('EmployeePayrollProfile', require('@components/pages/payroll/EmployeePayrollProfile.vue').default)
// payroll reports
Vue.component('GlobalPayrollListing', require('@components/pages/reports/payroll/GlobalPayrollListing.vue').default)
// Vue.component('GlobalPayrollListing', require('@components/pages/reports/admission/AdmissionByDoctor.vue').default)
// //PROFILE
Vue.component('UserProfile', require('@components/pages/profile/Index').default)
// //



const app = new Vue({
  el: '#app',
})
