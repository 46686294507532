<script>

export default {
  props: {
    leave_types: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      selectedLeaveType:{},
      employees:[],
      fields: [
        {
          name: 'employee.name',
          title: 'Employee',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'leave_type.name',
          title: 'Leave Type',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'days',
          title: 'days',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'year',
          title: 'Year',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: '__slot:actions',
          width: '15%',
          titleClass: 'text-center',
          dataClass: 'text-right',
        },
      ],

      moreParams: {
        filter: {
          search: ''
        }
      },
      previousDays : null,
      hasBalance:false,
      form: new window.Form({
        id: null,
        employee_id: '',
        leave_type_id:'',
        start_date:'',
        to_all: false,
        days: 0,
        year: new Date().getFullYear(),
        end_date:'',
        approved:true
      }),
    }
  },

  computed: {
    vuetable() {
      return this.$refs.table.$refs.vuetable
    },
  },
  mounted() {
    this.retrieveEmployees();
  },
  watch:{
    'form.leave_type_id':function (id){
            this.selectedLeaveType = this.leave_types.find((leave)=>leave.id === id);
            this.form.days = this.selectedLeaveType.days;
            if(!this.form.to_all){
                this.checkPreviousLeaveBalance()
            }

    }
  },
  methods: {
    checkPreviousLeaveBalance () {
      this.$httpClient.get('/employee/previous-leave-balance/', {
        params:{
          employee_id: this.form.employee_id,
          leave_type_id: this.form.leave_type_id,
          year:(this.form.year - 1)
        }
      })
          .then(({ data }) => {
            this.hasBalance = true;
            this.previousDays = data.balance
            this.form.days += data.balance
          }).catch(() => {

            this.hasBalance = false;
      })
    },
    retrieveEmployees () {
      this.$httpClient.get('/datatable/employees/')
          .then(({ data }) => {
            this.employees = data.data.filter((employee)=>employee.employment_status === 0 && employee);
          }).catch(() => {
        this.$toast.error('There was a problem retrieving the Employees')
      })
    },
    showCreateForm() {
      this.$bvModal.show('create-modal')
    },

    showUpdateForm(rowData) {
      this.selectedDepartment = rowData
      this.viewEditForm = true
    },

    createAssignLeave() {
      this.form.post('/employee/leave-assign')
          .then(() => {
            this.$bvModal.hide('create-modal')
            this.$toast.success('The leave has been assigned')
            this.vuetable.reload()
          })
          .catch( (error) => {
                  if (error.response && error.response.data && error.response.data.errors) {
                      this.form.errors.record(error.response.data.errors);
                  } else {
                      this.$toast.error(error.response.data)
                  }
              }

          )
    },

    showEditForm(rowData) {
      this.form.populate(rowData)
      this.form.id = rowData.id

      this.$bvModal.show('update-modal')
    },

    editLeave() {
      this.form.patch('/employee/leave-assign/' + this.form.id)
          .then(() => {
            this.vuetable.reload()
            this.$bvModal.hide('update-modal')
            this.$toast.success('The changes have been saved')
          })
          .catch()
    },

    applyFilter() {
      this.vuetable.reload()
    },

    cleanUp() {
      this.form.reset()
    }
  },
}
</script>

<template>
  <div>
    <div class="card card-stacked">
      <div class="card-header">
        <div class="input-icon me-2">
                     <span class="input-icon-addon">
                        <i class="uil uil-search"></i>
                    </span>
          <input type="text" class="form-control bg-muted-lt rounded-2"
                 placeholder="Search leave"
                 @input="applyFilter"
                 v-model="moreParams.filter.name">
        </div>

        <div class="card-actions">
          <button class="btn btn-primary" @click="showCreateForm">
            <i class="uil uil-plus me-1"></i>Assign Leave
          </button>
        </div>
      </div>
      <VueTable
          ref="table"
          api-url="/datatable/employee-leave-types"
          :fields="fields"
          :append-params="moreParams">
        <template v-slot:actions="props">
          <div class="dropdown">
            <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
              <i class="uil uil-ellipsis-h"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <a class="dropdown-item" href="#" @click="showEditForm(props.rowData)">
                <i class="uil uil-pen fs-3 me-2"></i> Edit
              </a>

            </div>
          </div>
        </template>
      </VueTable>
    </div>

    <b-modal
        id="create-modal"
        title="Assign Leave"
        hide-header-close
        size="lg"
        footer-class="d-flex justify-content-start"
        modal-class="modal-blur"
        no-close-on-esc
    >
      <form @submit.prevent="createAssignLeave" id="createForm">
        <div class="row">
          <div class="col-12">
              <div class="mb-3">
                  <label for="name" class="form-label">All employees</label>
                  <input type="checkbox" class="form-check-input" id="name" v-model="form.to_all">

                  <span
                      v-if="form.hasError('to_all')"
                      class="text-danger"
                      v-text="form.getError('to_all')">
                    </span>
              </div>
          </div>
          <div v-if="!form.to_all" class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Employee</label>
              <v-select
                  id="gender_id"
                  v-model="form.employee_id"
                  name="leave_type_id"
                  :options="employees"
                  label="name"
                  :reduce="option => option.id"
                  :clearable="false"
              />

              <span
                  v-if="form.hasError('employee_id')"
                  class="text-danger"
                  v-text="form.getError('employee_id')">
                    </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Leave Type</label>
              <v-select
                  id="gender_id"
                  v-model="form.leave_type_id"
                  name="leave_type_id"
                  :options="leave_types"
                  label="name"
                  :reduce="option => option.id"
                  :clearable="false"
              />

              <span
                  v-if="form.hasError('leave_type_id')"
                  class="text-danger"
                  v-text="form.getError('leave_type_id')">
                    </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Days</label>
              <input type="text" class="form-control" id="name" v-model="form.days">

              <span
                  v-if="form.hasError('days')"
                  class="text-danger"
                  v-text="form.getError('days')">
                    </span>
                    <small v-if="hasBalance">Last year's balance of {{previousDays}} days will be automatically added to this year's leave entitlement for employees.</small>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Year</label>
              <input type="text" class="form-control" id="name" v-model="form.year">

              <span
                  v-if="form.hasError('year')"
                  class="text-danger"
                  v-text="form.getError('year')">
                    </span>
            </div>
          </div>
        </div>


        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>

      </form>

      <template #modal-footer="{cancel}">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing"
                @click.prevent="createAssignLeave">
          Submit
        </button>
      </template>

    </b-modal>

    <b-modal
        id="update-modal"
        title="Edit Leave"
        hide-header-close
        size="lg"
        footer-class="d-flex justify-content-start"
        modal-class="modal-blur"
        no-close-on-esc
    >
      <form @submit.prevent="editLeave" id="createForm">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Employee</label>
              <v-select
                  id="gender_id"
                  v-model="form.employee_id"
                  name="leave_type_id"
                  :options="employees"
                  label="name"
                  :reduce="option => option.id"
                  :clearable="false"
              />

              <span
                  v-if="form.hasError('employee_id')"
                  class="text-danger"
                  v-text="form.getError('employee_id')">
                    </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Leave Type</label>
              <v-select
                  id="gender_id"
                  v-model="form.leave_type_id"
                  name="leave_type_id"
                  :options="leave_types"
                  label="name"
                  :reduce="option => option.id"
                  :clearable="false"
              />

              <span
                  v-if="form.hasError('leave_type_id')"
                  class="text-danger"
                  v-text="form.getError('leave_type_id')">
                    </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Days</label>
              <input type="text" class="form-control" id="name" v-model="form.days">

              <span
                  v-if="form.hasError('days')"
                  class="text-danger"
                  v-text="form.getError('days')">
                    </span>
                    <small class="text-muted" v-if="hasBalance">Last year's balance of {{previousDays}} days will be automatically added to this year's leave entitlement for employees.</small>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Year</label>
              <input type="text" class="form-control" id="name" v-model="form.year">

              <span
                  v-if="form.hasError('year')"
                  class="text-danger"
                  v-text="form.getError('year')">
                    </span>
            </div>
          </div>
        </div>


        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>

      </form>

      </form>
      <template #modal-footer="{cancel}">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing"
                @click.prevent="editLeave">
          Submit
        </button>

      </template>
    </b-modal>

  </div>
</template>

