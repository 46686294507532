<script>
import { defineComponent } from 'vue'
import { toPrice } from '@plugins/money'

export default defineComponent({
  name: 'Allowances',
  props: {
    employee: {
      type: Object,
      default: () => [],
    },
    permissions: {
      type: Array,
      default: () => [],
    },
    allowances: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {

      fields: [
        {
          name: 'allowance.name',
          title: 'Allowance',
          titleClass: ' text-start pe-3',
          dataClass: ' text-start pe-3',
          width: '8%',
        },

        {
          name: '__slot:amount',
          title: 'Amount',
          callback: function (value) {
            if (value < 0) {
              return '(' + toPrice(Math.abs(value)).toFormat('0,0.00') + ')'
            }
            return toPrice(value).toFormat('0,0.00')
          },
          titleClass: 'text-end pe-3',
          dataClass: 'text-end pe-3',
          width: '10%',
        },
        {
          name: '__slot:actions',
          dataClass: 'txt-center',
          width: '5%',
        },
      ],


      form: new window.Form({
        id:null,
        employee_id: this.employee.id,
        allowance_id: null,
        amount: null,
      }),

      enableForm: true,

      reverseForm: new window.Form({
        comments: '',
        detail_id: '',
        source: '1',
      }),

      selectedBill: '',
      employeeAllowances: [],
    }
  },

  computed: {
    vuetable() {
      return this.$refs.tablee.$refs.vuetable
    },
  },

  watch: {
    'form.item': function (item) {
      this.showStore = false
      this.showVendor = false
      this.assignedTo = []
      this.form.vendor_id = null
      this.form.store_id = null

      if (item) {
        this.setupProductPriceSetup(item)

        this.displayStoreSelection(item)

        this.displayDoctorsAssigned()

        this.findAvailableStock()
      }
    },
    'form.store_id': function (storeId) {
      if (storeId) {
        this.findAvailableStock()
      }
    },
  },

  mounted () {
    this.retrieveEmployeeAllowances()
  },

  methods: {
    retrieveEmployeeAllowances () {
      this.$httpClient.get('/employee/allowances/' + this.employee.id)
          .then(({ data }) => {
            this.employeeAllowances = data;
          }).catch(() => {
        this.$toast.error('There was a problem retrieving the Employee allowances')
      })
    },

    openAllowanceModal () {
      this.$bvModal.show('allowance-modal')
    },
    addAllowance () {
      this.form.post('/employee/allowance')
          .then(() => {
            this.$toast.success('Allowance has been added')
            this.retrieveEmployeeAllowances()
            this.vuetable.reload()
          })
          .catch((error) => {
            this.form.processing = false
            this.form.errors.record(error.response.data.errors)
          })
    },

    editAllowance(data){
      this.form.populate(data)
      this.$bvModal.show('edit-allowance-modal')
    },
    updateIncome(){
      this.form.patch('/employee/allowance/' + this.form.id)
          .then(() => {
            this.$toast.success('The changes have been saved')
            this.cleanUp()
            this.$bvModal.hide('edit-allowance-modal')
            this.vuetable.reload()


          })
          .catch()
    },
    deleteAllowance(rowData){
      this.form.id = rowData.id
      this.$toast.question('Are you sure?', 'You want to delete').then(() => {
        this.$httpClient.delete('/employee/allowance/' + this.form.id )
        .then((error) => {
          this.$toast.success('deleted')
          this.cleanUp()
          this.vuetable.reload()
        }).catch((error) => {
          this.$toast.error(error.response.data)
        })


      })
    },
    cleanUp () {
      this.form.reset()
      this.retrieveEmployeeAllowances()
    },


  },

})
</script>

<template>

  <div>

    <b-modal
        id="allowance-modal"
        body-class="px-4"
        content-class="bg-white rounded-3"
        footer-class="d-flex justify-content-between bg-muted-lt border-top"
        hide-header-close
        modal-class="modal-blur"
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        title="Allowance Form"
        @hidden="cleanUp"
    >
      <div class="form-group mb-4">
        <label class="form-label" for="item_id">Allowance</label>
        <v-select
            id="item_id"
            v-model="form.allowance_id"
            :clearable="false"
            :reduce="(option) => option.id"
            :options="allowances"
            label="name"
        >

        </v-select>
        <span
            v-if="form.errors.has('allowance_id')"
            class="text-danger"
            v-text="form.errors.first('allowance_id')"
        />
      </div>
      <div class="form-group mb-4">
        <div class="form-group">
          <label for="" class="form-label">Amount</label>
          <medic-money
              :value="form.amount"
              @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

          <div class="text-danger"
               v-if="form.hasError('amount')"
               v-text="form.getError('amount')">
          </div>
        </div>
      </div>

      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green"/>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button
            :disabled="form.processing" class="btn btn-primary"
            type="submit"
            @click="addAllowance"
        >
          Submit
        </button>
      </template>
    </b-modal>
    <b-modal
        id="edit-allowance-modal"
        body-class="px-4"
        content-class="bg-white rounded-3"
        footer-class="d-flex justify-content-between bg-muted-lt border-top"
        hide-header-close
        modal-class="modal-blur"
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        title="Edit Allowance Form"
        @hidden="cleanUp"
    >
      <div class="form-group mb-4">
        <label class="form-label" for="item_id">Allowance</label>
        <v-select
            id="item_id"
            v-model="form.allowance_id"
            :clearable="false"
            :reduce="(option) => option.id"
            :options="allowances"
            label="name"
        >

        </v-select>
        <span
            v-if="form.errors.has('allowance_id')"
            class="text-danger"
            v-text="form.errors.first('allowance_id')"
        />
      </div>
      <div class="form-group mb-4">
        <div class="form-group">
          <label for="" class="form-label">Amount</label>
          <medic-money
              :value="form.amount"
              @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

          <div class="text-danger"
               v-if="form.hasError('amount')"
               v-text="form.getError('amount')">
          </div>
        </div>
      </div>

      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green"/>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button
            :disabled="form.processing" class="btn btn-primary"
            type="submit"
            @click="updateIncome"
        >
          Submit
        </button>
      </template>
    </b-modal>


    <div class="card rounded-3 mb-5 ">
      <div class="card-header">
        <div class="">
          <h2 class="card-title mb-0 fw-bold">
            Allowances
          </h2>
        </div>

        <div class="card-actions">
          <div class="btn-list">
            <button class="btn btn-primary me-4" @click.prevent="openAllowanceModal">
              <i class="uil uil-plus me-1"/> Add Allowance
            </button>
          </div>
        </div>
      </div>

      <VueTable
          ref="tablee"
          :api-mode="false"
          :data="employeeAllowances"
          :fields="fields"
          :load-on-start="false"
          :show-pagination="false"
          :show-per-page="false"
      >

        <template #actions="props">
          <div class="dropdown">
            <button
                class="btn align-text-center py-1"
                data-bs-toggle="dropdown"
            >
              <i class="uil uil-ellipsis-h"/>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <div class="dropdown-divider"/>

              <a class="dropdown-item" href="#" @click="editAllowance(props.rowData)">
                <i class="uil uil-pen fs-3 me-2"/> Edit
              </a>
              <a class="dropdown-item text-danger" href="#" @click="deleteAllowance(props.rowData)">
                <i class="uil  uil-trash-alt me-2"/> Delete
              </a>
            </div>
          </div>
        </template>
        <template #actions="props">
          <div class="dropdown">
            <button
                class="btn align-text-center py-1"
                data-bs-toggle="dropdown"
            >
              <i class="uil uil-ellipsis-h"/>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <div class="dropdown-divider"/>

              <a class="dropdown-item" href="#" @click="editAllowance(props.rowData)">
                <i class="uil uil-pen fs-3 me-2"/> Edit
              </a>
              <a class="dropdown-item text-danger" href="#" @click="deleteAllowance(props.rowData)">
                <i class="uil  uil-trash-alt me-2"/> Delete
              </a>
            </div>
          </div>
        </template>
      </VueTable>
    </div>

  </div>
</template>

<style scoped>

</style>
