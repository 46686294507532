var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card card-stacked" },
        [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "input-icon me-2" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.moreParams.filter.search,
                    expression: "moreParams.filter.search",
                  },
                ],
                staticClass: "form-control bg-muted-lt rounded-2",
                attrs: { type: "text", placeholder: "Search leave" },
                domProps: { value: _vm.moreParams.filter.search },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.moreParams.filter,
                      "search",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm.permissions.includes("create-payroll-adjustment")
              ? _c("div", { staticClass: "card-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: { click: _vm.showAllowance },
                    },
                    [
                      _c("i", { staticClass: "uil uil-plus me-1" }),
                      _vm._v("Add Allowance\n          "),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("VueTable", {
            ref: "table",
            attrs: {
              "api-url": "/datatable/payroll-allowances",
              fields: _vm.fields,
              "append-params": _vm.moreParams,
            },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function (props) {
                  return [
                    _c("div", { staticClass: "dropdown" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn align-text-top py-1",
                          attrs: { "data-bs-toggle": "dropdown" },
                        },
                        [_c("i", { staticClass: "uil uil-ellipsis-h" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dropdown-menu dropdown-menu-end" },
                        [
                          !props.rowData.is_included &&
                          _vm.permissions.includes("delete-payroll-adjustment")
                            ? _c(
                                "a",
                                {
                                  staticClass: "dropdown-item text-danger",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delAllowance(props.rowData)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "uil uil-trash-alt me-2",
                                  }),
                                  _vm._v(" Delete\n              "),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "is_included",
                fn: function (props) {
                  return [
                    props.rowData.is_included
                      ? _c("span", { staticClass: "text-success" }, [
                          _vm._v("included"),
                        ])
                      : _c("span", { staticClass: "text-warning" }, [
                          _vm._v("not included"),
                        ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "payroll-allowance-modal",
            title: "Payroll Allowance",
            "hide-header-close": "",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
            "no-close-on-esc": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn me-auto",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return cancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Close\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", disabled: _vm.form.processing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.addEmployeeAllowance.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Submit\n        ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "form",
            {
              attrs: { id: "createForm" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.addEmployeeAllowance.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Employee")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "employee_id",
                          name: "employee_payroll_id",
                          options: _vm.employees,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function (option) {
                              return [
                                _c("li", { staticClass: "media mb-1" }, [
                                  _c("div", { staticClass: "media-body" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(option.name)),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "selected-option",
                            fn: function (option) {
                              return [
                                _c("li", { staticClass: "media mb-1" }, [
                                  _c("div", { staticClass: "media-body" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between",
                                      },
                                      [
                                        _c("div", [
                                          _c("span", [
                                            _vm._v(_vm._s(option.name)),
                                          ]),
                                          _c("br"),
                                          _vm._v(" "),
                                          _vm.leaveAllowance
                                            ? _c(
                                                "small",
                                                { staticClass: "text-blue" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toMoney(
                                                        option.basic_pay.amount
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.form.employee_payroll_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "employee_payroll_id", $$v)
                          },
                          expression: "form.employee_payroll_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("employee_payroll_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("employee_payroll_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-12" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Allowance")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          id: "gender_id",
                          name: "leave_type_id",
                          options: _vm.allowances,
                          label: "name",
                          reduce: (option) => option.id,
                          clearable: false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function (option) {
                              return [
                                _c("li", { staticClass: "media mb-1" }, [
                                  _c("div", { staticClass: "media-body" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(option.name)),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "selected-option",
                            fn: function (option) {
                              return [
                                _c("li", { staticClass: "media mb-1" }, [
                                  _c("div", { staticClass: "media-body" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between",
                                      },
                                      [
                                        _c("div", [
                                          _c("span", [
                                            _vm._v(_vm._s(option.name)),
                                          ]),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          _vm.LeaveAllowance.hasBeenPaid
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "fw-light text-warning",
                                                },
                                                [
                                                  _vm._v(
                                                    "Leave allowance has been paid"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.form.allowance_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "allowance_id", $$v)
                          },
                          expression: "form.allowance_id",
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("allowance_id")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("allowance_id")
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.leaveAllowance
                  ? _c("div", { staticClass: "col-lg-12" }, [
                      _c("div", { staticClass: "mb-3" }, [
                        _c(
                          "label",
                          { staticClass: "form-label", attrs: { for: "year" } },
                          [_vm._v("Year")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.year,
                              expression: "form.year",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "number" },
                          domProps: { value: _vm.form.year },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "year", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.form.hasError("year")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(_vm.form.getError("year")),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "name" } },
                      [_vm._v("Reason")]
                    ),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.reason,
                          expression: "form.reason",
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: _vm.form.reason },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "reason", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("reason")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("reason")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Amount")]
                      ),
                      _vm._v(" "),
                      _c("medic-money", {
                        attrs: { value: _vm.form.amount },
                        on: {
                          input: (value) => {
                            _vm.form.amount = value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("amount")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.form.getError("amount")),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "form-label", attrs: { for: "name" } },
                        [_vm._v("Date")]
                      ),
                      _vm._v(" "),
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          value: _vm.form.date,
                          "min-date": new Date(),
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.date = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("date")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.form.getError("date")),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.processing
                ? _c("div", { staticClass: "progress mb-2" }, [
                    _c("div", { staticClass: "progress" }, [
                      _c("div", {
                        staticClass:
                          "progress-bar progress-bar-indeterminate bg-green",
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-icon-addon" }, [
      _c("i", { staticClass: "uil uil-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }