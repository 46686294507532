<template>
  <div class="container">
    <div
      style="
        display: flex;
        justify-content: center;
        min-height: 400px;
        align-items: center;
      "
    >
      <iframe style="display: flex;"
        v-if="loaded"
        :src="`/storage/${org.chart}`"
        width="90%"
        height="900px"
      />
      <div v-else class="spinner" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    org: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data () {
    return {
      loaded: false,
    }
  },
  mounted () {
    setTimeout(() => (this.loaded = true), 1000)
  },
}
</script>
<style scoped>
.spinner {
  display: inline-block;
  width: 70px;
  height: 70px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-left-color: #041b3f;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
