<script>

import Papa from "papaparse";

export default {
    props: {
        departments: {
            type: Array,
            required: true,
            default: function () {
                return []
            },
        },
    },
    data() {
        return {
            form: new window.Form({
                department_id: null,
                period: null,
                to_csv: false
            }),

        }
    },

    methods: {
        generateReport() {
            if(this.form.department_id != null||this.form.period !== null ){
                this.form.processing = true
                if (!this.form.to_csv) {
                    this.$httpClient.get('/reports/performance-appraisal/generate', {
                        responseType: 'blob',
                        params: {
                            ...this.form.data(),
                        },
                    })
                        .then(({data}) => {
                            this.form.processing = false

                            const url = URL.createObjectURL(
                                new Blob([data], {type: 'application/pdf'}),
                            )

                            const link = document.createElement('a')

                            link.href = url
                            link.target = '_blank'
                            link.click()
                        }).catch((error) => {
                        this.form.processing = false
                        if(error.response.status == 404){
                            this.$toast.error("No record found");
                        }
                    })
                } else {
                    this.toCsv();
                }
            }else{
                this.$toast.error("Fill the form properly");
            }

        },
        toCsv() {
            this.$httpClient.get('/reports/employee-leave-balance/csv', {
                // responseType: 'blob',
                params: {
                    ...this.form.data(),
                }
            })
                .then(({data}) => {
                    const csv = Papa.unparse(data);
                    // Create a downloadable link
                    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.setAttribute('href', url);
                    link.setAttribute('download', `EMPLOYEE-LEAVE-BALANCES-${this.form.year}.csv`);
                    link.style.display = 'none';
                    document.body.appendChild(link);

                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(url); // Free up memory
                    this.form.processing = false
                })
                .catch(error => {
                    this.form.processing = false
                    console.error('Error exporting CSV:', error);
                });
        }
    },
}
</script>

<template>
    <div>
        <div class="card card-stacked card-md mb-4">
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-8 mx-auto">
                        <div class="mb-4">
                            <div class="text-center">
                                <h1 class="fw-bolder">
                                    Performance Appraisal
                                </h1>
                            </div>
                        </div>
<!--                        <div class="mb-4">-->
<!--                            <label class="form-check form-switch">-->
<!--                                <input v-model="form.to_csv" class="form-check-input"-->
<!--                                       type="checkbox">-->
<!--                                <span class="form-check-label">To CSV</span>-->
<!--                            </label>-->
<!--                        </div>-->
                        <div class="row gx-4">
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label class="form-label" for="item_id">Department</label>
                                    <v-select id="item_id" v-model="form.department_id" :clearable="false"
                                              :reduce="(option) => option.id"
                                              :options="departments" label="name">

                                    </v-select>
                                    <span v-if="form.errors.has('department_id')" class="text-danger"
                                          v-text="form.errors.first('department_id')"/>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label class="form-label" for="">Period</label>
                                    <date-picker name="date" formclass="form-control bg-white" :value="form.date"
                                                 :max-date="false" @on-change="(dateOb, dateStr) => {
            form.period = dateStr
          }"/>
                                </div>
                            </div>
                        </div>

                        <button :disabled="form.processing" class="btn btn-primary mx-auto" @click="generateReport">
                            Generate
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
