var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "min-height": "400px",
          "align-items": "center",
        },
      },
      [
        _vm.loaded
          ? _c("iframe", {
              staticStyle: { display: "flex" },
              attrs: {
                src: `/storage/${_vm.org.chart}`,
                width: "90%",
                height: "900px",
              },
            })
          : _c("div", { staticClass: "spinner" }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }