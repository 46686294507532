<script>
import Vue from 'vue'
import { toPrice } from "@plugins/money";
import PayrollDetailsComponent from "./PayrollDetailsComponent.vue";

Vue.component('PayrollDetailsComponent', PayrollDetailsComponent)

export default {
    props: {
        date: {
            type: Date,
            required: true,
        },
    },
    data() {
        return {
            fields: [
                {
                    name: '__slot:details',
                    title: '#',
                    width: '5%',
                },
                {
                    name: "__slot:name",
                    title: "Employee",
                    titleClass: "font-weight-bold",
                },
                {
                    name: "basic_pay",
                    title: "Basic pay",
                    titleClass: "font-weight-bold text-right",
                    dataClass: "text-right",
                    callback: function (value) {
                        if (value < 0) {
                            return (
                                "(" +
                                toPrice(Math.abs(value * 0.01)).toFormat(
                                    "0,0.00"
                                ) +
                                ")"
                            );
                        }
                        return toPrice(value * 0.01).toFormat("0,0.00");
                    },
                },
                {
                    name: "benefits",
                    title: "Benefits",
                    titleClass: "font-weight-bold text-right",
                    dataClass: "text-right",
                    callback: function (value) {
                        if (value < 0) {
                            return (
                                "(" +
                                toPrice(Math.abs(value * 0.01)).toFormat(
                                    "0,0.00"
                                ) +
                                ")"
                            );
                        }
                        return toPrice(value * 0.01).toFormat("0,0.00");
                    },
                },
                {
                    name: "pension",
                    title: "Pension",
                    titleClass: "font-weight-bold text-right",
                    dataClass: "text-right",
                    width: "10%",
                    callback: function (value) {
                        if (value < 0) {
                            return (
                                "(" +
                                toPrice(Math.abs(value * 0.01)).toFormat(
                                    "0,0.00"
                                ) +
                                ")"
                            );
                        }
                        return toPrice(value * 0.01).toFormat("0,0.00");
                    },
                },
                {
                    name: "paye",
                    title: "Tax",
                    titleClass: "font-weight-bold text-right",
                    dataClass: "text-right",
                    callback: function (value) {
                        if (value < 0) {
                            return (
                                "(" +
                                toPrice(Math.abs(value * 0.01)).toFormat(
                                    "0,0.00"
                                ) +
                                ")"
                            );
                        }
                        return toPrice(value * 0.01).toFormat("0,0.00");
                    },
                },
                {
                    name: "total_deductions",
                    title: "Deductions",
                    titleClass: "font-weight-bold text-right",
                    dataClass: "text-right",
                    callback: function (value) {
                        if (value < 0) {
                            return (
                                "(" +
                                toPrice(Math.abs(value * 0.01)).toFormat(
                                    "0,0.00"
                                ) +
                                ")"
                            );
                        }
                        return toPrice(value * 0.01).toFormat("0,0.00");
                    },
                },
                {
                    name: "net_pay",
                    title: "Net Pay",
                    titleClass: "font-weight-bold text-right",
                    dataClass: "text-right",
                    callback: function (value) {
                        if (value < 0) {
                            return (
                                "(" +
                                toPrice(Math.abs(value * 0.01)).toFormat(
                                    "0,0.00"
                                ) +
                                ")"
                            );
                        }
                        return toPrice(value * 0.01).toFormat("0,0.00");
                    },
                },
                {
                    name: "month",
                    title: "Month",
                    titleClass: "text-center",
                    dataClass: "text-center",
                },
                {
                    name: "__slot:actions",
                    titleClass: "text-center",
                    dataClass: "text-left",
                    width: "5%",
                },
            ],
            moreParams: {
                filter: {
                    search: "",
                    showDetails: location.pathname.split("/")[3],
                },
            },

            form: new window.Form({
                id: null,
                month: "",
            }),
        };
    },

    computed: {
        vuetable() {
            return this.$refs.table.$refs.vuetable;
        },
    },

    methods: {
        showCreateForm() {
            this.$bvModal.show("create-modal");
        },

        showUpdateForm(rowData) {
            this.selectedDepartment = rowData;
            this.viewEditForm = true;
        },
        downloadPayslip(rowData) {
            this.form.processing = true;

            this.$httpClient
                .get("/download-payslip", {
                    responseType: "blob",
                    params: {
                        data: rowData,
                    },
                })
                .then(({ data }) => {
                    this.form.processing = false;

                    const url = URL.createObjectURL(
                        new Blob([data], { type: "application/pdf" })
                    );

                    const link = document.createElement("a");

                    link.href = url;
                    link.target = "_blank";
                    link.click();
                })
                .catch((error) => {
                    this.form.processing = false;

                    this.form.errors.record(error.response.data.errors);
                });
            console.log(rowData);
        },
        processPayroll() {
            this.form
                .post("/payroll")
                .then(() => {
                    this.$bvModal.hide("create-modal");
                    this.$toast.success("Payroll has been processed");
                    this.vuetable.reload();
                })
                .catch((error) => {
                    let errorContent = error.response;
                    if (errorContent.status === 400) {
                        this.$toast.error(errorContent.data);
                        console.log("here");
                    }
                    console.log(error);
                });
        },
        formP10(rowData) {
            console.log(rowData);
            this.$httpClient
                .get("/reports/download-form-9", {
                    responseType: "blob",
                    params: {
                        year: rowData.year,
                        employee_payroll_id: rowData.employee_payroll_id,
                    },
                })
                .then(({ data }) => {
                    this.form.processing = false;

                    const url = URL.createObjectURL(
                        new Blob([data], { type: "application/pdf" })
                    );

                    const link = document.createElement("a");

                    link.href = url;
                    link.target = "_blank";
                    link.click();
                })
                .catch((error) => {
                    this.form.processing = false;

                    this.form.errors.record(error.response.data.errors);
                });
        },
        showEditForm(rowData) {
            this.form.populate(rowData);
            this.form.id = rowData.id;

            this.$bvModal.show("update-modal");
        },

        updateDepartment() {
            this.form
                .patch("/departments/" + this.form.id)
                .then(() => {
                    this.vuetable.reload();
                    this.$bvModal.hide("update-modal");
                    this.$toast.success("The changes have been saved");
                })
                .catch();
        },

        applyFilter() {
            this.vuetable.reload();
        },

        cleanUp() {
            this.form.reset();
        },
    },
};
</script>

<template>
    <div>
        <div class="card card-stacked">
            <div class="card-header">
                <div class="input-icon me-2">
                    <span class="input-icon-addon">
                        <i class="uil uil-search"></i>
                    </span>
                    <input
                        type="text"
                        class="form-control bg-muted-lt rounded-2"
                        placeholder="Search"

                        @input="applyFilter"
                        v-model="moreParams.filter.search"
                    />
                </div>

                <div class="card-actions">
                    <a class="btn btn-primary-outline" href="/payroll/lists">
                        <i class="uil uil-arrow-left me-2" /> Back
                    </a>
                    <a class="btn btn-primary" href="/payroll/payroll-list">
                        Payroll processing
                    </a>
                </div>
            </div>
            <VueTable
                ref="table"
                api-url="/datatable/payroll-details"
                :fields="fields"
                :append-params="moreParams"
                detail-row-component="PayrollDetailsComponent"
            >
                <template #details="props">
                    <button class="btn btn-outline-azure btn-sm py-1 fs-3" @click="vuetable.toggleDetailRow(props.rowData.id)">
                        <i class="uil uil-plus-square"></i>
                    </button>
                </template>
                <template v-slot:name="props">
                    <div class="media">
                        <div class="media-body align-self-center">
                            <span class="text-sm"
                                >{{ props.rowData.employee.name }}
                            </span>
                        </div>
                    </div>
                </template>

                <template v-slot:actions="props">
                    <div class="dropdown">
                        <button
                            class="btn align-text-top py-1"
                            data-bs-toggle="dropdown"
                        >
                            <i class="uil uil-ellipsis-h"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <b-dropdown-header>Payslip</b-dropdown-header>

                            <div class="dropdown-item">
                                <a
                                    href="#"
                                    class="text-secondary"
                                    @click="downloadPayslip(props.rowData)"
                                    title="Download the payslip"
                                >
                                    <i class="uil uil-down-arrow mr-2"></i>
                                    Download</a
                                >
                            </div>

                            <!--                            <div class="dropdown-item">-->
                            <!--                                <a href="#" class="text-secondary" @click.prevent="emailPayslip(props.rowData)"-->
                            <!--                                    title="Mail the payslip to employee">-->
                            <!--                                    <i class="uil uil-envelope mr-2"></i> Email</a>-->
                            <!--                            </div>-->
                            <b-dropdown-header>Forms</b-dropdown-header>
                            <div class="dropdown-item">
                                <a
                                    href="#"
                                    class="text-secondary"
                                    @click.prevent="formP10(props.rowData)"
                                    title="Download Form P9"
                                >
                                    Form
                                    P9</a
                                >
                            </div>
                        </div>
                    </div>
                </template>
            </VueTable>
        </div>

        <b-modal
            id="create-modal"
            title="Payroll Processing"
            hide-header-close
            footer-class="d-flex justify-content-start"
            modal-class="modal-blur"
            no-close-on-esc
        >
            <form @submit.prevent="createDepartment" id="createForm">
                <div class="mb-3">
                    <label for="name" class="form-label">Select Month</label>
                    <date-picker
                        name="month"
                        :value="form.month"
                        :max-date="false"
                        @on-change="
                            (dateOb, dateStr) => {
                                form.month = dateStr;
                            }
                        "
                    />

                    <span
                        v-if="form.hasError('month')"
                        class="text-danger"
                        v-text="form.getError('month')"
                    >
                    </span>
                </div>

                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div
                            class="progress-bar progress-bar-indeterminate bg-green"
                        ></div>
                    </div>
                </div>
            </form>

            <template #modal-footer="{ cancel }">
                <button
                    type="button"
                    class="btn me-auto"
                    @click.prevent="cancel"
                    :disabled="form.processing"
                >
                    Close
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    :disabled="form.processing"
                    @click.prevent="processPayroll"
                >
                    Process
                </button>
            </template>
        </b-modal>

        <b-modal
            id="update-modal"
            title="Edit Department"
            hide-header-close
            footer-class="d-flex justify-content-start"
            modal-class="modal-blur"
            no-close-on-esc
        >
            <form @submit.prevent="updateDepartment" id="updateForm">
                <div class="mb-3">
                    <label for="name" class="form-label">Select Month</label>
                    <input
                        type="text"
                        class="form-control"
                        id="month"
                        v-model="form.month"
                    />

                    <span
                        v-if="form.hasError('month')"
                        class="text-danger"
                        v-text="form.getError('month')"
                    >
                    </span>
                </div>

                <div class="progress mb-2" v-if="form.processing">
                    <div class="progress">
                        <div
                            class="progress-bar progress-bar-indeterminate bg-green"
                        ></div>
                    </div>
                </div>
            </form>
            <template #modal-footer="{ cancel }">
                <button
                    type="button"
                    class="btn me-auto"
                    @click.prevent="cancel"
                    :disabled="form.processing"
                >
                    Close
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    :disabled="form.processing"
                    @click.prevent="updateDepartment"
                >
                    Process
                </button>
            </template>
        </b-modal>
    </div>
</template>
