import { render, staticRenderFns } from "./_baseTable.vue?vue&type=template&id=62823460&scoped=true&"
import script from "./_baseTable.vue?vue&type=script&lang=js&"
export * from "./_baseTable.vue?vue&type=script&lang=js&"
import style0 from "./_baseTable.vue?vue&type=style&index=0&id=62823460&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62823460",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bismarck/Documents/software/hrm_ecobiz_dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62823460')) {
      api.createRecord('62823460', component.options)
    } else {
      api.reload('62823460', component.options)
    }
    module.hot.accept("./_baseTable.vue?vue&type=template&id=62823460&scoped=true&", function () {
      api.rerender('62823460', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/global/table/_baseTable.vue"
export default component.exports