<script>

import Papa from "papaparse";

export default {
    data() {
        return {
            form: new window.Form({
                date: '',
                to_csv: ''
            }),

        }
    },

    methods: {
        generateReport() {
            this.form.processing = true
            if (!this.form.to_csv) {
                this.$httpClient.get('/reports/global-pay/generate', {
                    responseType: 'blob',
                    params: {
                        ...this.form.data(),
                    },
                })
                    .then(({data}) => {
                        this.form.processing = false

                        const url = URL.createObjectURL(
                            new Blob([data], {type: 'application/pdf'}),
                        )

                        const link = document.createElement('a')

                        link.href = url
                        link.target = '_blank'
                        link.click()
                    }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.error) {

                        const errorMessage = error.response.data.error;
                        console.log(error.response.data);
                        this.p9Form.errors.record(errorMessage);
                    } else {
                        console.error('An unexpected error occurred.');
                        console.log(error.response.status);
                        if (error.response.status === 400) {
                            this.$toast.error('No payment for the period selected')
                        }
                        this.form.errors.record('An unexpected error occurred. Please try again later.');
                    }
                    this.form.processing = false;
                })
            } else {
                this.toCsv()
            }

        },

        toCsv() {
            this.$httpClient.get('/reports/global-pay/csv', {
                params: {
                    ...this.form.data(),
                }
            })
                .then(({data}) => {
                    const csv = Papa.unparse(data);
                    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.setAttribute('href', url);
                    link.setAttribute('download', `GLOBAL-PAYROLL-${this.form.date}.csv`);
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                    this.form.processing = false
                })
                .catch(error => {
                    this.form.processing = false
                    console.error('Error exporting CSV:', error);
                });
        }
    },
}
</script>

<template>
    <div>
        <div class="card card-stacked card-md mb-4">
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-8 mx-auto">
                        <div class="mb-4">
                            <div class="text-start">
                                <h1 class="fw-bolder">
                                    Global Payroll Report
                                </h1>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="form-check form-switch">
                                <input v-model="form.to_csv" class="form-check-input"
                                       type="checkbox">
                                <span class="form-check-label">To CSV</span>
                            </label>
                        </div>
                        <div class="row gx-4">
                            <div class="col-xl-6">
                                <div class="form-group mb-4">
                                    <label for="startDate">Date</label>
                                    <date-picker name="date" formclass="form-control bg-white" :value="form.date"
                                                 :max-date="false" @on-change="(dateOb, dateStr) => {
            form.date = dateStr
          }"/>
                                </div>
                            </div>

                        </div>

                        <button
                            :disabled="form.processing"
                            class="btn btn-primary mx-auto"
                            @click="generateReport"
                        >
                            Generate
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
