<script>
import { toPrice } from '@plugins/money'

export default {
  props: {
    allowances:{
      type:Array,
      default: function () {
        return []
      },
    },
      permissions:{
          type:Array,
          default: function () {
              return []
          },
      },
      employees:{
          type:Array,
      }
  },

  data() {
    return {
      months:[
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      fields: [
        {
          name: 'employee.payroll_no',
          title: 'Payroll No',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'employee.name',
          title: 'Employee',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'allowance.name',
          title: 'Allowance',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'amount',
          title: 'amount',
            callback: function (value) {
                if (value < 0) {
                    return '(' + toPrice(Math.abs((value * 0.01))).toFormat('0,0.00') + ')'
                }
                return toPrice((value * 0.01)).toFormat('0,0.00')
            },
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'month',
          title: 'Month',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },

        {
          name: '__slot:is_included',
          title: 'STATUS',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: '__slot:actions',
          width: '15%',
          titleClass: 'text-center',
          dataClass: 'text-right',
        },
      ],
        selectedEmployee: null,
      moreParams: {
        filter: {
          search: '',
          // date: new Date().toISOString().slice(0, 10)
        }
      },
      income:null,
      leaveAllowance:false,
      LeaveAllowance: {
        hasBeenPaid: false,
        leave:{}
      },
      form: new window.Form({
        id:null,
        employee_payroll_id: null,
        allowance_id: null,
        amount: '',
        reason: '',
        year: null,
        date: '',
        month:''
      })
    }
  },
  watch:{
    'form.allowance_id':function(value){
      let selectedAllowance = this.allowances.find((allowance)=>allowance.id === this.form.allowance_id)
      if(selectedAllowance.name === 'Leave'){
        this.leaveAllowance = true
        this.checkLeaveAllowance()
      }else{
        this.leaveAllowance =false
        this.year = null
      }
    },
    'form.employee_payroll_id':function (value){
      let selectedAllowance = this.allowances.find((allowance)=>allowance.id === this.form.allowance_id)
        this.selectedEmployee = this.employees.find((employee)=>employee.id == value);

        if(selectedAllowance.name === 'Leave'){
        this.leaveAllowance = true
        this.checkLeaveAllowance()
      }else{
        this.leaveAllowance =false
        this.year = null
      }
        this.form.amount = this.toMoney(this.selectedEmployee.basic_pay.amount ?? 0);
    }
  },
  mounted () {
    // this.retrieveEmployees()
  },
  computed: {
    vuetable () {
      return this.$refs.table.$refs.vuetable
    },
  },

  methods:{
    retrieveEmployees () {
      this.$httpClient.get('/datatable/payroll-employees/')
          .then(({ data }) => {
            this.employees = data.data;
          }).catch(() => {
        this.$toast.error('There was a problem retrieving the Employees')
      })
    },
      toMoney(value) {
          if (value < 0) {
              return '(' + toPrice(Math.abs((value * 0.01))).toFormat('0,0.00') + ')'
          }
          return toPrice((value * 0.01)).toFormat('0,0.00')
      },
      checkLeaveAllowance(){
      this.$httpClient.get('/check-leave-allowance',{
          params: {
            employee: this.form.employee_payroll_id
          }
      })
          .then(({ data }) => {
            if(Object.keys(data).length === 0){
              this.LeaveAllowance.hasBeenPaid = false
              this.LeaveAllowance.leave = {}
            }else{
              this.LeaveAllowance.hasBeenPaid = true
              this.LeaveAllowance.leave = data
              this.form.amount = this.income
            }
          }).catch(() => {
        this.$toast.error('There was a problem retrieving the Employee Leave allowance')
      })
    },
    showAllowance(){
      this.$bvModal.show('payroll-allowance-modal')
    },
    addEmployeeAllowance(){
      this.form.post('/payroll/allowance-inputs')
          .then(() => {
            this.$bvModal.hide('payroll-allowance-modal');
            this.form.reset();
            this.$toast.success('One time Allowance has been added.');
            this.vuetable.reload();
          })
          .catch( (error) => {
                if (error.response && error.response.data && error.response.data.errors) {
                  this.form.errors.record(error.response.data.errors);
                } else {
                  this.$toast.error(error.response.data)
                }
              }

          )
    },
    delAllowance(rowData){
      this.$httpClient.delete('/payroll/allowance-inputs',{
        params:{
          allowance_id: rowData.id
        }
      })
          .then(() => {
            this.$toast.success('deleted')
            this.vuetable.reload();
          }).catch(() => {
        this.$toast.error('There was a problem deleting the allowance')
      })
    }
  }
}
</script>
<template>
  <div>
    <div class="card card-stacked">
      <div class="card-header">
        <div class="input-icon me-2">
          <span class="input-icon-addon">
            <i class="uil uil-search"></i>
          </span>
          <input type="text" class="form-control bg-muted-lt rounded-2" placeholder="Search leave"
                 v-model="moreParams.filter.search">
        </div>

        <div v-if="permissions.includes('create-payroll-adjustment')" class="card-actions">
          <button @click="showAllowance" class="btn btn-primary">
            <i class="uil uil-plus me-1"></i>Add Allowance
          </button>
        </div>
      </div>
      <VueTable ref="table" api-url="/datatable/payroll-allowances" :fields="fields" :append-params="moreParams">
        <template v-slot:actions="props">
          <div class="dropdown">
            <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
              <i class="uil uil-ellipsis-h"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
<!--              <div v-if="!props.rowData.is_included">-->
<!--                <a v-if="!props.rowData.is_included" class="dropdown-item" href="#" @click="showEditDeduction(props.rowData)">-->
<!--                  <i class="uil uil-pen fs-3 me-2"></i> Edit-->
<!--                </a>-->
<!--              </div>-->

              <a v-if="!props.rowData.is_included && permissions.includes('delete-payroll-adjustment')" class="dropdown-item text-danger" href="#" @click="delAllowance(props.rowData)">
                <i class="uil  uil-trash-alt me-2"/> Delete
              </a>
            </div>
          </div>
        </template>
        <template #is_included="props">
          <span v-if="props.rowData.is_included" class="text-success">included</span>
          <span class="text-warning" v-else>not included</span>

        </template>
      </VueTable>
    </div>
    <b-modal id="payroll-allowance-modal" title="Payroll Allowance" hide-header-close
             footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
      <form @submit.prevent="addEmployeeAllowance" id="createForm">
        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Employee</label>
              <v-select id="employee_id" v-model="form.employee_payroll_id" name="employee_payroll_id" :options="employees"
                        label="name" :reduce="option => option.id" :clearable="false">
                <template v-slot:option="option">
                  <li class="media mb-1">
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </li>
                </template>
                <template v-slot:selected-option="option">
                  <li class="media mb-1">
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <div>
                          <span>{{ option.name }}</span><br>
                          <small v-if="leaveAllowance" class="text-blue">{{ toMoney(option.basic_pay.amount)}}</small>
                        </div>
                      </div>
                    </div>
                  </li>
                </template>
              </v-select>
              <span v-if="form.hasError('employee_payroll_id')" class="text-danger" v-text="form.getError('employee_payroll_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Allowance</label>

              <v-select id="gender_id" v-model="form.allowance_id" name="leave_type_id" :options="allowances"
                        label="name" :reduce="option => option.id" :clearable="false">
                <template v-slot:option="option">
                  <li class="media mb-1">
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </li>
                </template>
                <template v-slot:selected-option="option">
                  <li class="media mb-1">
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <div>
                          <span>{{ option.name }}</span> <br>
                          <small v-if="LeaveAllowance.hasBeenPaid" class="fw-light text-warning">Leave allowance has been paid</small>
                        </div>
                      </div>
                    </div>
                  </li>
                </template>

              </v-select>
              <span v-if="form.hasError('allowance_id')" class="text-danger" v-text="form.getError('allowance_id')">
              </span>
            </div>
          </div>
          <div v-if="leaveAllowance" class="col-lg-12">
            <div class="mb-3">
              <label for="year" class="form-label">Year</label>
              <input v-model="form.year" type="number" class="form-control">
              <span v-if="form.hasError('year')" class="text-danger" v-text="form.getError('year')">
              </span>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Reason</label>
              <textarea class="form-control" v-model="form.reason"></textarea>

              <span v-if="form.hasError('reason')" class="text-danger" v-text="form.getError('reason')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Amount</label>
              <medic-money
                  :value="form.amount"
                  @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

              <span v-if="form.hasError('amount')" class="text-danger" v-text="form.getError('amount')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Date</label>
              <date-picker name="date_of_birth" :value="form.date" :min-date="new Date()" @on-change="(dateOb, dateStr) => {
              form.date = dateStr
            }" />

              <span v-if="form.hasError('date')" class="text-danger" v-text="form.getError('date')">
              </span>
            </div>
          </div>
        </div>


        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>

      </form>
      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="addEmployeeAllowance">
          Submit
        </button>

      </template>
    </b-modal>
  </div>

</template>
