var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xl-8 mx-auto p-4" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "card card-stacked" }, [
          _c(
            "form",
            {
              staticClass: "card-body py-4",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.registerEmployee.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row g-4" }, [
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "" } },
                    [_vm._v("Payroll Number")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.staff_number,
                        expression: "form.staff_number",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "name", type: "text" },
                    domProps: { value: _vm.form.staff_number },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "staff_number", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("staff_number")
                    ? _c("div", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(
                            _vm.form.getError("staff_number")
                          ),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "" } },
                    [_vm._v("Full name")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.name,
                        expression: "form.name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "name", type: "text" },
                    domProps: { value: _vm.form.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "name", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("name")
                    ? _c("div", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("name")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-6 form-group mb-4" },
                  [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "" } },
                      [_vm._v("Gender")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        id: "scheme_id",
                        label: "name",
                        options: _vm.genderList,
                        reduce: (option) => option,
                        clearable: false,
                      },
                      model: {
                        value: _vm.form.gender,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "gender", $$v)
                        },
                        expression: "form.gender",
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("gender")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("gender")),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("Date of Birth"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("date-picker", {
                        attrs: {
                          name: "date_of_birth",
                          formclass: "form-control bg-white",
                          value: _vm.form.dob,
                          "max-date": new Date(),
                        },
                        on: {
                          "on-change": (dateOb, dateStr) => {
                            _vm.form.dob = dateStr
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("dob")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.form.getError("dob")),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Identification number")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.identification_number,
                        expression: "form.identification_number",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "name", type: "text" },
                    domProps: { value: _vm.form.identification_number },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.form,
                          "identification_number",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("identification_number")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(
                            _vm.form.getError("identification_number")
                          ),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Mobile number")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.telephone,
                        expression: "form.telephone",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "name", type: "text" },
                    domProps: { value: _vm.form.telephone },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "telephone", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("identification_number")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(
                            _vm.form.getError("identification_number")
                          ),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Email address")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.email,
                        expression: "form.email",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "name", type: "text" },
                    domProps: { value: _vm.form.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "email", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("email")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("email")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Physical address")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.physical_address,
                        expression: "form.physical_address",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "name", type: "text" },
                    domProps: { value: _vm.form.physical_address },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.form,
                          "physical_address",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("physical_address")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(
                            _vm.form.getError("physical_address")
                          ),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-6 form-group mb-4" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "plan_type" },
                      },
                      [_vm._v("Marital Status")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        id: "scheme_id",
                        label: "name",
                        options: _vm.marital_status,
                        reduce: (option) => option.value,
                        clearable: false,
                      },
                      model: {
                        value: _vm.form.marital_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "marital_status", $$v)
                        },
                        expression: "form.marital_status",
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("marital_status")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.form.getError("marital_status")
                            ),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c("h5", [_vm._v("Next of kin")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-label",
                          attrs: { for: "plan_type" },
                        },
                        [_vm._v("Name")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.next_of_kin,
                            expression: "form.next_of_kin",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "name", type: "text" },
                        domProps: { value: _vm.form.next_of_kin },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "next_of_kin",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("next_of_kin")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("next_of_kin")
                              ),
                            },
                          })
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-label",
                          attrs: { for: "plan_type" },
                        },
                        [_vm._v("Tel")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.next_of_kin_tel,
                            expression: "form.next_of_kin_tel",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { id: "name", type: "text" },
                        domProps: { value: _vm.form.next_of_kin_tel },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "next_of_kin_tel",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.form.hasError("next_of_kin_tel")
                        ? _c("span", {
                            staticClass: "text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.getError("next_of_kin_tel")
                              ),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Nationality")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.nationality,
                        expression: "form.nationality",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "name", type: "text" },
                    domProps: { value: _vm.form.nationality },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "nationality", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("nationality")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("nationality")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Home County")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.home_county,
                        expression: "form.home_county",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "name", type: "text" },
                    domProps: { value: _vm.form.home_county },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "home_county", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("home_county")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("home_county")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Home sub county")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.home_sub_county,
                        expression: "form.home_sub_county",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "name", type: "text" },
                    domProps: { value: _vm.form.home_sub_county },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.form,
                          "home_sub_county",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("home_sub_county")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(
                            _vm.form.getError("home_sub_county")
                          ),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Home constituency")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.home_constituency,
                        expression: "form.home_constituency",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "name", type: "text" },
                    domProps: { value: _vm.form.home_constituency },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.form,
                          "home_constituency",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("home_constituency")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(
                            _vm.form.getError("home_constituency")
                          ),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Ethnicity")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.ethnicity,
                        expression: "form.ethnicity",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "name", type: "text" },
                    domProps: { value: _vm.form.ethnicity },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "ethnicity", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("ethnicity")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("ethnicity")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-6 form-group mb-4" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "plan_type" },
                      },
                      [_vm._v("Is disabled")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        id: "scheme_id",
                        label: "name",
                        options: _vm.is_disabled,
                        reduce: (option) => option.value,
                        clearable: false,
                      },
                      model: {
                        value: _vm.form.is_disabled,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_disabled", $$v)
                        },
                        expression: "form.is_disabled",
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("is_disabled")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.form.getError("is_disabled")
                            ),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.form.is_disabled === 1
                  ? _c(
                      "div",
                      { staticClass: "col-lg-6 form-group mb-4" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "plan_type" },
                          },
                          [_vm._v("Type of Disability")]
                        ),
                        _vm._v(" "),
                        _c("v-select", {
                          attrs: {
                            id: "scheme_id",
                            label: "name",
                            options: _vm.disabilities,
                            reduce: (option) => option,
                            clearable: false,
                          },
                          model: {
                            value: _vm.form.disability,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "disability", $$v)
                            },
                            expression: "form.disability",
                          },
                        }),
                        _vm._v(" "),
                        _vm.form.hasError("disability")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.form.getError("disability")
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Job Title")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.job_title,
                        expression: "form.job_title",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "name", type: "text" },
                    domProps: { value: _vm.form.job_title },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "job_title", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("job_title")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("job_title")),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-6 form-group mb-4" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "plan_type" },
                      },
                      [_vm._v("Department")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        id: "scheme_id",
                        label: "name",
                        options: _vm.departments,
                        reduce: (option) => option.id,
                        clearable: false,
                      },
                      model: {
                        value: _vm.form.department_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "department_id", $$v)
                        },
                        expression: "form.department_id",
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("department_id")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.form.getError("department_id")
                            ),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-6 form-group mb-4" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "plan_type" },
                      },
                      [_vm._v("Report to")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        id: "scheme_id",
                        label: "name",
                        options: _vm.filterEmployee,
                        reduce: (option) => option.id,
                        clearable: false,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function (option) {
                            return [
                              _c("li", { staticClass: "media mb-1" }, [
                                _c("div", { staticClass: "media-body" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between",
                                    },
                                    [
                                      _c("span", [_vm._v(_vm._s(option.name))]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        { staticClass: "text-warning" },
                                        [_vm._v(_vm._s(option.department.name))]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "selected-option",
                          fn: function (option) {
                            return [
                              _c("li", { staticClass: "media mb-1" }, [
                                _c("div", { staticClass: "media-body" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between",
                                    },
                                    [_c("span", [_vm._v(_vm._s(option.name))])]
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.form.supervisor,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "supervisor", $$v)
                        },
                        expression: "form.supervisor",
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("supervisor")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.form.getError("supervisor")
                            ),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-6 form-group mb-4" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "plan_type" },
                      },
                      [_vm._v("Employee Type")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        id: "scheme_id",
                        label: "name",
                        options: _vm.employeetypes,
                        reduce: (option) => option.id,
                        clearable: false,
                      },
                      model: {
                        value: _vm.form.employee_type_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "employee_type_id", $$v)
                        },
                        expression: "form.employee_type_id",
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("employee_type_id")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.form.getError("employee_type_id")
                            ),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.is_in_contract
                  ? _c(
                      "div",
                      { staticClass: "col-lg-6 form-group mb-4" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "plan_type" },
                          },
                          [_vm._v("Employment Expiry Date")]
                        ),
                        _vm._v(" "),
                        _c("date-picker", {
                          attrs: {
                            name: "date_of_birth",
                            formclass: "form-control bg-white",
                            value: _vm.form.contract_exp,
                            "min-date": new Date(),
                          },
                          on: {
                            "on-change": (dateOb, dateStr) => {
                              _vm.form.contract_exp = dateStr
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.form.hasError("contract_exp")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.form.getError("contract_exp")
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-6 form-group mb-4" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "plan_type" },
                      },
                      [_vm._v("Job Group")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        id: "scheme_id",
                        label: "name",
                        options: _vm.jobgroups,
                        reduce: (option) => option.id,
                        clearable: false,
                      },
                      model: {
                        value: _vm.form.job_group_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "job_group_id", $$v)
                        },
                        expression: "form.job_group_id",
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("job_group_id")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.form.getError("job_group_id")
                            ),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-6 form-group mb-4" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "plan_type" },
                      },
                      [_vm._v("Location")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        id: "scheme_id",
                        label: "name",
                        options: _vm.branches,
                        reduce: (option) => option.id,
                        clearable: false,
                      },
                      model: {
                        value: _vm.form.branch_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "branch_id", $$v)
                        },
                        expression: "form.branch_id",
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("branch_id")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("branch_id")),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-12 form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "plan_type" } },
                    [_vm._v("Conflicts of Interest")]
                  ),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.conflicts_of_interest,
                        expression: "form.conflicts_of_interest",
                      },
                    ],
                    staticClass: "form-control",
                    domProps: { value: _vm.form.conflicts_of_interest },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.form,
                          "conflicts_of_interest",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("conflicts_of_interest")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(
                            _vm.form.getError("conflicts_of_interest")
                          ),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-8 mx-auto mt-4" }, [
                  _c("div", { staticClass: "form-group mb-3" }, [
                    _c("div", { staticClass: "mb-2" }, [
                      _c("label", { staticClass: "form-check form-switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.pays_tax,
                              expression: "form.pays_tax",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.form.pays_tax)
                              ? _vm._i(_vm.form.pays_tax, null) > -1
                              : _vm.form.pays_tax,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.form.pays_tax,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "pays_tax",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "pays_tax",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form, "pays_tax", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "form-check-label" }, [
                          _vm._v("Pays tax"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.form.pays_tax
                    ? _c(
                        "div",
                        { staticClass: "form-group mb-3" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "paye_type" },
                            },
                            [_vm._v("Tax Setup")]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              id: "paye_type",
                              options: _vm.PAYEOptions,
                              label: "name",
                              clearable: false,
                              reduce: (option) => option.value,
                            },
                            model: {
                              value: _vm.form.payee_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "payee_type", $$v)
                              },
                              expression: "form.payee_type",
                            },
                          }),
                          _vm._v(" "),
                          _vm.form.errors.has("payee_type")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.form.errors.get("payee_type")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.pays_tax
                    ? _c("div", { staticClass: "form-group mb-3" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "kra_pin" },
                          },
                          [_vm._v("Kra Pin")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.kra_pin,
                              expression: "form.kra_pin",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "name", type: "text" },
                          domProps: { value: _vm.form.kra_pin },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "kra_pin", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.form.hasError("kra_pin")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.form.getError("kra_pin")
                                ),
                              },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mb-3" }, [
                    _c("div", { staticClass: "mb-2" }, [
                      _c("label", { staticClass: "form-check form-switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.pays_shif,
                              expression: "form.pays_shif",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.form.pays_shif)
                              ? _vm._i(_vm.form.pays_shif, null) > -1
                              : _vm.form.pays_shif,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.form.pays_shif,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "pays_shif",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "pays_shif",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form, "pays_shif", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "form-check-label" }, [
                          _vm._v("Pays SHIF"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.form.pays_shif
                    ? _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "kra_pin" },
                          },
                          [_vm._v("SHIF NUMBER")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.shif_no,
                              expression: "form.shif_no",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "name", type: "text" },
                          domProps: { value: _vm.form.shif_no },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "shif_no", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.form.hasError("shif_no")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.form.getError("shif_no")
                                ),
                              },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mb-3" }, [
                    _c("div", { staticClass: "mb-2" }, [
                      _c("label", { staticClass: "form-check form-switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.pays_nssf,
                              expression: "form.pays_nssf",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.form.pays_nssf)
                              ? _vm._i(_vm.form.pays_nssf, null) > -1
                              : _vm.form.pays_nssf,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.form.pays_nssf,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "pays_nssf",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "pays_nssf",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form, "pays_nssf", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "form-check-label" }, [
                          _vm._v("Pays NSSF"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.form.pays_nssf
                    ? _c("div", { staticClass: "form-group mb-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "kra_pin" },
                          },
                          [_vm._v("NSSF NUMBER")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.nssf_no,
                              expression: "form.nssf_no",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "name", type: "text" },
                          domProps: { value: _vm.form.nssf_no },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "nssf_no", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.form.hasError("nssf_no")
                          ? _c("span", {
                              staticClass: "text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.form.getError("nssf_no")
                                ),
                              },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mb-2" }, [
                    _c("div", { staticClass: "mb-2" }, [
                      _c("label", { staticClass: "form-check form-switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.createLogin,
                              expression: "form.createLogin",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.form.createLogin)
                              ? _vm._i(_vm.form.createLogin, null) > -1
                              : _vm.form.createLogin,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.form.createLogin,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "createLogin",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "createLogin",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form, "createLogin", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "form-check-label" }, [
                          _vm._v("Create Login Details"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.form.createLogin
                    ? _c("fieldset", { staticClass: "form-fieldset mt-3" }, [
                        _c("div", { staticClass: "text-center fw-bold my-3" }, [
                          _vm._v("Employee Login Details"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mb-3" }, [
                          _c("label", { staticClass: "form-label required" }, [
                            _vm._v("Username"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.username,
                                expression: "form.username",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "name", type: "text" },
                            domProps: { value: _vm.form.username },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "username",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.form.hasError("username")
                            ? _c("span", {
                                staticClass: "text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.form.getError("username")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-lg-6" }, [
                            _c("div", { staticClass: "mb-3" }, [
                              _c(
                                "label",
                                { staticClass: "form-label required" },
                                [_vm._v("Password")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.password,
                                    expression: "form.password",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { id: "name", type: "password" },
                                domProps: { value: _vm.form.password },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.form,
                                      "password",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.form.hasError("password")
                                ? _c("span", {
                                    staticClass: "text-danger",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.form.getError("password")
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-lg-6" }, [
                            _c("div", { staticClass: "mb-3" }, [
                              _c(
                                "label",
                                { staticClass: "form-label required" },
                                [_vm._v("Confirm Password")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.cpassword,
                                    expression: "form.cpassword",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { id: "name", type: "password" },
                                domProps: { value: _vm.form.cpassword },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.form,
                                      "cpassword",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.form.hasError("cpassword")
                                ? _c("span", {
                                    staticClass: "text-danger",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.form.getError("cpassword")
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group mb-2" }, [
                            _c("div", { staticClass: "mb-2" }, [
                              _c(
                                "label",
                                { staticClass: "form-check form-switch" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.activated,
                                        expression: "form.activated",
                                      },
                                    ],
                                    staticClass: "form-check-input",
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(_vm.form.activated)
                                        ? _vm._i(_vm.form.activated, null) > -1
                                        : _vm.form.activated,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.form.activated,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.form,
                                                "activated",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.form,
                                                "activated",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.form, "activated", $$c)
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "form-check-label" },
                                    [_vm._v("enabled")]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary me-3",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("\n                Submit\n              ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: {
                        disabled: _vm.form.processing || _vm.form.successful,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.goBack.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n                Cancel\n              ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mb-4" }, [
      _c("h1", { staticClass: "fs-1" }, [
        _vm._v("\n          Employee Registration\n        "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "text-muted" }, [
        _vm._v("Complete the form below to register a new employee"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }