<script>
import payrollDeductions from './payrollDeductions.vue';
import payrollAllowances from "./payrollAllowances.vue";
export default {
  components: {
    payrollDeductions,
    payrollAllowances
  },
  props: {
    deductions:{
      type:Array,
      default: function () {
        return []
      },
    },
      employees: {
        type:Array
      },
    allowances:{
      type:Array,
      default: function () {
        return []
      },
    },
      permissions:{
      type:Array,
      default: function () {
        return []
      },
    }
  },

  data () {
    return {}
  },

}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-tabs active-nav-item-class="bg-white text-indigo" lazy nav-class="mb-2">
          <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
            <template #title>
              <i class="uil uil-spinner-alt me-2" />Payroll Deductions
            </template>

            <payroll-deductions  :permissions="permissions" :employees="employees" :deductions="deductions"></payroll-deductions>

          </b-tab>
          <b-tab title-item-class="fs-3" title-link-class="px-4 py-2">
            <template #title>
              <i class="uil uil-check-circle me-2" />Payroll Allowances
            </template>
            <payroll-allowances :permissions="permissions" :employees="employees" :allowances="allowances"></payroll-allowances>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
