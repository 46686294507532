import { render, staticRenderFns } from "./Rejected.vue?vue&type=template&id=5c005cf1&"
import script from "./Rejected.vue?vue&type=script&lang=js&"
export * from "./Rejected.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bismarck/Documents/software/hrm_ecobiz_dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c005cf1')) {
      api.createRecord('5c005cf1', component.options)
    } else {
      api.reload('5c005cf1', component.options)
    }
    module.hot.accept("./Rejected.vue?vue&type=template&id=5c005cf1&", function () {
      api.rerender('5c005cf1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/pages/employee/leave/Rejected.vue"
export default component.exports