<script>
import { defineComponent } from 'vue'
import { toPrice } from '@plugins/money'

export default defineComponent({
  name: 'Contributions',
  props: {
    employee: {
      type: Object,
      default: () => [],
    },
    permissions: {
      type: Array,
      default: () => [],
    },
    contributions: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {

      fields: [
        {
          name: 'contribution.name',
          title: 'Contribution',
          titleClass: ' text-start pe-3',
          dataClass: ' text-start pe-3',
          width: '8%',
        },
        {
          name: 'start_date',
          title: 'Start Date',
          titleClass: ' text-start pe-3',
          dataClass: ' text-start pe-3',
          width: '8%',
        },
        {
          name: 'end_date',
          title: 'End Date',
          titleClass: ' text-start pe-3',
          dataClass: ' text-start pe-3',
          width: '8%',
        },

        {
          name: 'amount',
          title: 'Amount',
            callback: function (value) {
                if (value < 0) {
                    return '(' + toPrice(Math.abs((value * 0.01))).toFormat('0,0.00') + ')'
                }
                return toPrice((value * 0.01)).toFormat('0,0.00')
            },
          titleClass: 'text-end pe-3',
          dataClass: 'text-end pe-3',
          width: '10%',
        },
        {
          name: '__slot:actions',
          dataClass: 'txt-center',
          width: '5%',
        },
      ],

      employeeContributions: [],

      form: new window.Form({
        id: null,
        employee_payroll_id: this.employee.id,
        contribution_id: '',
        start_date: '',
        end_date: '',
        amount: null,
        continuous: false
      }),

      enableForm: true,

      reverseForm: new window.Form({
        comments: '',
        detail_id: '',
        source: '1',
      }),

    }
  },

  watch: {
    'form.continuous':function (value){
      if(value){
        this.form.end_date = null;
      }
    }
  },

  mounted () {
    this.retrieveEmployeeConributions()
  },

  methods: {
    retrieveEmployeeConributions () {
      this.$httpClient.get('/payroll/contributions/' + this.employee.hashid)
          .then(({ data }) => {
            this.employeeContributions = data;
          }).catch(() => {
        this.$toast.error('There was a problem retrieving the Employee contributions')
      })
    },

    openContributionModal () {
      this.$bvModal.show('contribution-modal')
    },


    addContribution () {
      this.form.post('/payroll/contributions')
          .then(() => {
            this.$toast.success('Contribution has been added')
              this.$bvModal.hide('contribution-modal')
            this.cleanUp()
          })
          .catch((error) => {
            this.form.processing = false
            this.form.errors.record(error.response.data.errors)
          })
    },
    editContribution(data){
      this.form.populate(data)
        this.form.amount = (data.amount * 0.01)
      this.$bvModal.show('edit-contribution-modal')
    },
    updateContribution(){
      this.form.patch('/payroll/contributions/' + this.form.id)
          .then(() => {
            this.$toast.success('The changes have been saved')
            this.cleanUp()
            this.$bvModal.hide('edit-contribution-modal')
            this.vuetable.reload()


          })
          .catch()
    },
    deleteContribution (rowData){
      this.form.id = rowData.id
      this.$toast.question('Are you sure?', 'You want to delete').then(() => {
        this.$httpClient.delete('/payroll/contributions/' + this.form.id )
        .then((error) => {
          this.$toast.success('deleted')
          this.cleanUp()
          this.vuetable.reload()
        }).catch((error) => {
          this.$toast.error(error.response.data)
        })


      })
    },
    cleanUp () {
      this.form.reset()
      this.retrieveEmployeeConributions()
    },

  },

})
</script>

<template>

  <div>

    <b-modal
        id="contribution-modal"
        body-class="px-4"
        content-class="bg-white rounded-3"
        footer-class="d-flex justify-content-between bg-muted-lt border-top"
        hide-header-close
        modal-class="modal-blur"
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        title="Contribution Form"
        @hidden="cleanUp"
    >
      <div class="form-group mb-4">
        <label class="form-label" for="item_id">Contribution</label>
        <v-select
            id="item_id"
            v-model="form.contribution_id"
            :clearable="false"
            :reduce="(option) => option.id"
            :options="contributions"
            label="name"
        >

        </v-select>
        <span
            v-if="form.errors.has('allowance_id')"
            class="text-danger"
            v-text="form.errors.first('allowance_id')"
        />
      </div>
      <div class="form-group mb-4">
        <div class="form-group">
          <label for="" class="form-label">Amount</label>
          <medic-money
              :value="form.amount"
              @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

          <div class="text-danger"
               v-if="form.hasError('amount')"
               v-text="form.getError('amount')">
          </div>
        </div>
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Start Date</label>
        <div>
          <date-picker
              name="date_of_birth"
              formclass="form-control bg-white"
              :value="form.start_date"
              :max-date="false"
              @on-change="(dateOb, dateStr) => {
                    form.start_date = dateStr
                  }"
          />

          <span
              v-if="form.hasError('start_date')"
              class="text-danger"
              v-text="form.getError('start_date')"
          />
        </div>
      </div>
      <div class="mb-3">
        <div class="d-flex gap-2">
          <input id="continuous" v-model="form.continuous" class="form-check-input" type="checkbox">
          <label class="form-label" for="continuous">Continuous</label>
        </div>

        <span
            v-if="form.hasError('continuous')"
            class="text-danger"
            v-text="form.getError('continuous')"
        />
      </div>
      <div v-if="!form.continuous" class="form-group mb-4">
        <label class="form-label">End Date</label>
        <div>
          <date-picker
              name="date_of_birth"
              formclass="form-control bg-white"
              :value="form.end_date"
              :max-date="false"
              @on-change="(dateOb, dateStr) => {
                    form.end_date = dateStr
                  }"
          />

          <span
              v-if="form.hasError('end_date')"
              class="text-danger"
              v-text="form.getError('end_date')"
          />
        </div>
      </div>
      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green"/>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button
            :disabled="form.processing" class="btn btn-primary"
            type="submit"
            @click="addContribution"
        >
          Submit
        </button>
      </template>
    </b-modal>
    <b-modal
        id="edit-contribution-modal"
        body-class="px-4"
        content-class="bg-white rounded-3"
        footer-class="d-flex justify-content-between bg-muted-lt border-top"
        hide-header-close
        modal-class="modal-blur"
        no-close-on-backdrop
        no-close-on-esc
        no-enforce-focus
        title="Edit Contribution Form"
        @hidden="cleanUp"
    >
      <div class="form-group mb-4">
        <label class="form-label" for="item_id">Contribution</label>
        <v-select
            id="item_id"
            v-model="form.contribution_id"
            :clearable="false"
            :reduce="(option) => option.id"
            :options="contributions"
            label="name"
        >

        </v-select>
        <span
            v-if="form.errors.has('allowance_id')"
            class="text-danger"
            v-text="form.errors.first('allowance_id')"
        />
      </div>
      <div class="form-group mb-4">
        <div class="form-group">
          <label for="" class="form-label">Amount</label>
          <medic-money
              :value="form.amount"
              @input="(value) => {
                                            form.amount = value
                                        }"></medic-money>

          <div class="text-danger"
               v-if="form.hasError('amount')"
               v-text="form.getError('amount')">
          </div>
        </div>
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Start Date</label>
        <div>
          <date-picker
              name="date_of_birth"
              formclass="form-control bg-white"
              :value="form.start_date"
              :max-date="false"
              @on-change="(dateOb, dateStr) => {
                    form.start_date = dateStr
                  }"
          />

          <span
              v-if="form.hasError('start_date')"
              class="text-danger"
              v-text="form.getError('start_date')"
          />
        </div>
      </div>
      <div class="mb-3">
        <div class="d-flex gap-2">
          <input id="continuous" v-model="form.continuous" class="form-check-input" type="checkbox">
          <label class="form-label" for="continuous">Continuous</label>
        </div>

        <span
            v-if="form.hasError('continuous')"
            class="text-danger"
            v-text="form.getError('continuous')"
        />
      </div>
      <div v-if="!form.continuous" class="form-group mb-4">
        <label class="form-label">End Date</label>
        <div>
          <date-picker
              name="date_of_birth"
              formclass="form-control bg-white"
              :value="form.end_date"
              :max-date="false"
              @on-change="(dateOb, dateStr) => {
                    form.end_date = dateStr
                  }"
          />

          <span
              v-if="form.hasError('end_date')"
              class="text-danger"
              v-text="form.getError('end_date')"
          />
        </div>
      </div>
      <div v-if="form.processing" class="progress mb-2">
        <div class="progress">
          <div class="progress-bar progress-bar-indeterminate bg-green"/>
        </div>
      </div>

      <template #modal-footer="{cancel}">
        <button :disabled="form.processing" class="btn" type="button" @click.prevent="cancel">
          Close
        </button>

        <button
            :disabled="form.processing" class="btn btn-primary"
            type="submit"
            @click="updateContribution"
        >
          Submit
        </button>
      </template>
    </b-modal>


    <div class="card rounded-3 mb-5 ">
      <div class="card-header">
        <div class="">
          <h2 class="card-title mb-0 fw-bold">
            Contributions
          </h2>
        </div>

        <div class="card-actions">
          <div class="btn-list">
            <button class="btn btn-primary me-4" @click.prevent="openContributionModal">
              <i class="uil uil-plus me-1"/> Add Contribution
            </button>
          </div>
        </div>
      </div>

      <VueTable
          ref="table"
          :api-mode="false"
          :data="employeeContributions"
          :fields="fields"
          :load-on-start="false"
          :show-pagination="false"
          :show-per-page="false"
      >

        <template #actions="props">
          <div class="dropdown">
            <button
                class="btn align-text-center py-1"
                data-bs-toggle="dropdown"
            >
              <i class="uil uil-ellipsis-h"/>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <div class="dropdown-divider"/>
              <a class="dropdown-item" href="#" @click="editContribution(props.rowData)">
              <i class="uil uil-pen fs-3 me-2"/> Edit
            </a>
              <a class="dropdown-item text-danger" href="#" @click="deleteContribution(props.rowData)">
                <i class="uil  uil-trash-alt me-2"/> Delete
            </a>
            </div>
          </div>
        </template>
      </VueTable>
    </div>

  </div>
</template>

<style scoped>

</style>
