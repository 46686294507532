var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "page-bdy" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row mb-4" }, [
            _c("div", { staticClass: "col-md-8" }, [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.updateGreeting()) },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-md-4 d-flex justify-content-end align-items-end",
              },
              [
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-secondary rounded",
                      on: { click: _vm.showCalender },
                    },
                    [
                      _c("span", { staticClass: "me-2" }, [
                        _c(
                          "svg",
                          {
                            staticClass: "bi bi-calendar-event",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "16",
                              height: "16",
                              fill: "currentColor",
                              viewBox: "0 0 16 16",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z",
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" Calender"),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row row-cards mb-4" }, [
            _c("div", { staticClass: "col-xxl-3 col-md-6" }, [
              _c("div", { staticClass: "card info-card sales-card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("Total Employee"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-icon rounded-circle d-flex align-items-center justify-content-center",
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "bi bi-people",
                            staticStyle: { size: "32px" },
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "16",
                              height: "16",
                              fill: "currentColor",
                              viewBox: "0 0 16 16",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ps-3" }, [
                      _c("h6", [_vm._v(_vm._s(_vm.noofemployees))]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xxl-3 col-md-6" }, [
              _c("div", { staticClass: "card info-card sales-card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("Turnover Rate"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-icon rounded-circle d-flex align-items-center justify-content-center",
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "bi bi-people",
                            staticStyle: { size: "32px" },
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "16",
                              height: "16",
                              fill: "currentColor",
                              viewBox: "0 0 16 16",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ps-3" }, [
                      _c("h6", [_vm._v(_vm._s(_vm.turnover.employeeWhoLeft))]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "text-success small pt-1 fw-bold" },
                        [_vm._v(_vm._s(_vm.turnover.rate) + "%")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "text-muted small pt-2 ps-1" },
                        [_vm._v("Than last year")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xxl-3 col-md-6" }, [
              _c("div", { staticClass: "card info-card sales-card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h5", { staticClass: "card-title" }, [_vm._v("Payrolls")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-icon rounded-circle d-flex align-items-center justify-content-center",
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticStyle: { size: "12px" },
                            attrs: {
                              width: "24",
                              height: "24",
                              xmlns: "http://www.w3.org/2000/svg",
                              "data-name": "Layer 1",
                              viewBox: "0 0 24 24",
                              id: "coins",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "currentColor",
                                d: "M21.22,12A3,3,0,0,0,22,10a3,3,0,0,0-3-3H13.82A3,3,0,0,0,11,3H5A3,3,0,0,0,2,6a3,3,0,0,0,.78,2,3,3,0,0,0,0,4,3,3,0,0,0,0,4A3,3,0,0,0,2,18a3,3,0,0,0,3,3H19a3,3,0,0,0,2.22-5,3,3,0,0,0,0-4ZM11,19H5a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Zm0-4H5a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Zm0-4H5A1,1,0,0,1,5,9h6a1,1,0,0,1,0,2Zm0-4H5A1,1,0,0,1,5,5h6a1,1,0,0,1,0,2Zm8.69,11.71A.93.93,0,0,1,19,19H13.82a2.87,2.87,0,0,0,0-2H19a1,1,0,0,1,1,1A1,1,0,0,1,19.69,18.71Zm0-4A.93.93,0,0,1,19,15H13.82a2.87,2.87,0,0,0,0-2H19a1,1,0,0,1,1,1A1,1,0,0,1,19.69,14.71Zm0-4A.93.93,0,0,1,19,11H13.82a2.87,2.87,0,0,0,0-2H19a1,1,0,0,1,1,1A1,1,0,0,1,19.69,10.71Z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ps-3" }, [
                      _c("h6", [_vm._v("ksh " + _vm._s(_vm.payroll.netDeff))]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "text-success small pt-1 fw-bold" },
                        [_vm._v(_vm._s(_vm.payroll.netIncPe) + "%")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "text-muted small pt-2 ps-1" },
                        [_vm._v("Than last month")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xxl-3 col-md-6" }, [
              _c("div", { staticClass: "card info-card sales-card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h5", { staticClass: "card-title" }, [_vm._v("On leave")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-icon rounded-circle d-flex align-items-center justify-content-center",
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "bi bi-people",
                            staticStyle: { size: "32px" },
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "16",
                              height: "16",
                              fill: "currentColor",
                              viewBox: "0 0 16 16",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ps-3" }, [
                      _c("h6", [_vm._v(_vm._s(_vm.onleave))]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row row-deck row-cards" }, [
            _c("div", { staticClass: "col-md-6 col-lg-4 mb-3" }, [
              _c(
                "div",
                { staticClass: "card section" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex justify-content-between align-items-center",
                    },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "d-flex gap-2 align-items-center" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Date: "),
                          ]),
                          _vm._v(" "),
                          _c("date-picker", {
                            attrs: {
                              name: "date_of_birth",
                              formclass: "form-control bg-white",
                              value: _vm.scheduleFilter.date,
                            },
                            on: {
                              "on-change": (dateOb, dateStr) => {
                                _vm.scheduleFilter.date = dateStr
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tabs",
                    {
                      attrs: {
                        "active-nav-item-class": "bg-white text-indigo",
                        lazy: "",
                        "nav-class": "mb-2 mx-1",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            "title-item-class": "fs-3",
                            "title-link-class": "px-4 py-2",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c("i", {
                                    staticClass: "uil uil-spinner-alt me-2",
                                  }),
                                  _vm._v("Meetings\n                  "),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("VueTable", {
                            ref: "table2",
                            attrs: {
                              "per-page": 5,
                              "api-url": "/datatable/interview",
                              fields: _vm.interviewFields,
                              "append-params": _vm.interviewParams,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "interview_title",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex py-1 align-items-center",
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "avatar me-2",
                                          style: {
                                            backgroundImage: `url('/storage/profile/default.jpg')`,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "flex-fill" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-medium d-flex justify-content-between",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.rowData.application
                                                        .applicant.name
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "small" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.rowData
                                                          .date_and_time
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-muted small",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "text-muted",
                                                    attrs: {
                                                      href:
                                                        "mailto:" +
                                                        props.rowData
                                                          .application.applicant
                                                          .email,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.rowData
                                                          .application.applicant
                                                          .email
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "text-secondary" },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "text-reset",
                                                    attrs: { href: "#" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.rowData
                                                          .application.vacancy
                                                          .name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            "title-item-class": "fs-3",
                            "title-link-class": "px-4 py-2",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c("i", {
                                    staticClass: "uil uil-check-circle me-2",
                                  }),
                                  _vm._v("Cases\n                  "),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("VueTable", {
                            ref: "table2",
                            attrs: {
                              "per-page": 5,
                              "api-url": "/datatable/disciplinary-cases",
                              fields: _vm.unsolvedCasesFields,
                              "append-params": _vm.unsolvedCasesParams,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "employee.name",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex py-1 align-items-center",
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "avatar me-2",
                                          style: {
                                            backgroundImage: `url(${_vm.backgroundImageUrl(
                                              props.rowData.employee
                                            )})`,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "flex-fill" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-medium",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    props.rowData.employee.name
                                                  ) + " "
                                                ),
                                                _vm.hasLeaveEnd(
                                                  props.rowData.resolution_date
                                                )
                                                  ? _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-warning",
                                                        attrs: {
                                                          title:
                                                            "Case to be resolved today",
                                                        },
                                                      },
                                                      [_vm._v(" | Today")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.checkLeaveEnd(
                                                  props.rowData.resolution_date
                                                )
                                                  ? _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-warning",
                                                      },
                                                      [_vm._v("| Tomorrow")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-muted small",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "text-muted",
                                                    attrs: {
                                                      href:
                                                        "mailto:" +
                                                        props.rowData.employee
                                                          .email,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.rowData.employee
                                                          .email
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "resolution_date",
                                fn: function (props) {
                                  return [
                                    _c("div", { staticClass: "text-reset" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatDate(
                                            props.rowData.resolution_date
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            "title-item-class": "fs-3",
                            "title-link-class": "px-4 py-2",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c("i", {
                                    staticClass: "uil uil-closed-circle me-2",
                                  }),
                                  _vm._v("Reviews\n                  "),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("VueTable", {
                            ref: "table2",
                            attrs: {
                              "per-page": 5,
                              "api-url": "/datatable/performance",
                              fields: _vm.workPerformanceFields,
                              "append-params": _vm.workPerformanceParams,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "employee.name",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex py-1 align-items-center",
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "avatar me-2",
                                          style: {
                                            backgroundImage: `url(${_vm.backgroundImageUrl(
                                              props.rowData.employee
                                            )})`,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "flex-fill" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-medium d-flex justify-content-between",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      title:
                                                        "employee name and group",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.rowData.employee
                                                          .name
                                                      ) +
                                                        " | " +
                                                        _vm._s(
                                                          props.rowData
                                                            .job_group.name
                                                        )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "small",
                                                    attrs: {
                                                      title: "due date",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatDate(
                                                          props.rowData.due_date
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-muted small",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "text-muted",
                                                    attrs: {
                                                      href:
                                                        "mailto:" +
                                                        props.rowData.employee
                                                          .email,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.rowData.employee
                                                          .email
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    props.rowData.employee.supervisor
                                      ? _c(
                                          "div",
                                          { staticClass: "text-muted" },
                                          [_vm._v("Supervisor")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    props.rowData.employee.supervisor
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex py-1 align-items-center",
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "avatar me-2",
                                              style: {
                                                backgroundImage: `url(${_vm.backgroundImageUrl(
                                                  props.rowData.employee
                                                    .supervisor
                                                )})`,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "flex-fill" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-weight-medium",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.rowData.employee
                                                          .supervisor.name
                                                      ) +
                                                        "\n\n                          "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-muted small",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "text-muted",
                                                        attrs: {
                                                          href:
                                                            "mailto:" +
                                                            props.rowData
                                                              .employee
                                                              .supervisor.email,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.rowData
                                                              .employee
                                                              .supervisor.email
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                              {
                                key: "employee_leave_type.leave_type",
                                fn: function (props) {
                                  return [
                                    _c("div", { staticClass: "d-flex" }, [
                                      _c("div", [_vm._v("sddfd")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            props.employee_leave_type.leave_type
                                              .name
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-8" }, [
              _c("div", { staticClass: "card section" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "pb-2" }, [
                  _c("div", { staticClass: "col-lg-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.employeeParams.filter.search,
                          expression: "employeeParams.filter.search",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { placeholder: "Search", type: "text" },
                      domProps: { value: _vm.employeeParams.filter.search },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.employeeParams.filter,
                              "search",
                              $event.target.value
                            )
                          },
                          _vm.searchEmployees,
                        ],
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    { staticClass: "table-responsive" },
                    [
                      _c("VueTable", {
                        ref: "tableEmployee",
                        attrs: {
                          "per-page": 7,
                          "api-url": "/datatable/employees",
                          fields: _vm.employeeFields,
                          "append-params": _vm.employeeParams,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "name",
                            fn: function (props) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex py-1 align-items-center",
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "avatar me-2",
                                      style: {
                                        backgroundImage: `url(${_vm.backgroundImageUrl(
                                          props.rowData
                                        )})`,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "flex-fill" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-medium d-flex justify-content-between",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(props.rowData.name)),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "small",
                                              attrs: { title: "Job title" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(props.rowData.job_title)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-muted small" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-muted",
                                              attrs: {
                                                href:
                                                  "mailto:" +
                                                  props.rowData.email,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(props.rowData.email)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-4 g-3" }, [
              _c("div", { staticClass: "col-md-12 col-lg-8" }, [
                _c(
                  "div",
                  { staticClass: "card section" },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "b-tabs",
                      {
                        attrs: {
                          "active-nav-item-class": "bg-white text-indigo",
                          lazy: "",
                          "nav-class": "mb-2",
                        },
                      },
                      [
                        _c(
                          "b-tab",
                          {
                            attrs: {
                              "title-item-class": "fs-3",
                              "title-link-class": "px-4 py-2",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c("i", {
                                      staticClass: "uil uil-spinner-alt me-2",
                                    }),
                                    _vm._v(
                                      "Leave Request\n                    "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "table-responsive" },
                              [
                                _c("VueTable", {
                                  ref: "leaveTable",
                                  attrs: {
                                    "per-page": 7,
                                    "api-url": "/datatable/leave-requests",
                                    fields: _vm.leaveFields,
                                    "append-params": _vm.leaveParams,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "employee_leave_type.employee.name",
                                      fn: function (props) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex py-1 align-items-center",
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "avatar me-2",
                                                style: {
                                                  backgroundImage: `url(${_vm.backgroundImageUrl(
                                                    props.rowData
                                                      .employee_leave_type
                                                      .employee
                                                  )})`,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "flex-fill" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "font-weight-medium d-flex justify-content-between",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.rowData
                                                              .employee_leave_type
                                                              .employee.name
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "small",
                                                          attrs: {
                                                            title: "Job title",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.rowData
                                                                .employee_leave_type
                                                                .employee
                                                                .job_title
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-muted small",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "text-muted",
                                                          attrs: {
                                                            href:
                                                              "mailto:" +
                                                              props.rowData
                                                                .employee_leave_type
                                                                .employee.email,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.rowData
                                                                .employee_leave_type
                                                                .employee.email
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          props.rowData.employee_leave_type
                                            .employee.supervisor
                                            ? _c(
                                                "div",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Supervisor")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          props.rowData.employee_leave_type
                                            .employee.supervisor
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex py-1 align-items-center",
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass: "avatar me-2",
                                                    style: {
                                                      backgroundImage: `url(${_vm.backgroundImageUrl(
                                                        props.rowData
                                                          .employee_leave_type
                                                          .employee.supervisor
                                                      )})`,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "flex-fill",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "font-weight-medium",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.rowData
                                                                .employee_leave_type
                                                                .employee
                                                                .supervisor.name
                                                            ) +
                                                              "\n\n                                            "
                                                          ),
                                                          props.rowData
                                                            .supervisor_approved
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "small text-success",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "| Has approved"
                                                                  ),
                                                                ]
                                                              )
                                                            : props.rowData
                                                                .supervisor_rejected
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "small text-warning",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "| Has rejected"
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "small text-warning",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Has not approved"
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-muted small",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "text-muted",
                                                              attrs: {
                                                                href:
                                                                  "mailto:" +
                                                                  props.rowData
                                                                    .employee_leave_type
                                                                    .employee
                                                                    .supervisor
                                                                    .email,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  props.rowData
                                                                    .employee_leave_type
                                                                    .employee
                                                                    .supervisor
                                                                    .email
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                    {
                                      key: "start_date",
                                      fn: function (props) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-content-between",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    title: "Leave type",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                             " +
                                                      _vm._s(
                                                        props.rowData
                                                          .employee_leave_type
                                                          .leave_type.name
                                                      ) +
                                                      "\n                          "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              props.rowData.days > 1
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          props.rowData.days
                                                        ) +
                                                        " days\n                          "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          props.rowData.days
                                                        ) +
                                                        " day\n                          "
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", [
                                            _c(
                                              "span",
                                              { staticClass: "small" },
                                              [
                                                _vm._v(
                                                  "Starting from " +
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        props.rowData.start_date
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "small" },
                                              [
                                                _vm._v(
                                                  "To " +
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        props.rowData.end_date
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          {
                            attrs: {
                              "title-item-class": "fs-3",
                              "title-link-class": "px-4 py-2",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c("i", {
                                      staticClass: "uil uil-check-circle me-2",
                                    }),
                                    _vm._v("On leave\n                    "),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _vm._v(" "),
                            _c("VueTable", {
                              ref: "leaveTable",
                              attrs: {
                                "per-page": 7,
                                "api-url": "/datatable/employee_on_leave",
                                fields: _vm.leaveFields,
                                "append-params": _vm.filterParams,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "employee_leave_type.employee.name",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex py-1 align-items-center",
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "avatar me-2",
                                            style: {
                                              backgroundImage: `url(${_vm.backgroundImageUrl(
                                                props.rowData
                                                  .employee_leave_type.employee
                                              )})`,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-fill" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-weight-medium d-flex justify-content-between",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.rowData
                                                          .employee_leave_type
                                                          .employee.name
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "small",
                                                      attrs: {
                                                        title: "Job title",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.rowData
                                                            .employee_leave_type
                                                            .employee.job_title
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-muted small",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "text-muted",
                                                      attrs: {
                                                        href:
                                                          "mailto:" +
                                                          props.rowData
                                                            .employee_leave_type
                                                            .employee.email,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.rowData
                                                            .employee_leave_type
                                                            .employee.email
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      props.rowData.employee_leave_type.employee
                                        .supervisor
                                        ? _c(
                                            "div",
                                            { staticClass: "text-muted" },
                                            [_vm._v("Supervisor")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      props.rowData.employee_leave_type.employee
                                        .supervisor
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex py-1 align-items-center",
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "avatar me-2",
                                                style: {
                                                  backgroundImage: `url(${_vm.backgroundImageUrl(
                                                    props.rowData
                                                      .employee_leave_type
                                                      .employee.supervisor
                                                  )})`,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "flex-fill" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "font-weight-medium",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.rowData
                                                            .employee_leave_type
                                                            .employee.supervisor
                                                            .name
                                                        ) +
                                                          "\n\n                              "
                                                      ),
                                                      props.rowData
                                                        .supervisor_approved
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "small text-success",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "| Has approved"
                                                              ),
                                                            ]
                                                          )
                                                        : props.rowData
                                                            .supervisor_rejected
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "small text-warning",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "| Has rejected"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "small text-warning",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Has not approved"
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-muted small",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "text-muted",
                                                          attrs: {
                                                            href:
                                                              "mailto:" +
                                                              props.rowData
                                                                .employee_leave_type
                                                                .employee
                                                                .supervisor
                                                                .email,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.rowData
                                                                .employee_leave_type
                                                                .employee
                                                                .supervisor
                                                                .email
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: "start_date",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-between",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { attrs: { title: "Leave type" } },
                                            [
                                              _vm._v(
                                                "\n                             " +
                                                  _vm._s(
                                                    props.rowData
                                                      .employee_leave_type
                                                      .leave_type.name
                                                  ) +
                                                  "\n                          "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          props.rowData.days > 1
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(props.rowData.days) +
                                                    " days\n                          "
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(props.rowData.days) +
                                                    " day\n                          "
                                                ),
                                              ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("span", { staticClass: "small" }, [
                                          _vm._v(
                                            "Starting from " +
                                              _vm._s(
                                                _vm.formatDate(
                                                  props.rowData.start_date
                                                )
                                              )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "small" }, [
                                          _vm._v(
                                            "To " +
                                              _vm._s(
                                                _vm.formatDate(
                                                  props.rowData.end_date
                                                )
                                              )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "card section" },
                  [
                    _c("apexchart", {
                      attrs: {
                        type: "line",
                        height: "350",
                        options: _vm.chartOptions,
                        series: _vm.series,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "xl",
            id: "calender-modal",
            title: "Calender",
            "hide-header-close": "",
            "footer-class": "d-flex justify-content-start",
            "modal-class": "modal-blur",
            "no-close-on-esc": "",
          },
        },
        [
          _c("Calender", {
            attrs: { isCalendarInitialized: _vm.calenderShow },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "section-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Schedule")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "section-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Employee")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "section-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Leave")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }