import { render, staticRenderFns } from "./Relief.vue?vue&type=template&id=ec7db95e&scoped=true&"
import script from "./Relief.vue?vue&type=script&lang=js&"
export * from "./Relief.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec7db95e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bismarck/Documents/software/hrm_ecobiz_dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ec7db95e')) {
      api.createRecord('ec7db95e', component.options)
    } else {
      api.reload('ec7db95e', component.options)
    }
    module.hot.accept("./Relief.vue?vue&type=template&id=ec7db95e&scoped=true&", function () {
      api.rerender('ec7db95e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/pages/payroll/Relief.vue"
export default component.exports