<script>
import { onActivated } from 'vue';


export default {
  props: {
    vacancies: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
    departments: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
    jobgroups: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
    employeetypes: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      employees: [],
      applicant: {},
      interview: {},
      fields: [
        {
          name: 'vacancy.name',
          title: 'Vacancy',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'applicant.name',
          title: 'Candidate',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'hiring_manager.name',
          title: 'Hiring Manager',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'application_date',
          title: 'Date of Application',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: 'status',
          title: 'Status',
          titleClass: 'font-weight-bold',
          dataClass: 'ps-3',
        },
        {
          name: '__slot:actions',
          width: '15%',
          titleClass: 'text-center',
          dataClass: 'text-right',
        },
      ],

      moreParams: {
        filter: {
          search: '',
        }
      },

      form: new window.Form({
        id: null,
        name: '',
        dob:'',
        gender:'',
        nationality:'',
        id_no:'',
        residence:'',
        phone: '',
        email: '',
        resume: '',
        vacancy_id: '',
      }),
      interviewForm: new window.Form({
        id: null,
        application_id: null,
        vacancy_id: null,
        interview_title: null,
        interviewer_id: null,
        date_and_time: null,
        notes: '',
      }),
        selectedApplicant:{},
        selectedApplication: {
            vacancy: {}
        },
      interviewResult: new window.Form({
        feedback: '',
        interPassed: false
      }),
      offerJob: new window.Form({
        application_id: null,
        payroll_number: null,
        name: '',
        dob: '',
        gender: '',
        email: '',
        phone: '',
        residence: '',
        department_id: null,
        employee_type: null,
        job_group_id: null,
        job_title:'',
        nationality:'',
        id_no:'',
        activated: true
      }),
    }
  },
  mounted () {
    this.retrieveEmployees()
  },
  computed: {
    vuetable() {
      return this.$refs.table.$refs.vuetable
    },
  },
  watch: {

  },
  methods: {
    retrieveEmployees () {
      this.$httpClient.get('/datatable/employees/')
        .then(({ data }) => {
          this.employees = data.data;
        }).catch(() => {
          this.$toast.error('There was a problem retrieving the Employees')
        })
    },
    deleteApplicationn(rowData){

      this.$toast.question('Are you sure?', 'You want to delete').then(() => {

        this.$httpClient.delete(`/recruitment/${rowData.id}`)
        .then((error) => {
          this.$toast.success('deleted')
          this.vuetable.reload()
        }).catch((error) => {
          this.$toast.error(error.response.data)
        })


      })
    },
    deleteFailedInterview(rowData){

      this.$toast.question('Are you sure?', 'You want to delete').then(() => {

        this.$httpClient.delete(`/recruitment/${rowData.id}`)
        .then((error) => {
          this.$toast.success('deleted')
          this.vuetable.reload()
        }).catch((error) => {
          this.$toast.error(error.response.data)
        })


      })
    },
    showCreateForm () {
      this.$bvModal.show('create-modal')
    },
    fetchScheduledInterview (applicationId){
      this.$httpClient.get(`/interview-scheduled/${applicationId}`)
      .then(({data}) => {
        this.interview = data;
      })
      .catch((error) => {
          this.$toast.error(error.response.data)
        })
    },
    submitInterview (){
      this.interviewResult.patch('interview-results/' + this.interview.application_id)
      .then((error) => {
          this.$toast.success('Applicant interview results submited')
          this.vuetable.reload()
          this.$bvModal.hide('interview-modal')
        }).catch((error) => {
          this.$toast.error(error.response.data)
        })
    },
    showUpdateForm (rowData) {
      this.selectedDepartment = rowData
      this.viewEditForm = true
    },

    createAssignLeave () {
      this.form.post('/recruitment')
        .then(() => {
          this.$bvModal.hide('create-modal');
          this.$toast.success('Application submitted successfully.');
          this.vuetable.reload();
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            this.form.errors.record(error.response.data.errors);
          } else {
            this.$toast.error(error.response.data)
          }
        }

        )
    },

    shortList(rowData) {
      this.$httpClient.patch(`/application/shortlist/${rowData.id}`)
        .then((error) => {
          this.$toast.success('Candidate short listed')
          this.vuetable.reload()
        }).catch((error) => {
          this.$toast.error(error.response.data)
        })
    },
    hire () {
      this.offerJob.post('/hire')
      .then(() => {
          this.$bvModal.hide('job-offer-modal');
          this.$toast.success('Hired! Please go to the newly hired employee\'s profile to complete details.');
          this.vuetable.reload();
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            this.offerJob.errors.record(error.response.data.errors);
          } else {
            this.$toast.error(error.response.data)
          }
        }
        )
    },
    showEditForm(rowData) {
      this.form.populate(rowData)
      this.form.id = rowData.id
      this.form.leave_type_id = rowData.employee_leave_type_id

      this.$bvModal.show('update-modal')
    },
    showInterviewScheduleForm (rowData) {
      this.applicant = rowData.applicant
      this.interviewForm.application_id = rowData.id
      this.interviewForm.interviewer_id = rowData.hiring_manager.id
      this.interviewForm.vacancy_id = rowData.vacancy_id

      this.$bvModal.show('interview-schedule-modal')
    },
    showJobOfferForm (rowData) {
      this.offerJob.application_id = rowData.id
      this.offerJob.name = rowData.applicant.name
      this.offerJob.email = rowData.applicant.email
      this.offerJob.phone = rowData.applicant.phone
      this.offerJob.dob = rowData.applicant.dob
      this.offerJob.gender = rowData.applicant.gender
      this.offerJob.residence = rowData.applicant.residence
      this.offerJob.department_id = rowData.vacancy.department_id
      this.offerJob.job_group_id = rowData.vacancy.job_group_id
      this.offerJob.nationality = rowData.applicant.nationality
      this.offerJob.job_title = rowData.vacancy.job_title
      this.offerJob.id_no = rowData.applicant.id_no
      this.$bvModal.show('job-offer-modal')
    },
    showInterviewForm (rowData) {
      this.fetchScheduledInterview(rowData.id)
      this.applicant = rowData.applicant
      this.interviewForm.application_id = rowData.id
      this.interviewForm.vacancy_id = rowData.vacancy_id
      this.interviewForm.interviewer_id = rowData.hiring_manager
      this.$bvModal.show('interview-modal')
    },
    scheduleInterview (){
      this.interviewForm.post('/schedule-interview')
        .then(() => {
          this.$toast.success('Interview has been scheduled.');
          this.$bvModal.hide('interview-schedule-modal')
          this.vuetable.reload();
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            this.form.errors.record(error.response.data.errors);
          } else {
            this.$toast.error(error.response.data)
          }
        }
        )
    },
    delRequest(rowData) {
      this.$httpClient.delete(`/user/leave-requests/${rowData.id}/delete`)

        .then((error) => {
          this.$toast.success('Request deleted')
          this.vuetable.reload()
        }).catch((error) => {
          this.$toast.error(error.response.data)
        })
    },
    editLeave() {
      this.form.patch('/user/leave-requests/' + this.form.id)
        .then(() => {
          this.vuetable.reload()
          this.$bvModal.hide('update-modal')
          this.$toast.success('The changes have been saved')
        })
        .catch()
    },
      viewApplication(rowData) {
          this.selectedApplicant = rowData.applicant
          this.selectedApplication = rowData
          // this.slit(this.selectedApplicant.skills)
          this.$bvModal.show('application')
      },
      rejectedOffer(rowData){
          this.$httpClient.patch(`/application/rejected-offer/${rowData.id}`)
              .then((error) => {
                  this.$toast.success('Job offer rejected')
                  this.vuetable.reload()
              }).catch((error) => {
              this.$toast.error(error.response.data)
          })
      },
    applyFilter() {
      this.vuetable.reload()
    },

    cleanUp() {
      this.form.reset()
    }
  },
}
</script>

<template>
  <div>
    <div class="card card-stacked">
      <div class="card-header">
        <div class="input-icon me-2">
          <span class="input-icon-addon">
            <i class="uil uil-search"></i>
          </span>
          <input type="text" class="form-control bg-muted-lt rounded-2" placeholder="Search application" @input="applyFilter"
            v-model="moreParams.filter.search">
        </div>

        <div class="card-actions">
          <!--          {{leave_types}}-->
          <button class="btn btn-primary" @click="showCreateForm">
            <i class="uil uil-plus me-1"></i>Add
          </button>
        </div>
      </div>
      <VueTable ref="table" api-url="/datatable/applications" :fields="fields" :append-params="moreParams">
        <template v-slot:actions="props">
          <div class="dropdown">
            <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
              <i class="uil uil-ellipsis-h"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item" href="#"
                   @click="viewApplication(props.rowData)">
                    view
                </a>
              <a v-if="props.rowData.status === 'Applied'" class="dropdown-item" href="#"
                @click="shortList(props.rowData)">
                 Short List
              </a>
              <a v-if="props.rowData.status === 'Short listed'" class="dropdown-item" href="#"
                @click="showInterviewScheduleForm(props.rowData)">
                 Schedule Interview
              </a>
              <a v-if="props.rowData.status === 'Interview passed'" class="dropdown-item" href="#"
                @click="showJobOfferForm(props.rowData)">
                 Offer a job
              </a>
                <a v-if="props.rowData.status === 'Interview passed'" class="dropdown-item" href="#"
                   @click="rejectedOffer(props.rowData)">
                    Rejected Offer
                </a>
              <a v-if="props.rowData.status === 'Interview Scheduled'" class="dropdown-item" href="#"
                @click="showInterviewForm(props.rowData)">
                interview
              </a>
              <a v-if="props.rowData.status === 'Applied'" class="dropdown-item" href="#"
                @click="showEditForm(props.rowData)">
               Decline
              </a>
              <a v-if="props.rowData.status === 'Offered                                                                                         '"
                class="dropdown-item" href="#" @click="showEditForm(props.rowData)">
               Hire
              </a>
<!--              <a v-if="props.rowData.status === 'Applied'" class="dropdown-item" href="#"-->
<!--                @click="showEditForm(props.rowData)">-->
<!--                <i class="uil uil-pen fs-3 me-2"></i> Edit-->
<!--              </a>-->
              <a v-if="props.rowData.status === 'Interview failed' || props.rowData.status === 'Rejected Offer'" class="dropdown-item text-danger" href="#"
                @click="deleteFailedInterview(props.rowData)">
                Del application
              </a>
              <a v-if="props.rowData.status === 'Applied'" class="dropdown-item text-danger" href="#"
                @click="deleteApplicationn(props.rowData)">
                 Del application
              </a>
            </div>
          </div>
        </template>
      </VueTable>
    </div>
    <!-- create modal -->
    <b-modal id="create-modal" title="Job Application" size="lg" hide-header-close footer-class="d-flex justify-content-start"
      modal-class="modal-blur">
      <form @submit.prevent="createAssignLeave" id="createForm">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Applicant name</label>
              <input type="text" class="form-control" id="name" v-model="form.name">

              <span v-if="form.hasError('name')" class="text-danger" v-text="form.getError('name')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Applicant email</label>
              <input type="email" class="form-control" id="email" v-model="form.email">

              <span v-if="form.hasError('email')" class="text-danger" v-text="form.getError('email')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Death Of Birth</label>
              <date-picker name="date_of_birth" :value="form.dob" :max-date="false" @on-change="(dateOb, dateStr) => {
              form.dob = dateStr
            }" />

              <span v-if="form.hasError('dob')" class="text-danger" v-text="form.getError('dob')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Gender</label>
              <v-select id="gender_id" v-model="form.gender" name="gender" :options="['male','female']" label="name"
                        :reduce="option => option" :clearable="false">

              </v-select>

              <span v-if="form.hasError('gender')" class="text-danger" v-text="form.getError('gender')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Nationality</label>
              <input type="text" class="form-control" id="nationality" v-model="form.nationality">

              <span v-if="form.hasError('nationality')" class="text-danger" v-text="form.getError('nationality')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">ID no</label>
              <input type="text" class="form-control" id="email" v-model="form.id_no">

              <span v-if="form.hasError('id_no')" class="text-danger" v-text="form.getError('id_no')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Residence</label>
              <input type="text" class="form-control" id="email" v-model="form.residence">

              <span v-if="form.hasError('residence')" class="text-danger" v-text="form.getError('residence')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Applicant phone</label>
              <input type="text" class="form-control" id="phone" v-model="form.phone">

              <span v-if="form.hasError('phone')" class="text-danger" v-text="form.getError('phone')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Vacancy</label>
              <v-select id="gender_id" v-model="form.vacancy_id" name="vacancy_id" :options="vacancies" label="name"
                :reduce="option => option.id" :clearable="false">

              </v-select>
              <span v-if="form.hasError('vacancy_id')" class="text-danger" v-text="form.getError('vacancy_id')">
              </span>
            </div>
          </div>
        </div>


        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>

      </form>

      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="createAssignLeave">
          Submit
        </button>
      </template>

    </b-modal>
    <!-- edit modal -->
    <b-modal id="update-modal" title="Edit Leave" hide-header-close size="lg"
      footer-class="d-flex justify-content-start" modal-class="modal-blur" no-close-on-esc>
      <form @submit.prevent="editLeave" id="createForm">
        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Leave Type</label>
              <v-select id="gender_id" v-model="form.employee_leave_type_id" name="leave_type_id" :options="leave_types"
                label="leave_type_id" :reduce="option => option.id" :clearable="false">
                <template v-slot:option="option">
                  <li class="media mb-1">
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <span>{{ option.leave_type.name }}</span>
                      </div>
                    </div>
                  </li>
                </template>
                <template v-slot:selected-option="option">
                  <li class="media mb-1">
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <span>{{ option.leave_type.name }}</span>
                      </div>
                    </div>
                  </li>
                </template>
              </v-select>
              <span v-if="form.hasError('leave_type_id')" class="text-danger" v-text="form.getError('leave_type_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Start Date</label>
              <date-picker name="date_of_birth" :value="form.start_date" :max-date="false" @on-change="(dateOb, dateStr) => {
              form.start_date = dateStr
            }" />

              <span v-if="form.hasError('start_date')" class="text-danger" v-text="form.getError('start_date')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">End Date</label>
              <date-picker name="date_of_birth" :value="form.end_date" :max-date="false" @on-change="(dateOb, dateStr) => {
              form.end_date = dateStr
            }" />

              <span v-if="form.hasError('end_date')" class="text-danger" v-text="form.getError('end_date ')">
              </span>
            </div>
          </div>
          <!--          <div class="col-lg-6">-->
          <!--            <div class="mb-3">-->
          <!--              <label for="name" class="form-label">Days</label>-->
          <!--              <input type="text" class="form-control" id="name" v-model="form.days">-->

          <!--              <span-->
          <!--                  v-if="form.hasError('days')"-->
          <!--                  class="text-danger"-->
          <!--                  v-text="form.getError('days')">-->
          <!--                    </span>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Return Date</label>
              <date-picker name="date_of_birth" :value="form.return_date" :max-date="futureDate" @on-change="(dateOb, dateStr) => {
              form.return_date = dateStr
            }" />

              <span v-if="form.hasError('return_date')" class="text-danger" v-text="form.getError('return_date ')">
              </span>
            </div>
          </div>
        </div>


        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>

      </form>
      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="editLeave">
          Submit
        </button>

      </template>
    </b-modal>
    <!-- set interview schedule modal -->
    <b-modal id="interview-schedule-modal" title="Schedule an Interview" hide-header-close size="lg"
      footer-class="d-flex justify-content-start" modal-class="modal-blur">
      <form @submit.prevent="scheduleInterview" id="interview">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Applicant name</label>
              <input type="text" disabled class="form-control" id="name" v-model="applicant.name">

              <span v-if="form.hasError('name')" class="text-danger" v-text="form.getError('name')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Applicant email</label>
              <input type="email" class="form-control" disabled id="email" v-model="applicant.email">

              <span v-if="form.hasError('email')" class="text-danger" v-text="form.getError('email')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Applicant phone</label>
              <input type="text" class="form-control" disabled id="phone" v-model="applicant.phone">

              <span v-if="form.hasError('phone')" class="text-danger" v-text="form.getError('phone')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Vacancy</label>
              <v-select id="gender_id" v-model="interviewForm.vacancy_id" name="vacancy_id" :options="vacancies" label="name"
                :reduce="option => option.id" :clearable="false" disabled>

              </v-select>
              <span v-if="form.hasError('vacancy_id')" class="text-danger" v-text="form.getError('vacancy_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Hiring Manager</label>
              <v-select id="gender_id" v-model="interviewForm.interviewer_id" name="interviewer_id" :options="employees" label="name"
                        :reduce="option => option.id" :clearable="false" disabled>

              </v-select>
              <span v-if="interviewForm.hasError('interviewer_id')" class="text-danger" v-text="interviewForm.getError('interviewer_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Interview Title</label>
              <input type="text" v-model="interviewForm.interview_title" class="form-control">
              <span v-if="interviewForm.hasError('interview_title')" class="text-danger"
                v-text="interviewForm.getError('interview_title')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Date and Time</label>
              <date-picker name="date_of_birth" :enable-time="true" date-format='Y-m-d H:i' :value="interviewForm.date_and_time"
                :max-date="false" @on-change="(dateOb, dateStr) => {
              interviewForm.date_and_time = dateStr
            }" />
              <span v-if="interviewForm.hasError('date_and_time')" class="text-danger" v-text="interviewForm.getError('date_and_time')">
              </span>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Notes</label>
              <textarea v-model="interviewForm.notes" class="form-control"></textarea>
              <span v-if="interviewForm.hasError('notes')" class="text-danger" v-text="interviewForm.getError('notes')">
              </span>
            </div>
          </div>
        </div>


        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>

      </form>
      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="scheduleInterview">
          Submit
        </button>

      </template>
    </b-modal>
    <!-- set interview  modal -->
    <b-modal id="interview-modal" title="Interview" hide-header-close size="lg"
      footer-class="d-flex justify-content-start" modal-class="modal-blur">
      <form @submit.prevent="submitInterview" id="interview">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Applicant name</label>
              <input type="text" disabled class="form-control" id="name" v-model="applicant.name">

              <span v-if="form.hasError('name')" class="text-danger" v-text="form.getError('name')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Applicant email</label>
              <input type="email" class="form-control" disabled id="email" v-model="applicant.email">

              <span v-if="form.hasError('email')" class="text-danger" v-text="form.getError('email')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Applicant phone</label>
              <input type="text" class="form-control" disabled id="phone" v-model="applicant.phone">

              <span v-if="form.hasError('phone')" class="text-danger" v-text="form.getError('phone')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Vacancy</label>
              <v-select id="gender_id" v-model="interviewForm.vacancy_id" name="vacancy_id" :options="vacancies" label="name"
                :reduce="option => option.id" :clearable="false" disabled>

              </v-select>
              <span v-if="form.hasError('vacancy_id')" class="text-danger" v-text="form.getError('vacancy_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Interview Title</label>
              <input type="text" disabled v-model="interview.interview_title" class="form-control">
              <span v-if="interviewForm.hasError('interview_title')" class="text-danger"
                v-text="interviewForm.getError('interview_title')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Interviewer</label>
              <v-select id="gender_id" v-model="interview.interviewer_id" name="vacancy_id" :options="employees" label="name" disabled
                :reduce="option => option.id" :clearable="false">

              </v-select>
              <span v-if="interviewForm.hasError('vacancy_id')" class="text-danger" v-text="interviewForm.getError('vacancy_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Date and Time</label>
              <date-picker disabled name="date_of_birth" :enable-time="true" date-format='Y-m-d H:i' :value="interview.date_and_time"
                :max-date="false" @on-change="(dateOb, dateStr) => {
              interview.date_and_time = dateStr
            }" />
              <span v-if="interviewForm.hasError('date_and_time')" class="text-danger" v-text="interviewForm.getError('date_and_time')">
              </span>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Notes</label>
              <textarea disabled v-model="interview.notes" class="form-control"></textarea>
              <span v-if="interviewForm.hasError('notes')" class="text-danger" v-text="interviewForm.getError('notes')">
              </span>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-3">
              <label for="name" class="form-label">Feedback</label>
              <textarea v-model="interviewResult.feedback" class="form-control"></textarea>
              <span v-if="interviewResult.hasError('feedback')" class="text-danger" v-text="interviewResult.getError('feedback')">
              </span>
            </div>
          </div>
          <div class="col-lg-12">
            <label class="form-check form-switch">
                      <input v-model="interviewResult.interPassed" class="form-check-input" type="checkbox">
                      <span class="form-check-label">Passed interview</span>
                    </label>
          </div>
        </div>


        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>

      </form>
      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="submitInterview">
          Submit
        </button>

      </template>
    </b-modal>
    <!-- set offer a job  modal -->
    <b-modal id="job-offer-modal" title="Job Offer" hide-header-close size="lg"
      footer-class="d-flex justify-content-start" modal-class="modal-blur">
      <form @submit.prevent="hire" id="interview">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Payroll number</label>
              <input type="text"  class="form-control" id="payroll_number" v-model="offerJob.payroll_number">

              <span v-if="offerJob.hasError('payroll_number')" class="text-danger" v-text="offerJob.getError('payroll_number')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Full name</label>
              <input type="text"  class="form-control" id="name" v-model="offerJob.name">

              <span v-if="offerJob.hasError('name')" class="text-danger" v-text="offerJob.getError('name')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="dob" class="form-label">Date of Birth</label>
              <date-picker  name="date_of_birth" :value="offerJob.dob"
                :max-date="false" @on-change="(dateOb, dateStr) => {
              offerJob.dob = dateStr
            }" />
              <span v-if="offerJob.hasError('dob')" class="text-danger" v-text="offerJob.getError('dob')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="gender" class="form-label">Gender</label>
              <input type="text" class="form-control" id="gender" v-model="offerJob.gender">

              <span v-if="offerJob.hasError('gender')" class="text-danger" v-text="offerJob.getError('gender')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input type="email" class="form-control" id="email" v-model="offerJob.email">

              <span v-if="offerJob.hasError('email')" class="text-danger" v-text="offerJob.getError('email')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="phone" class="form-label">Phone</label>
              <input type="text" class="form-control"  id="phone" v-model="offerJob.phone">

              <span v-if="form.hasError('phone')" class="text-danger" v-text="form.getError('phone')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="phone" class="form-label">Nationality</label>
              <input type="text" class="form-control"  id="phone" v-model="offerJob.nationality">

              <span v-if="form.hasError('nationality')" class="text-danger" v-text="form.getError('nationality')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="phone" class="form-label">ID no</label>
              <input type="text" class="form-control"  id="id_no" v-model="offerJob.id_no">

              <span v-if="form.hasError('id_no')" class="text-danger" v-text="form.getError('id_no')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Residence</label>
              <input type="text" class="form-control" id="residence" v-model="offerJob.residence">

              <span v-if="offerJob.hasError('residence')" class="text-danger" v-text="offerJob.getError('residence')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Department</label>
              <v-select id="gender_id" v-model="offerJob.department_id" name="vacancy_id" :options="departments" label="name"
                :reduce="option => option.id" :clearable="false" >

              </v-select>
              <span v-if="offerJob.hasError('department_id')" class="text-danger" v-text="offerJob.getError('department_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Employee Type</label>
              <v-select id="gender_id" v-model="offerJob.employee_type" name="job_group_id" :options="employeetypes" label="name"
                :reduce="option => option.id" :clearable="false">
              </v-select>
              <span v-if="offerJob.hasError('employee_type')" class="text-danger"
                v-text="offerJob.getError('employee_type')">
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="name" class="form-label">Job Group</label>
              <v-select id="gender_id" v-model="offerJob.job_group_id" name="job_group_id" :options="jobgroups" label="name"
                :reduce="option => option.id" :clearable="false">

              </v-select>
              <span v-if="offerJob.hasError('job_group_id')" class="text-danger" v-text="offerJob.getError('job_group_id')">
              </span>
            </div>
          </div>
          <div class="col-lg-12">
            <label class="form-check form-switch">
                      <input v-model="offerJob.activated" class="form-check-input" type="checkbox">
                      <span class="form-check-label">activate</span>
                    </label>
          </div>
        </div>


        <div class="progress mb-2" v-if="form.processing">
          <div class="progress">
            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
          </div>
        </div>

      </form>
      <template #modal-footer="{ cancel }">
        <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
        </button>
        <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="hire">
          Hire
        </button>

      </template>
    </b-modal>

    <!-- view application  modal -->
    <b-modal id="application" title="Application" hide-header-close size="lg"
           footer-class="d-flex justify-content-start" modal-class="modal-blur">

      <div class="car">
          <div class="card-ody">
              <div class="row">
                  <div class="col-md-6 profile-info">
                      <b>Name</b>
                      <h4 class="card-title">{{ selectedApplicant.name }}</h4>
                      <b>Email:</b>
                      <p>{{selectedApplicant.email}}</p>
                      <b>Phone:</b>
                      <p>{{selectedApplicant.phone}}</p>
                      <b>Gender:</b>
                      <p><span class="badge bg-primary">{{selectedApplicant.gender}}</span></p>
                      <!--                            <h6>Nationality:</h6>-->
                      <!--                            <p><span class="badge bg-info">{{selectedApplicant.nationality}}</span></p>-->
                  </div>
                  <div class="col-md-6 profile-info">
                      <b>Applied Pos</b>
                      <p if="selectedApplication.vacancy">{{selectedApplication.vacancy.name}}</p>
<!--                      <h6>Years of Exprience:</h6>-->
<!--                      <p>{{selectedApplicant.years}}</p>-->
<!--                      <h6>Education Level:</h6>-->
<!--                      <p>{{selectedApplicant.education_level}}</p>-->

<!--                      <h6>Skills:</h6>-->
<!--                      <div class="skills d-flex gap-2">-->
<!--                          <span v-for="skill in skills" class="badge bg-success">{{ skill }}</span>-->
<!--                      </div>-->
                  </div>
              </div>
              <!--                    <div class="row mt-4">-->
              <!--                        <div class="col-md-6 profile-info">-->
              <!--                            <h6>LinkedIn Profile:</h6>-->
              <!--                            <p><a href="#">linkedin.com/in/johndoe</a></p>-->
              <!--                        </div>-->
              <!--                        <div class="col-md-6 profile-info">-->
              <!--                            <h6>Portfolio:</h6>-->
              <!--                            <p><a href="#">johndoe.com</a></p>-->
              <!--                        </div>-->
              <!--                    </div>-->
<!--              <div v-if="selectedApplicant.additionals" class="row mt-4">-->
<!--                  <div class="col-md-12 profile-info">-->
<!--                      <h6>Additional Information:</h6>-->
<!--                      <p class="text-muted">{{selectedApplicant.additionals}}</p>-->
<!--                  </div>-->
<!--              </div>-->
<!--              <div class="actions text-center">-->
<!--                  <a class="btn btn-success" target="_blank" :href="'/storage/'+selectedApplicant.cv">View CV</a>-->
<!--              </div>-->
          </div>
      </div>

      <template #modal-footer="{ cancel }">
          <button type="button" class="btn me-auto" @click.prevent="cancel" :disabled="form.processing">Close
          </button>
          <!--                <button type="button" class="btn btn-primary" :disabled="form.processing" @click.prevent="hire">-->
          <!--                    Hire-->
          <!--                </button>-->

      </template>
  </b-modal>

  </div>
</template>
