<script>

export default {
    name: 'HousingLevy',
    data () {
        return {
            fields: [
                {
                    name: 'rate',
                    title: 'Rate',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                },
                {
                    name: 'year',
                    title: 'year',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                },
                {
                    name: 'activated',
                    title: 'Activate',
                    titleClass: 'font-weight-bold',
                    dataClass: 'ps-3',
                    callback: function (value) {
                        if (value) {
                            return '<input class=\'form-check-input\' type=\'checkbox\' id=\'flexCheckChecked\' disabled checked>'
                        }

                        return '<input class=\'form-check-input\' type=\'checkbox\' id=\'flexCheckChecked\' disabled>'
                    },
                },
                {
                    name: '__slot:actions',
                    width: '15%',
                    titleClass: 'text-center',
                    dataClass: 'text-right',
                },
            ],

            moreParams: {
                filter: {
                    year: '',
                },
            },

            form: new window.Form({
                id: null,
                rate: '',
                year: '',
                activated: true,
            }),
        }
    },

    computed: {
        vuetable () {
            return this.$refs.table.$refs.vuetable
        },
    },

    methods: {
        showCreateForm () {
            this.$bvModal.show('create-modal')
        },

        showUpdateForm (rowData) {
            this.selectedDepartment = rowData
            this.viewEditForm = true
        },

        createHousingLevy () {
            this.form.post('/housing_levy')
                .then(() => {
                    this.$bvModal.hide('create-modal')
                    this.$toast.success('The Benefit has been created')
                    this.vuetable.reload()
                })
                .catch()
        },

        showEditForm (rowData) {
            this.form.populate(rowData)
            this.form.id = rowData.id

            this.$bvModal.show('update-modal')
        },

        updateHousingLevy () {
            this.form.patch('/housing_levy/' + this.form.id)
                .then(() => {
                    this.vuetable.reload()
                    this.$bvModal.hide('update-modal')
                    this.$toast.success('The changes have been saved')
                })
                .catch()
        },

        applyFilter () {
            this.vuetable.reload()
        },

        cleanUp () {
            this.form.reset()
        },
    },
}
</script>

<template>
    <div>
        <div class="card card-stacked">
            <div class="card-header">
                <div class="input-icon me-2">
          <span class="input-icon-addon">
            <i class="uil uil-search"/>
          </span>
                    <input
                        v-model="moreParams.filter.name" class="form-control bg-muted-lt rounded-2"
                        placeholder="Search Benefits"
                        type="text"
                        @input="applyFilter"
                    >
                </div>

                <div class="card-actions">
                    <button class="btn btn-primary" @click="showCreateForm">
                        <i class="uil uil-plus me-1"/>New Housing levy
                    </button>
                </div>
            </div>
            <VueTable
                ref="table"
                api-url="/datatable/housing_levies"
                :fields="fields"
                :append-params="moreParams"
            >
                <template #name="props">
                    <div class="media">
                        <div class="media-body align-self-center">
                            <span class="text-sm">{{ props.rowData.name }} </span>
                            <div class="font-size-12 text-purple-300">
                                {{ props.rowData.stores ? props.rowData.stores.name : '' }}
                                <span
                                    v-if="props.rowData.auto_post_request"
                                    class="badge badge-soft-success shadow-sm"
                                >Auto Post</span>
                                <span
                                    v-else
                                    class="badge badge-soft-secondary rounded px-1 p-1 shadow-sm border"
                                >Manual Post</span>
                            </div>
                        </div>
                    </div>
                </template>

                <template #actions="props">
                    <div class="dropdown">
                        <button class="btn align-text-top py-1" data-bs-toggle="dropdown">
                            <i class="uil uil-ellipsis-h"/>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#" @click="showEditForm(props.rowData)">
                                <i class="uil uil-pen fs-3 me-2"/> Edit
                            </a>
                        </div>
                    </div>
                </template>
            </VueTable>
        </div>

        <b-modal
            id="create-modal"
            title="Create Housing Levy"
            hide-header-close
            footer-class="d-flex justify-content-start"
            modal-class="modal-blur"
            no-close-on-esc
        >
            <form id="createForm" @submit.prevent="createHousingLevy">
                <div class="mb-3">
                    <label for="name" class="form-label">Rate</label>
                    <input id="name" v-model="form.rate" class="form-control" type="text">

                    <span
                        v-if="form.hasError('rate')"
                        class="text-danger"
                        v-text="form.getError('rate')"
                    />
                </div>
                <div class="mb-3">
                    <label for="name" class="form-label">Year</label>
                    <input id="name" v-model="form.year" class="form-control" type="text">

                    <span
                        v-if="form.hasError('year')"
                        class="text-danger"
                        v-text="form.getError('year')"
                    />
                </div>
                <div class="mb-3">
                    <div class="d-flex gap-2">
                        <input id="free_bed" v-model="form.activated" class="form-check-input" type="checkbox">
                        <label class="form-label" for="free_bed">Activated</label>
                    </div>

                    <span
                        v-if="form.hasError('activated')"
                        class="text-danger"
                        v-text="form.getError('activated')"
                    />
                </div>
                <div v-if="form.processing" class="progress mb-2">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"/>
                    </div>
                </div>
            </form>

            <template #modal-footer="{cancel}">
                <button :disabled="form.processing" class="btn me-auto" type="button" @click.prevent="cancel">
                    Close
                </button>
                <button
                    :disabled="form.processing" class="btn btn-primary" type="button"
                    @click.prevent="createHousingLevy"
                >
                    Submit
                </button>
            </template>
        </b-modal>

        <b-modal
            id="update-modal"
            title="Edit Housing Levy"
            hide-header-close
            footer-class="d-flex justify-content-start"
            modal-class="modal-blur"
            no-close-on-esc
        >
            <form id="updateForm" @submit.prevent="updateHousingLevy">
                <div class="mb-3">
                    <label for="name" class="form-label">Rate</label>
                    <input id="name" v-model="form.rate" class="form-control" type="text">

                    <span
                        v-if="form.hasError('rate')"
                        class="text-danger"
                        v-text="form.getError('rate')"
                    />
                </div>
                <div class="mb-3">
                    <label for="name" class="form-label">Year</label>
                    <input id="name" v-model="form.year" class="form-control" type="text">

                    <span
                        v-if="form.hasError('year')"
                        class="text-danger"
                        v-text="form.getError('year')"
                    />
                </div>
                <div class="mb-3">
                    <div class="d-flex gap-2">
                        <input id="free_bed" v-model="form.activated" class="form-check-input" type="checkbox">
                        <label for="free_bed" class="form-label">Actvated</label>
                    </div>

                    <span
                        v-if="form.hasError('activated')"
                        class="text-danger"
                        v-text="form.getError('activated')"
                    />
                </div>

                <div v-if="form.processing" class="progress mb-2">
                    <div class="progress">
                        <div class="progress-bar progress-bar-indeterminate bg-green"/>
                    </div>
                </div>
            </form>
            <template #modal-footer="{cancel}">
                <button :disabled="form.processing" class="btn me-auto" type="button" @click.prevent="cancel">
                    Close
                </button>
                <button
                    :disabled="form.processing" class="btn btn-primary" type="button"
                    @click.prevent="updateHousingLevy"
                >
                    Update
                </button>
            </template>
        </b-modal>
    </div>
</template>
