var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card card-stacked card-md mb-4" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-10 mx-auto" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "form-check form-switch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.to_csv,
                      expression: "form.to_csv",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.form.to_csv)
                      ? _vm._i(_vm.form.to_csv, null) > -1
                      : _vm.form.to_csv,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.form.to_csv,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.form, "to_csv", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "to_csv",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "to_csv", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "form-check-label" }, [
                  _vm._v("To CSV"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row gx-4" }, [
              _c("div", { staticClass: "col-xl-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group mb-4" },
                  [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "" } },
                      [_vm._v("Deduction")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        id: "scheme_id",
                        label: "name",
                        options: _vm.deductions,
                        reduce: (option) => option.id,
                        clearable: false,
                      },
                      model: {
                        value: _vm.form.deduction_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "deduction_id", $$v)
                        },
                        expression: "form.deduction_id",
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("month")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("month")),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-xl-4" }, [
                _c(
                  "div",
                  { staticClass: "form-group mb-4" },
                  [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "" } },
                      [_vm._v("Month")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        id: "scheme_id",
                        label: "name",
                        options: _vm.months,
                        reduce: (option) => option,
                        clearable: false,
                      },
                      model: {
                        value: _vm.form.month,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "month", $$v)
                        },
                        expression: "form.month",
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.hasError("month")
                      ? _c("span", {
                          staticClass: "text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.form.getError("month")),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-xl-2" }, [
                _c("div", { staticClass: "form-group mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "" } },
                    [_vm._v("Year")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.year,
                        expression: "form.year",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "number", name: "year", id: "year" },
                    domProps: { value: _vm.form.year },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "year", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.hasError("year")
                    ? _c("span", {
                        staticClass: "text-danger",
                        domProps: {
                          textContent: _vm._s(_vm.form.getError("year")),
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mx-auto",
                attrs: { disabled: _vm.form.processing },
                on: { click: _vm.generateReport },
              },
              [
                _vm._v(
                  "\n                        Generate\n                    "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-4" }, [
      _c("div", { staticClass: "text-start" }, [
        _c("h1", { staticClass: "fw-bolder" }, [
          _vm._v(
            "\n                                Deductions\n                            "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }