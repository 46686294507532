var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card card-stacked card-md mb-4" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-8 mx-auto" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "form-check form-switch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.to_csv,
                      expression: "form.to_csv",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.form.to_csv)
                      ? _vm._i(_vm.form.to_csv, null) > -1
                      : _vm.form.to_csv,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.form.to_csv,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.form, "to_csv", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "to_csv",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "to_csv", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "form-check-label" }, [
                  _vm._v("To CSV"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row gx-4" }, [
              _c("div", { staticClass: "col-xl-6" }, [
                _c(
                  "div",
                  { staticClass: "form-group mb-4" },
                  [
                    _c("label", { attrs: { for: "startDate" } }, [
                      _vm._v("Date"),
                    ]),
                    _vm._v(" "),
                    _c("date-picker", {
                      attrs: {
                        name: "date",
                        formclass: "form-control bg-white",
                        value: _vm.form.date,
                        "max-date": false,
                      },
                      on: {
                        "on-change": (dateOb, dateStr) => {
                          _vm.form.date = dateStr
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mx-auto",
                attrs: { disabled: _vm.form.processing },
                on: { click: _vm.generateReport },
              },
              [
                _vm._v(
                  "\n                        Generate\n                    "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-4" }, [
      _c("div", { staticClass: "text-start" }, [
        _c("h1", { staticClass: "fw-bolder" }, [
          _vm._v(
            "\n                                Global Payroll Report\n                            "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }