<script>
import { defineComponent } from 'vue'
import { MediaLibraryAttachment } from 'media-library-pro-vue2-attachment'

export default defineComponent({
  name: 'EmployeeDocument',
  components: {
    MediaLibraryAttachment,
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {

      documents: [],

      value: {
        name: 'media',
        media: {},
      },

      enableForm: false,

      form: new window.Form({
        document_name: '',
        employee_id: this.employee.id,
        media: {},
        name: 'media',
      }),

      validationErrors: {},
      isReadyToSubmit: true,

    }
  },

  mounted () {
    this.retrieveDocuments()
  },
  methods: {
    toggleForm () {
      this.enableForm = !this.enableForm
    },

    onChange (media) {
      // this.form.media = media
      this.value.media = media
    },

    uploadDocument () {
      this.form.media = this.value.media

      this.form.post('/employee/employee-documents')
          .then(() => {
            this.$refs.mediaComponent.mediaLibrary.changeState(state => {
              state.media = []
            })
            this.$toast.success('Document uploaded successfully')

            this.toggleForm()

            this.retrieveDocuments()
          }).catch()
    },

    retrieveDocuments () {
      this.$httpClient.get('/employee/employee-documents', {
        params: {
          employee_id: this.employee.id,
        },
      })
          .then(({ data }) => {
            this.documents = data
          }).catch((error) => {
        console.error(error)
      })
    },
  },
})
</script>

<template>
  <div v-if="enableForm">
    <div class="card rounded-3">
      <div class="card-header">
        <div>
          <h3 class="card-title">
            Documents
          </h3>
          <p class="card-subtitle">
            Upload document
          </p>
        </div>

        <div class="card-actions">
          <button class="btn" @click.prevent="toggleForm">
            Close
          </button>
        </div>
      </div>

      <div class="card-body px-4">
        <form @submit.prevent="uploadDocument">
          <div class="form-group mb-4">
            <label for="" class="form-label">Document Name</label>

            <input v-model="form.document_name" name="document_name" type="text" class="form-control">

            <span v-if="form.hasError('document_name')" class="text-danger" v-text="form.getError('document_name')" />
          </div>

          <div class="form-group mb-4">
            <!--          <label for="" class="form-label">Upload File</label>-->

            <media-library-attachment
                ref="mediaComponent"
                name="media"
                :validation-rules="{
                accept: ['image/jpeg', 'image/gif', 'application/pdf'],
                maxSizeInKB: 10240
              }"
                :validation-errors="validationErrors"
                :initial-value="value.media"
                @is-ready-to-submit-change="isReadyToSubmit = $event"
                @change="onChange"
            />
          </div>

          <button
              class="btn btn-primary"
              :disabled="form.processing"
              type="submit"
          >
            Upload Document
          </button>
        </form>
      </div>

    </div>

  </div>

  <div v-else>
    <div class="card">
      <div class="card-header">
        <div>
          <h3 class="card-title">
            Documents
          </h3>
          <p class="card-subtitle">
            Listing uploaded documents
          </p>
        </div>

        <div class="card-actions">
          <button class="btn" @click.prevent="toggleForm">
            <i class="uil uil-file-upload me-2" /> Upload
          </button>
        </div>
      </div>

      <div :key="document.id" v-for="document in documents" class="card-body">
        <div class="row g-2 align-items-center">
          <div class="col">
            <h4 class="card-title m-0">
              <a href="#">{{ document.name }}</a>
            </h4>
            <div class="text-secondary">
              {{ document.size | readableFileSize }}<br/>
                <small class="text-muted">by <a href="#">{{ document.uploaded_by.name}}</a> on {{ document.created_at|dATE_MONTH_YEAR_HOUR_MINUTES}} </small>
            </div>
          </div>
          <div class="col-auto">
            <a :href="'/employee/employee-document/' + document.uuid" class="btn" target="_blank">
              Download
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
